@if(isLoading){
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
} @else if(!!connection) {
    <div>
        <div>
            <div class="flex flex-col items-start py-1 px-2 gap-1">
                <div>
                    <div class="text-xs">{{"License.Details.Url" | transloco}}</div>
                    <div class="font-semibold"><a href='https://{{connection.envUrl}}' target="_blank">{{connection.envUrl}}</a></div>
                </div>
                <div>
                    <div class="text-xs">{{"License.Details.Server" | transloco}}</div>
                    <div class="font-semibold">{{connection.envServer}}</div>
                </div>
                <div class="flex flex-row">
                    <div>
                        <div class="text-xs">{{"License.Details.Database" | transloco}}</div>
                        <div class="font-semibold">{{connection.envDatabase}}</div>
                    </div>
                </div>
                @if (!connection.testEnvironmentCreated) {
                    <arc-button class="pt-2" [customClasses]=" isDarkTheme ? '!text-white !border-white': ''" actionButtonKey="LicenseTestActivateContextAction" actionButtonTitleKey="License.Details.CopyToTest"
                        [currentData]="license" type="stroked">
                    </arc-button>
                }
            </div>
        </div>
        @if(!!connection.testEnvironmentCreated) {
            <div class="px-2 bg-app text-on-app">
                <div class="flex flex-col items-start gap-1">
                    <div class="flex w-full justify-between">
                        <div>
                            <div class="text-xs">{{"License.Details.Server" | transloco}}</div>
                            <div class="font-semibold">{{connection.envServer}}</div>
                        </div>
                        <button mat-icon-button (click)="copyToClipboard(connection.testEnvConnectionString)">
                            <mat-icon>content_copy</mat-icon>
                        </button>
                    </div>
                    <div class="flex w-full justify-between">
                        <div>
                            <div class="text-xs">{{"License.Details.TestDatabaseFrom" | transloco}} {{connection.testEnvironmentCreated | arcDate }}</div>
                            <div class="font-semibold">{{connection.testEnvDatabase}}</div>
                        </div>
                        <div class="flex">
                            <button mat-icon-button (click)="handleDelete()" class="!pt-1.5">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-icon-button (click)="handleDownload()">
                                <mat-icon>file_download</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        }
        <div class="px-2 pt-1">
            <div class="flex flex-col items-start">
                <div class="flex w-full justify-between">
                    <div>
                        <div class="text-xs">{{"License.Details.AccessCode" | transloco}}</div>
                        <div class="font-semibold">{{connection.accessCode}}</div>
                    </div>
                    <button mat-icon-button (click)="copyToClipboard(connection.accessCode)">
                        <mat-icon>content_copy</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
}
