import { Injectable, inject, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, tap, Observable, lastValueFrom, switchMap, first, finalize, of, map } from 'rxjs';

import { Utils } from '../utils/tools/utils.tools';
import { ActionButtonModel } from '../models/action-button.model';
import { ContextActionModel } from '../models/context-action.model';
import { DictionaryType } from '../models/types/dictionary.type';
import { BaseCrudStore } from '../abstractions/base-crud.store';
import { ExecuteActionModel } from '../models/execute-action.model';
import { DynamicFormDialogComponent } from '../../components/dynamic-form-dialog/dynamic-form-dialog.component';
import { BaseSearchStore } from '../abstractions/base-search.store';
import { BaseListViewComponent } from '../abstractions/base-list-view-component';
import { SearchRequestService } from './search-request.service';
import { ApiResponseModel } from '../../app/models/responses/api-response.model';
import { ToasterService } from './toaster.service';
import { OptionalType } from '../models/types/optional.type';
import { ContextActionWindowSizeEnum } from '../../app/models/enums/context-action-window-size.enum';
import { Identifyable } from '../abstractions/identifyable';
import { Tools } from '../utils/tools';

@Injectable({
    providedIn: 'root'
})
export class ActionButtonsService<
    TList extends Identifyable<TId>,
    T extends Identifyable<TId> = any,
    TCreate extends Identifyable<TId> = T,
    TUpdate extends Identifyable<TId> = TCreate,
    TId = number
> {
    static readonly createButtonKey = Utils.newGuid();
    static readonly editButtonKey = Utils.newGuid();
    static readonly deleteButtonKey = Utils.newGuid();
    static readonly exportButtonKey = Utils.newGuid();

    get currentSelection(): Identifyable<TId>[] {
        return this._currentSelection;
    }
    get currentButtonsWithoutBase(): ActionButtonModel[] {
        const excludedKeys = [
            ActionButtonsService.createButtonKey,
            ActionButtonsService.editButtonKey,
            ActionButtonsService.deleteButtonKey,
            ActionButtonsService.exportButtonKey
        ];
        return Utils.deepCopy(this.localButtonsBackup)
            .filter(b => !excludedKeys.includes(b.key))
            .concat(this.outOfContextActions);
    }

    readonly buttons = signal<ActionButtonModel[]>([]);
    readonly checkButtonLoadingStatusSub = new Subject<ActionButtonModel>();

    private readonly matDialog = inject(MatDialog);
    private readonly searchRequestService = inject(SearchRequestService);
    private readonly toasterService = inject(ToasterService);

    private _currentSelection: TList[] = [];
    private localButtonsBackup: ActionButtonModel[] = [];
    private outOfContextActions: ActionButtonModel[] = [];
    private outOfContextActionsStores: DictionaryType<BaseSearchStore<Identifyable<TId>, TId>> = {};
    private currentStore!: BaseCrudStore<T, TList, TCreate, TUpdate, TId>;
    private currentComponent!: BaseListViewComponent<T, TList, TCreate, TUpdate, TId>;
    private isInitialized = false;
    private get selectedIds(): TId[] {
        return this._currentSelection.map(s => s.id);
    }

    /**
     * Generates the action buttons according to the current context (page).
     * @param component Current page.
     * @param contextActions Context actions of the current context.
     */
    generate(component: BaseListViewComponent<T, TList, TCreate, TUpdate, TId>, contextActions: ContextActionModel[] = []): void {
        this.currentStore = component.config.store;
        this.currentComponent = component;
        this._currentSelection = [];
        const baseButtons = this.getBaseButtons();
        const extraActionButtons = this.convertToActionButtons(contextActions);
        this.localButtonsBackup = [...baseButtons, ...extraActionButtons, ...component.config.customActionButtons];
        const availableButtons = Object.values(Utils.deepCopy(this.localButtonsBackup));
        this.isInitialized = true;

        this.notifyChanges(availableButtons);
    }

    /**
     * Updates the buttons' visibility according to the user selection of the items in a list.
     * @param data Current selection.
     */
    select(data: TList[]): void {
        if (!this.isInitialized) {
            return;
        }

        if (Array.isArray(data)) {
            this._currentSelection = [...data];
        } else {
            this._currentSelection.push(data);
        }

        const amountCurrentlySelected = this._currentSelection.length;
        const availableButtons = Utils.deepCopy(this.localButtonsBackup);

        // Iterates over the available buttons and updates their visibility according to the selection.
        for (const button of availableButtons) {
            const isEnabled = amountCurrentlySelected >= button.min && amountCurrentlySelected <= button.max;
            button.isEnabled = isEnabled;
            this.localButtonsBackup.find(b => b.key === button.key)!.isEnabled = isEnabled;
        }

        this.notifyChanges(availableButtons);
    }

    /**
     * Restores the buttons' visibility status.
     */
    clearSelection(): void {
        this._currentSelection = [];
        this.outOfContextActions = [];
        this.outOfContextActionsStores = {};
        const availableButtons = Utils.deepCopy(this.localButtonsBackup);

        for (const button of availableButtons) {
            const isEnabled = button.min === 0 && button.max >= 0;
            button.isEnabled = isEnabled;
            this.localButtonsBackup.find(b => b.key === button.key)!.isEnabled = isEnabled;
        }

        this.notifyChanges(availableButtons);
    }

    /**
     * Resets the whole service.
     * This makes the service behave as if it were in a new state, it will expect generate to be called again.
     */
    reset(): void {
        this.localButtonsBackup = [];
        this.isInitialized = false;

        this.clearSelection();
    }

    /**
     * Executes the click function of the specified button.
     * @param key Key of the button.
     * @param selectedItem Current record's data.
     */
    handleClick(key: string, selectedItem?: Identifyable<TId>, extraParameters?: DictionaryType<any>): void {
        const button = this.getButtonByKey(key);

        if (!!button?.clickFn) {
            button.clickFn(button, selectedItem, extraParameters);
        }
    }

    /**
     * Retrieves a button by its key.
     * @param key Key.
     */
    getButtonByKey(key: string): OptionalType<ActionButtonModel> {
        return this.localButtonsBackup.find(b => b.key === key) || this.outOfContextActions.find(b => b.key === key);
    }

    /**
     * Checks whether the button is hidden or not.
     * @param key Key of the button.
     * @param currentItem Current record's data.
     */
    isHidden(key: string, currentItem?: Identifyable<TId>): boolean {
        const button = this.getButtonByKey(key);
        return !!button?.isCurrentlyHidden(currentItem);
    }

    /**
     * Changes the loading status of a button.
     * @param key Key of the button.
     * @param isLoading Whether it's loading or not.
     */
    changeLoadingStatus(key: string, isLoading: boolean): void {
        const availableButtons = Utils.deepCopy(this.localButtonsBackup);
        const button = availableButtons.find(b => b.key === key);

        if (!!button) {
            button.isLoading = isLoading;

            this.checkButtonLoadingStatusSub.next(button);
            this.notifyChanges(availableButtons);
        }
    }

    /**
     * Updates the is hidden of the button with the provided key.
     * @param key Button's key.
     * @param isHidden New is hidden function.
     */
    updateIsHidden(key: string, isHidden?: (btn: ActionButtonModel, data?: TList) => boolean): this {
        const currentButton = this.localButtonsBackup.find(lbb => lbb.key === key);

        if (!currentButton) {
            return this;
        }

        currentButton.isHidden = isHidden;
        const availableButtons = Utils.deepCopy(this.localButtonsBackup);

        this.notifyChanges(availableButtons);

        return this;
    }

    /**
     * Updates the basic configuration (icon, text and isLoading) of the button with the provided key.
     * @param btn New Button's configuration.
     */
    updateBasicConfig(btn: ActionButtonModel): this {
        const currentButton = this.localButtonsBackup.find(lbb => lbb.key === btn.key)!;
        currentButton.icon = btn.icon;
        currentButton.text = btn.text;
        currentButton.color = btn.color;
        currentButton.isLoading = btn.isLoading;
        const availableButtons = Utils.deepCopy(this.localButtonsBackup);

        this.notifyChanges(availableButtons);

        return this;
    }

    /**
     * Converts actions that were requested out of context.
     * @param contextActions Actions.
     * @param store Store to be used for the provided actions.
     */
    convertOutOfContextActions(contextActions: ContextActionModel[], store: BaseSearchStore<Identifyable<TId>, TId>): ActionButtonModel[] {
        this.outOfContextActions = this.convertToActionButtons(contextActions);
        this.outOfContextActions.forEach(cta => (this.outOfContextActionsStores[cta.key] = store));

        return this.outOfContextActions;
    }

    private notifyChanges(buttons: ActionButtonModel[]): void {
        this.buttons.set(buttons);
    }

    private convertToActionButtons(contextActions: ContextActionModel[]): ActionButtonModel[] {
        const result: ActionButtonModel[] = [];

        for (const contextAction of contextActions) {
            result.push(
                new ActionButtonModel({
                    key: contextAction.key,
                    text: contextAction.name,
                    isTextTranslated: true,
                    icon: contextAction.icon || 'play_circle',
                    min: contextAction.min,
                    max: contextAction.max,
                    hasParameters: contextAction.hasParameters,
                    hasSteps: contextAction.hasSteps,
                    steps: contextAction.steps,
                    isHiddenOnSelection: contextAction.isHiddenOnSelection,
                    conditionField: contextAction.conditionField,
                    failedConditionMessage: contextAction.failedConditionMessage,
                    modalVisibility: contextAction.modalVisibility,
                    isEnabled: contextAction.min === 0 && contextAction.max === 0,
                    clickFn: (btn, selectedItem, extraParameters) =>
                        this.onContextActionClicked(contextAction, btn, selectedItem, extraParameters)
                })
            );
        }

        return result;
    }

    private async onContextActionClicked(
        contextAction: ContextActionModel,
        btn: ActionButtonModel,
        selectedItem?: Identifyable<TId>,
        parameters?: DictionaryType<any>
    ): Promise<void> {
        {
            const selectedIds = !!selectedItem ? [selectedItem.id] : this.selectedIds;
            const btnKey = btn.key!;

            this.changeLoadingStatus(btnKey, true);

            // no parameters: execute immediately
            if (!contextAction.hasParameters) {
                this.executeContextAction(contextAction, {
                    selectedIds
                }).subscribe({
                    next: () => this.showSuccessActionMessage(),
                    complete: () => this.changeLoadingStatus(btnKey, false)
                });
                return;
            }

            // single step context action
            if (!contextAction.hasSteps) {
                this.getParametersAndExecuteContextAction(contextAction, selectedIds, parameters ?? {}).subscribe({
                    next: isExecuted => {
                        if (isExecuted) {
                            this.showSuccessActionMessage();
                        }
                    },
                    complete: () => this.changeLoadingStatus(btnKey, false)
                });
                return;
            }

            // multi step
            const multiStepParameters: DictionaryType<any> = parameters !== undefined ? Tools.Utils.deepCopy(parameters) : {};
            for (let step = 0; step < (contextAction.steps ?? 1); step++) {
                const isExecuted = await lastValueFrom(
                    this.getParametersAndExecuteContextAction(contextAction, selectedIds, multiStepParameters, step)
                );
                if (!isExecuted) {
                    this.changeLoadingStatus(btnKey, false);
                    return;
                }
            }

            this.changeLoadingStatus(btnKey, false);
            this.showSuccessActionMessage();
        }
    }

    private getParametersAndExecuteContextAction(
        contextAction: ContextActionModel,
        selectedIds: TId[],
        parameters: DictionaryType<any>,
        step?: number
    ): Observable<boolean> {
        return this.currentStore.getContextActionParameters(contextAction.key, { selectedIds, parameters, step }).pipe(
            first(),
            switchMap(resp => {
                if (!resp.value || resp.value.fields.length === 0) {
                    return this.executeContextAction(contextAction, {
                        selectedIds,
                        parameters,
                        step
                    }).pipe(map(() => true));
                }

                const dialogRef = this.matDialog.open(DynamicFormDialogComponent, {
                    width: resp.value.windowSize === ContextActionWindowSizeEnum.Default ? '400px' : '600px',
                    data: {
                        contextAction: contextAction,
                        model: resp.value
                    }
                });
                return dialogRef.afterClosed().pipe(
                    first(),
                    switchMap((newParameters?: DictionaryType<any>) => {
                        if (!newParameters) {
                            // action was ancelled by closing dialog
                            return of(false);
                        }

                        // updating parameters
                        Object.assign(parameters, newParameters);

                        return this.executeContextAction(contextAction, {
                            selectedIds,
                            parameters,
                            step
                        }).pipe(map(() => true));
                    })
                );
            })
        );
    }

    private showSuccessActionMessage(): void {
        this.toasterService.showSuccess('General.Actions.SuccessMessage');
    }

    private executeContextAction(
        contextAction: ContextActionModel,
        request: ExecuteActionModel<TId>
    ): Observable<ApiResponseModel<boolean>> {
        const store =
            contextAction.key in this.outOfContextActionsStores ? this.outOfContextActionsStores[contextAction.key] : this.currentStore;
        return store.executeAction(contextAction.key, request, contextAction.isFileResult).pipe(
            tap(actionResult => {
                const body = (actionResult as any).body;
                if (contextAction.isFileResult && !!body && body.size > 0) {
                    const blob = new Blob(['', body], { type: body.type });
                    Utils.saveFile(blob, Utils.getFileNameFromResponse(actionResult));
                }
            }),
            finalize(() => {
                const isLastStep = !contextAction.hasSteps || request.step === (contextAction.steps ?? 1) - 1;
                if (isLastStep) {
                    this.currentComponent.refresh();
                }
            })
        );
    }

    private getBaseButtons(): ActionButtonModel[] {
        const result: ActionButtonModel[] = [];

        if (this.currentComponent.config.buttonsVisibility.hasCreate) {
            result.push(
                new ActionButtonModel({
                    key: ActionButtonsService.createButtonKey,
                    text: 'General.Actions.Create',
                    icon: 'add',
                    min: 0,
                    max: 0,
                    hasParameters: false,
                    isEnabled: true,
                    order: 10,
                    clickFn: () => {
                        this.currentComponent.handleCreateButtonClick();
                    }
                })
            );
        }

        if (this.currentComponent.config.buttonsVisibility.hasEdit) {
            result.push(
                new ActionButtonModel({
                    key: ActionButtonsService.editButtonKey,
                    text: 'General.Actions.Edit',
                    icon: 'edit',
                    min: 1,
                    max: 1,
                    hasParameters: false,
                    order: 20,
                    clickFn: (btn: ActionButtonModel, selectedItem?: TList) => {
                        const selected = selectedItem || this._currentSelection.filter(cs => cs.id === this.selectedIds[0])[0];
                        this.currentComponent.handleEditButtonClick(selected);
                    }
                })
            );
        }

        if (this.currentComponent.config.buttonsVisibility.hasDelete) {
            result.push(
                new ActionButtonModel({
                    key: ActionButtonsService.deleteButtonKey,
                    text: 'General.Actions.Delete',
                    icon: 'delete',
                    min: 1,
                    max: Number.MAX_VALUE,
                    hasParameters: false,
                    order: 30,
                    clickFn: (btn: ActionButtonModel, selectedItem?: TList) => {
                        const selected = !!selectedItem ? [selectedItem] : this._currentSelection;
                        this.currentComponent.handleDeleteButtonClick(...selected);
                    }
                })
            );
        }

        if (this.currentComponent.config.buttonsVisibility.hasExport) {
            result.push(
                new ActionButtonModel({
                    key: ActionButtonsService.exportButtonKey,
                    text: 'General.Actions.Export',
                    icon: 'download',
                    min: 0,
                    max: 0,
                    hasParameters: false,
                    isEnabled: true,
                    clickFn: (btn: ActionButtonModel) => {
                        this.changeLoadingStatus(btn.key!, true);
                        this.currentStore.downloadCsv(this.searchRequestService.current).subscribe(r => {
                            const blob = new Blob(['\ufeff', r], { type: 'text/csv;charset=utf-8' });

                            Utils.saveFile(blob, `${btn.key}.csv`);
                            this.changeLoadingStatus(btn.key!, false);
                        });
                    }
                })
            );
        }

        return result;
    }
}
