import { computed, signal } from '@angular/core';

/**
 * This is required instead of Angular's selection model: this.selectedItems.isSelected, because isSelected compares the objects
 * and the objects change when the page changes. Therefore we must use the ID to make the comparison of checked/unchecked.
 */
export class TableSelectionModel<TList> {
    selected = computed(() => this.items());
    hasValue = computed(() => this.items().length > 0);

    private readonly items = signal<TList[]>([]);
    private readonly keySelector: (item: TList) => any;

    constructor(
        keySelector?: (item: TList) => any
    ) {
        this.keySelector = keySelector || (item => Object.keys(item as any).includes('id') ? (item as any).id : JSON.stringify(item));
    }

    select(...items: TList[]): void {
        this.items.update(local => [
            ...local,
            ...items.filter(i => !local.some(existing => this.keySelector(existing) === this.keySelector(i)))
        ]);
    }

    toggle(item: TList): void {
        const key = this.keySelector(item);

        if (!this.items().some(existing => this.keySelector(existing) === key)) {
            this.items.update(items => [...items, item]);
        } else {
            this.items.update(items => [...items.filter(existing => this.keySelector(existing) !== key)]);
        }
    }

    isSelected(item: TList): boolean {
        return this.items().some(existing => this.keySelector(existing) === this.keySelector(item));
    }

    clear(currentPage: TList[]): void {
        this.items.update(items => [
            ...items.filter(i => !currentPage.some(existing => this.keySelector(existing) === this.keySelector(i)))
        ]);
    }
}
