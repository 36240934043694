<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-input labelKey="Newsletter.EditFields.Title" [control]="formGroup.controls.title" />
    <div class="flex justify-between">
        <div class="flex items-center gap-6">
            <p>{{ 'Newsletter.Edit.Language' | transloco }}</p>
            <arc-button-toggle-group [control]="language" [items]="languages" toggleButtonClasses="w-[80px]" />
        </div>
        <button mat-icon-button  (click)="translate()">
            <mat-icon>translate</mat-icon>
        </button>
    </div>

    <arc-newsletter-email-editor
        [emailMessageFormGroup]="formGroupForSelectedLanguage"
        [messageType]="this.item().messageType">
    </arc-newsletter-email-editor>
</form>
