import { Component, OnInit } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { CurrencyColumnModel } from '../../models/column-types/currency-column.model';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-currency-column',
    templateUrl: './currency-column.component.html',
    styleUrls: ['./currency-column.component.scss']
})
export class CurrencyColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseColumnComponent<T, TId> implements OnInit {
    get currColumnModel(): CurrencyColumnModel {
        return this.columnModel as CurrencyColumnModel;
    }

    get currencyId(): string | undefined {
        const currencyIdFn = this.currColumnModel.currencyId;
        return currencyIdFn ? currencyIdFn(this.item) : undefined;
    }

    get currencyIsoCode(): string | undefined {
        const currencyIsoCodeFn = this.currColumnModel.currencyIsoCode;
        return currencyIsoCodeFn ? currencyIsoCodeFn(this.item) : undefined;
    }

    get value(): OptionalType<number> {
        return this.getItemValue(this.columnModel.propertyName);
    }

    get hasValue(): boolean {
        return typeof this.value === 'number';
    }
}
