import { MarkdownModule } from 'ngx-markdown';
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { GridsterModule } from 'angular-gridster2';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxStripeModule } from 'ngx-stripe';

import { MaterialModule } from '../../core/utils/material.module';
import { ComponentsModule } from '../../components/components.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './security/login/login.component';
import { RequestPasswordTokenComponent } from './security/request-pwd-token/request-password-token.component';
import { ResetPasswordComponent } from './security/reset-password/reset-password.component';
import { DirectivesModule } from '../../core/directives/directives.module';
import { PipesModule } from '../../core/pipes/pipes.module';
import { LicensesComponent } from './licenses/licenses/licenses.component';
import { TransactionsComponent } from './licenses/transactions/transactions.component';
import { InvoicesComponent } from './licenses/invoices/invoices.component';
import { BenefitsComponent } from './licenses/benefits/benefits.component';
import { TicketsComponent } from './support/tickets/tickets.component';
import { HelpComponent } from './support/help/help.component';
import { FeaturesComponent } from './support/features/features.component';
import { FeedbackComponent } from './support/feedback/feedback.component';
import { NewsletterComponent } from './marketing/newsletter/newsletter.component';
import { SubscribersComponent } from './marketing/subscribers/subscribers.component';
import { LeadsComponent } from './marketing/leads/leads.component';
import { UsersComponent } from './basic-data/users/users.component';
import { ApiIntegrationsComponent } from './basic-data/api-integrations/api-integrations.component';
import { FeedbackStatusComponent } from './support/feedback/components/feedback-status/feedback-status.component';
import { NewsletterCardComponent } from './marketing/newsletter/newsletter-card/newsletter-card.component';
import { FeatureStatusComponent } from './support/features/components/feature-status/feature-status.component';
import { FeatureVoteComponent } from './support/features/features-widgets/feature-vote/feature-vote.component';
import { NewsletterEditComponent } from './marketing/newsletter/newsletter-edit-items/newsletter-edit/newsletter-edit.component';
import { NewsletterCreateDialogComponent } from './marketing/newsletter/newsletter-create-dialog/newsletter-create-dialog.component';
import { NewsletterEmailEditorComponent } from './marketing/newsletter/components/newsletter-email-editor/newsletter-email-editor.component';
import { FeedbackTextComponent } from './support/feedback/feedback-widgets/feetback-text/feedback-text.component';
import { HelpTopicItemComponent } from './support/help/components/help-topic-item/help-topic-item.component';
import { FeedbackVoteTextColumnComponent } from './support/features/components/feedback-vote-text/feedback-vote-text-column.component';
import { FeatureDescriptionComponent } from './support/features/features-widgets/feature-description/feature-description.component';
import { FeedbackTypeColumnComponent } from './support/feedback/components/feedback-type/feedback-type-column.component';
import { HelpSubTopicItemComponent } from './support/help/components/help-sub-topic-item/help-sub-topic-item.component';
import { UsersEditBasicDataComponent } from './basic-data/users/users-edit-items/users-edit-basic-data/users-edit-basic-data.component';
import { FeaturesEditComponent } from './support/features/features-edit-items/features-edit/features-edit.component';
import { HelpDescriptionComponent } from './support/help/help-detail-widgets/help-description/help-description.component';
import { ReportTemplatesComponent } from './basic-data/report-templates/report-templates.component';
import { ReportTemplateCardComponent } from './basic-data/report-templates/report-template-card/report-template-card.component';
import { ReportTemplateEditBaseDataComponent } from './basic-data/report-templates/report-template-edit-items/report-template-edit-base-data/report-template-edit-base-data.component';
import { ReportsDesignerComponent } from './basic-data/report-templates/designer/reports-designer.component';
import { ReportsViewerComponent } from './basic-data/report-templates/reports-viewer/reports-viewer.component';
import { PosReportTemplateCardComponent } from './basic-data/pos-report-templates/pos-report-template-card/pos-report-template-card.component';
import { PosReportTemplateEditBaseDataComponent } from './basic-data/pos-report-templates/pos-report-template-edit-items/pos-report-edit-base-data/pos-report-edit-base-data.component';
import { PosReportTemplatesComponent } from './basic-data/pos-report-templates/pos-report-templates.component';
import { SubscribersEditComponent } from './marketing/subscribers/subscribers-edit-items/subscribers-edit/subscribers-edit.component';
import { HelpEditComponent } from './support/help/help-edit-items/help-edit/help-edit.component';
import { RegisterComponent } from './security/register/register.component';
import { ModuleBundlesComponent } from './basic-data/module-bundles/module-bundles.component';
import { ContractsComponent } from './basic-data/contracts/contracts.component';
import { ContractEditBaseDataComponent } from './basic-data/contracts/contract-edit-items/contract-edit-base-data/contract-edit-base-data.component';
import { ContractViewComponent } from './contract-view/contract-view.component';
import { ModuleBundleBaseDataComponent } from './basic-data/module-bundles/edit-items/module-bundle-base-data/module-bundle-base-data.component';
import { ModuleBundleDiscountsComponent } from './basic-data/module-bundles/edit-items/module-bundle-discounts/module-bundle-discounts.component';
import { ModuleBundleImageComponent } from './basic-data/module-bundles/edit-items/module-bundle-image/module-bundle-image.component';
import { LicenseBaseDataComponent } from './licenses/licenses/licenses-edit-items/license-base-data/license-base-data.component';
import { LicenseEditUsersComponent } from './licenses/licenses/licenses-edit-items/license-edit-users/license-edit-users.component';
import { LicensePaymentComponent } from './licenses/licenses/licenses-edit-items/license-payment/license-payment.component';
import { TransactionTypeComponent } from './licenses/transactions/components/transaction-type/transaction-type.component';
import { HardwareBundlesComponent } from './basic-data/hardware-bundles/hardware-bundles.component';
import { HardwareBundleImageComponent } from './basic-data/hardware-bundles/edit-items/hardware-bundle-image/hardware-bundle-image.component';
import { HardwareBundleBaseDataComponent } from './basic-data/hardware-bundles/edit-items/hardware-bundle-base-data/hardware-bundle-base-data.component';
import { InvoiceBaseDataComponent } from './licenses/invoices/edit-items/invoice-base-data/invoice-base-data.component';
import { BenefitDiscountComponent } from './licenses/benefits/components/benefit-discount/benefit-discount.component';
import { BenefitTypeComponent } from './licenses/benefits/components/benefit-type/benefit-type.component';
import { BenefitPaymentTypeComponent } from './licenses/benefits/components/benefit-payment-type/benefit-payment-type.component';
import { BenefitPaymentPeriodComponent } from './licenses/benefits/components/benefit-payment-period/benefit-payment-period.component';
import { BenefitBasicDataComponent } from './licenses/benefits/edit-items/benefit-basic-data/benefit-basic-data.component';
import { TransactionBaseDataComponent } from './licenses/transactions/edit-items/transaction-base-data/transaction-base-data.component';
import { SystemLogComponent } from './basic-data/system-log/system-log.component';
import { SystemLogDetailsTableComponent } from './basic-data/system-log/sytem-log-details-widgets/system-log-details-table/system-log-details-table.component';
import { SystemLogMessageComponent } from './basic-data/system-log/sytem-log-details-widgets/system-log-message/system-log-message.component';
import { SystemLogStacktraceComponent } from './basic-data/system-log/sytem-log-details-widgets/system-log-stacktrace/system-log-stacktrace.component';
import { LicenseDbInfoComponent } from './licenses/licenses/components/license-db-info/license-db-info.component';
import { HelpTooltipsComponent } from './support/help-tooltips/help-tooltips.component';
import { HelpTooltipEditComponent } from './support/help-tooltips/help-tooltips-edit-items/help-tooltip-edit/help-tooltip-edit.component';
import { HelpArticleViewComponent } from './help-article-view/help-article-view.component';
import { TicketHeaderAdditionalInfoComponent } from './support/tickets/components/ticket-header-additional-info/ticket-header-additional-info.component';
import { TicketMessageItemComponent } from './support/tickets/components/ticket-message-item/ticket-message-item.component';
import { TicketMessagesComponent } from './support/tickets/components/ticket-messages/ticket-messages.component';
import { TicketBaseDataComponent } from './support/tickets/components/ticket-base-data/ticket-base-data.component';
import { LicenseDbInfoWidgetComponent } from './licenses/licenses/licenses-details-widgets/license-db-info-widget/license-db-info-widget.component';
import { TicketListItemComponent } from './support/tickets/components/ticket-list-item/ticket-list-item.component';
import { TicketStatusComponent } from './support/tickets/components/ticket-status/ticket-status.component';
import { TicketListComponent } from './support/tickets/components/ticket-list/ticket-list.component';
import { LicenseModuleBundlesBaseDataComponent } from './licenses/license-module-bundles/edit-items/license-module-bundles-base-data/license-module-bundles-base-data.component';
import { LicenseModuleBundlesComponent } from './licenses/license-module-bundles/license-module-bundles.component';
import { HelpTopicsComponent } from './support/help-topics/help-topics.component';
import { HelpTopicEditComponent } from './support/help-topics/components/help-topic-edit/help-topic-edit.component';
import { CreateTicketDialogComponent } from './support/tickets/components/create-ticket/create-ticket-dialog/create-ticket-dialog.component';
import { CreateTicketResponseComponent } from './support/tickets/components/create-ticket/create-ticket-response/create-ticket-response.component';
import { CreateTicketRequestComponent } from './support/tickets/components/create-ticket/create-ticket-request/create-ticket-request.component';
import { CreateTicketComponent } from './support/tickets/components/create-ticket/create-ticket/create-ticket.component';
import { NewTicketComponent } from './support/tickets/components/create-ticket/new-ticket/new-ticket.component';
import { SelectedHelpArticleComponent } from './support/tickets/components/create-ticket/selected-help-article/selected-help-article.component';
import { HelpArticleListItemComponent } from './support/tickets/components/create-ticket/help-article-list-item/help-article-list-item.component';
import { DemoAvailableSoonComponent } from './demo-available-soon/demo-available-soon.component';
import { ConfiguratorAvailableSoonComponent } from './configurator-available-soon/configurator-available-soon.component';
import { EventMessageItemComponent } from './support/tickets/components/event-message-item/event-message-item.component';
import { LicenseEditUsersResellerComponent } from './licenses/licenses/licenses-edit-items/license-edit-users-reseller/license-edit-users-reseller.component';
import { BlogEditComponent } from './support/blog/blog-edit-items/blog-edit/blog-edit.component';
import { BlogComponent } from './support/blog/blog.component';
import { LicenseHardwareOrdersComponent } from './licenses/license-hardware-orders/license-hardware-orders.component';
import { LicenseHardwareOrderBaseDataComponent } from './licenses/license-hardware-orders/edit-items/license-hardware-order-base-data/license-hardware-order-base-data.component';
import { LicenseHardwareOrderStatusComponent } from './licenses/license-hardware-orders/components/hardware-order-status/hardware-order-status.component';
import { LicenseDetailsWidgetComponent } from './licenses/licenses/licenses-details-widgets/license-details-widget/license-details-widget.component';
import { LicenseDetailsComponent } from './licenses/licenses/components/license-details/license-details.component';
import { LicenseValidThruComponent } from './licenses/licenses/components/license-valid-thru/license-valid-thru.component';
import { LicenseModuleItemListComponent } from './licenses/licenses/components/license-module-item-list/license-module-item-list.component';
import { LicenseModuleItemComponent } from './licenses/licenses/components/license-module-item/license-module-item.component';
import { LicenseProductItemComponent } from './licenses/licenses/components/license-product-item/license-product-item.component';
import { LicenseModulesWidgetComponent } from './licenses/licenses/licenses-details-widgets/license-modules-widget/license-modules-widget.component';
import { LicenseTransactionsWidgetComponent } from './licenses/licenses/licenses-details-widgets/license-transactions-widget/license-transactions-widget.component';
import { LicenseNewTransactionDialogComponent } from './licenses/licenses/components/license-new-transaction-dialog/license-new-transaction-dialog.component';
import { InvoicePayViewComponent } from './invoice-pay-view/invoice-pay-view.component';
import { environment } from '../../environments/environment';
import { LicenseAssetsWidgetComponent } from './licenses/licenses/licenses-details-widgets/license-assets-widget/license-assets.detail-widget';
import { CustomerInteractionsComponent } from './support/customer-interactions/customer-interactions.component';
import { CustomerInteractionsNoteDetailWidget } from './support/customer-interactions/customer-interactions-widgets/customer-interactions-note-widget/customer-interactions-note.detail-widget';
import { LicenseHardwareOrdersDetailWidget } from './licenses/licenses/licenses-details-widgets/license-hardware-orders-widget/license-hardware-orders.detail-widget';
import { LicenseInteractionsDetailWidget } from './licenses/licenses/licenses-details-widgets/license-interactions-widget/license-interactions-detail-widget';
import { LicenseInteractionTypeComponent } from './licenses/licenses/components/license-interaction-action/license-interaction-action.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        MaterialModule,
        ComponentsModule,
        GridsterModule,
        PipesModule,
        DirectivesModule,
        QRCodeModule,
        NgOptimizedImage,
        RouterLink,
        MarkdownModule.forRoot(),
        NgxStripeModule.forRoot(environment.stripePublishableKey)
    ],
    declarations: [
        HomeComponent,
        LoginComponent,
        RequestPasswordTokenComponent,
        ResetPasswordComponent,
        ResetPasswordComponent,
        LicensesComponent,
        TransactionsComponent,
        InvoicesComponent,
        BenefitsComponent,
        TicketsComponent,
        HelpComponent,
        FeaturesComponent,
        FeedbackComponent,
        NewsletterComponent,
        SubscribersComponent,
        LeadsComponent,
        UsersComponent,
        ReportsDesignerComponent,
        ReportsViewerComponent,
        ReportTemplatesComponent,
        ReportTemplateCardComponent,
        ReportTemplateEditBaseDataComponent,
        PosReportTemplateCardComponent,
        PosReportTemplateEditBaseDataComponent,
        PosReportTemplatesComponent,
        ReportTemplatesComponent,
        ApiIntegrationsComponent,
        FeedbackStatusComponent,
        NewsletterCardComponent,
        FeatureStatusComponent,
        FeatureVoteComponent,
        NewsletterEditComponent,
        NewsletterCreateDialogComponent,
        NewsletterEmailEditorComponent,
        FeedbackTextComponent,
        HelpTopicItemComponent,
        FeedbackVoteTextColumnComponent,
        FeatureDescriptionComponent,
        FeedbackTypeColumnComponent,
        HelpSubTopicItemComponent,
        UsersEditBasicDataComponent,
        FeaturesEditComponent,
        HelpDescriptionComponent,
        HelpEditComponent,
        RegisterComponent,
        SubscribersEditComponent,
        ModuleBundlesComponent,
        ContractsComponent,
        ContractEditBaseDataComponent,
        ContractViewComponent,
        ModuleBundleBaseDataComponent,
        ModuleBundleDiscountsComponent,
        ModuleBundleImageComponent,
        LicenseBaseDataComponent,
        LicenseEditUsersComponent,
        LicenseEditUsersResellerComponent,
        LicensePaymentComponent,
        TransactionTypeComponent,
        HardwareBundlesComponent,
        HardwareBundleImageComponent,
        HardwareBundleBaseDataComponent,
        InvoiceBaseDataComponent,
        BenefitDiscountComponent,
        BenefitTypeComponent,
        BenefitPaymentTypeComponent,
        BenefitPaymentPeriodComponent,
        BenefitBasicDataComponent,
        TransactionBaseDataComponent,
        SystemLogComponent,
        SystemLogDetailsTableComponent,
        SystemLogMessageComponent,
        SystemLogStacktraceComponent,
        LicenseDbInfoComponent,
        HelpTooltipsComponent,
        HelpTooltipEditComponent,
        HelpArticleViewComponent,
        TicketMessagesComponent,
        TicketHeaderAdditionalInfoComponent,
        TicketMessageItemComponent,
        LicenseModuleBundlesComponent,
        LicenseModuleBundlesBaseDataComponent,
        TicketBaseDataComponent,
        LicenseDbInfoWidgetComponent,
        TicketListItemComponent,
        TicketStatusComponent,
        TicketListComponent,
        HelpTopicsComponent,
        HelpTopicEditComponent,
        CreateTicketDialogComponent,
        CreateTicketResponseComponent,
        CreateTicketRequestComponent,
        CreateTicketComponent,
        NewTicketComponent,
        SelectedHelpArticleComponent,
        HelpArticleListItemComponent,
        DemoAvailableSoonComponent,
        ConfiguratorAvailableSoonComponent,
        EventMessageItemComponent,
        BlogComponent,
        BlogEditComponent,
        LicenseHardwareOrdersComponent,
        LicenseHardwareOrderBaseDataComponent,
        LicenseHardwareOrderStatusComponent,
        LicenseTransactionsWidgetComponent,
        LicenseNewTransactionDialogComponent,
        LicenseDetailsComponent,
        LicenseDetailsWidgetComponent,
        LicenseValidThruComponent,
        LicenseModuleItemListComponent,
        LicenseModuleItemComponent,
        LicenseProductItemComponent,
        LicenseModulesWidgetComponent,
        InvoicePayViewComponent,
        LicenseAssetsWidgetComponent,
        CustomerInteractionsComponent,
        CustomerInteractionsNoteDetailWidget,
        LicenseHardwareOrdersDetailWidget,
        LicenseInteractionsDetailWidget,
        LicenseInteractionTypeComponent
    ],
    exports: [HomeComponent, LoginComponent, RequestPasswordTokenComponent, ResetPasswordComponent, ResetPasswordComponent]
})
export class ViewsModule {}
