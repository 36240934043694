/* eslint-disable @typescript-eslint/indent */
import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { NumberColumnModel } from '../../../../components/dynamic-table/models/column-types/number-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { LicenseHardwareOrdersStore } from '../../../services/stores/license-hardware-orders.store';
import { LicenseHardwareOrderModel } from '../../../models/license-hardware-orders.model';
import { LicenseHardwareOrderEditModel } from '../../../models/requests/license-hardware-order-edit.model';
import { LicenseHardwareOrderListModel } from '../../../models/responses/license-hardware-order-list.model';
import { LicenseHardwareOrderBaseDataComponent } from './edit-items/license-hardware-order-base-data/license-hardware-order-base-data.component';
import { LicenseHardwareOrderStatusComponent } from './components/hardware-order-status/hardware-order-status.component';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';

@Component({
    selector: 'arc-license-hardware-orders',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './license-hardware-orders.component.scss'
})
export class LicenseHardwareOrdersComponent extends PageListViewComponent<
    LicenseHardwareOrderListModel,
    LicenseHardwareOrderModel,
    LicenseHardwareOrderEditModel
> {
    private readonly licenseHardwareOrdersStore = inject(LicenseHardwareOrdersStore);
    constructor() {
        super();
        this.config = new TableListViewConfigModel<LicenseHardwareOrderListModel, LicenseHardwareOrderModel, LicenseHardwareOrderEditModel>(
            {
                entityName: 'LicenseHardwareOrders',
                availableColumns: {
                    licenseHolder: new StackedColumnModel({
                        columnTitleKey: 'LicenseHardwareOrders.List.LicenseHolder',
                        propertyName: 'licenseHolder',
                        propertyName2: 'licenseId',
                        widthPixels: 300
                    }),
                    quantity: new NumberColumnModel({
                        columnTitleKey: 'LicenseHardwareOrders.List.Quantity',
                        propertyName: 'quantity',
                        widthPixels: 80
                    }),
                    title: new StringColumnModel({
                        columnTitleKey: 'LicenseHardwareOrders.List.Title',
                        propertyName: 'title',
                        widthPixels: 200
                    }),
                    price: new CurrencyColumnModel({
                        columnTitleKey: 'LicenseHardwareOrders.List.Price',
                        propertyName: 'price',
                        currencyIsoCode: item => item.currencyIsoCode,
                        widthPixels: 100
                    }),
                    orderStatus: new CustomColumnModel({
                        columnTitleKey: 'LicenseHardwareOrders.List.OrderStatus',
                        customComponent: LicenseHardwareOrderStatusComponent,
                        widthPixels: 120
                    })
                },
                defaultColumnOrder: ['licenseHolder', 'quantity', 'title', 'price', 'orderStatus'],
                defaultSort: new ColumnSortModel({
                    column: 'id',
                    direction: SortDirectionEnum.Desc
                }),
                availableDetailWidgets: {},
                defaultDetailWidgetOrder: [],
                editSidebarConfig: {
                    editComponents: [
                        {
                            titleKey: 'LicenseHardwareOrders.Edit.BaseData.Name',
                            component: LicenseHardwareOrderBaseDataComponent
                        }
                    ]
                },
                store: this.licenseHardwareOrdersStore
            }
        );
    }
}
