import { Identifyable } from '../../core/abstractions/identifyable';
import { DictionaryType } from '../../core/models/types/dictionary.type';
import { EditSidebarLayoutSettingsModel } from '../../components/sidebar-components/edit-sidebar/models/edit-sidebar-layout-settings.model';

export class LayoutSettingsModel implements Identifyable {
    id!: number;
    /**
     * Stores the order and collapsed state of all "edit items" (expandable containers with edit fields) for all crud pages
     */
    editSidebars: DictionaryType<EditSidebarLayoutSettingsModel> = {};
    isLeftSidebarOpenDesktop = true;

    constructor(init?: Partial<LayoutSettingsModel>) {
        Object.assign(this, init);
    }
}
