import { Component, inject, input, OnInit, signal } from '@angular/core';
import { German } from 'flatpickr/dist/l10n/de.js';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { Italian } from 'flatpickr/dist/l10n/it.js';

import { BaseControlComponent } from '../base-control/base-control.component';
import { TranslationService } from '../../../core/services/translation.service';


@Component({
    selector: 'arc-datetime-picker',
    templateUrl: './datetime-picker.component.html',
    styleUrl: './datetime-picker.component.scss'
})
export class DatetimePickerComponent extends BaseControlComponent implements OnInit {
    min = input<Date>(new Date(1970, 1, 1));
    locale = signal<object | string>('');

    dateFormat = signal('');

    private readonly translationService = inject(TranslationService);

    override ngOnInit(): void {
        super.ngOnInit();
        this.dateFormat.set(this._translationService.current.dateFormatFlatPicker);
        this.locale.set(this.getLocale());
    }

    private getLocale(): object | string {
        switch (this.translationService.current.code) {
            case 'de':
                return German;
            case 'fr':
                return French;
            case 'it':
                return Italian;
            default:
                return 'en';
        }
    }
}
