/* eslint-disable @typescript-eslint/indent */
import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { LicenseModuleBundleModel } from '../../../models/license-module-bundle.model';
import { LicenseModuleBundleEditModel } from '../../../models/requests/license-module-bundle-edit.model';
import { LicenseModuleBundleListModel } from '../../../models/responses/license-module-bundle-list.model';
import { LicenseModuleBundlesStore } from '../../../services/stores/license-module-bundles.store';
import { LicenseModuleBundlesBaseDataComponent } from './edit-items/license-module-bundles-base-data/license-module-bundles-base-data.component';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { NumberColumnModel } from '../../../../components/dynamic-table/models/column-types/number-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';

@Component({
    selector: 'arc-license-module-bundles',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './license-module-bundles.component.scss'
})
export class LicenseModuleBundlesComponent extends PageListViewComponent<
    LicenseModuleBundleListModel,
    LicenseModuleBundleModel,
    LicenseModuleBundleEditModel
> {
    private readonly licenseModuleBundlesStore = inject(LicenseModuleBundlesStore);
    constructor() {
        super();
        this.config = new TableListViewConfigModel<LicenseModuleBundleListModel, LicenseModuleBundleModel, LicenseModuleBundleEditModel>({
            entityName: 'LicenseModuleBundles',
            availableColumns: {
                licenseHolder: new StackedColumnModel({
                    columnTitleKey: 'LicenseModuleBundles.List.LicenseHolder',
                    propertyName: 'licenseHolder',
                    propertyName2: 'licenseId',
                    widthPixels: 300
                }),
                quantity: new NumberColumnModel({
                    columnTitleKey: 'LicenseModuleBundles.List.Quantity',
                    propertyName: 'quantity',
                    widthPixels: 80
                }),
                title: new StringColumnModel({
                    columnTitleKey: 'LicenseModuleBundles.List.Title',
                    propertyName: 'title',
                    widthPixels: 200
                }),
                billedUntilDate: new DateColumnModel({
                    columnTitleKey: 'LicenseModuleBundles.List.BilledUntilDate',
                    propertyName: 'billedUntilDate',
                    widthPixels: 120
                }),
                validThru: new DateColumnModel({
                    columnTitleKey: 'LicenseModuleBundles.List.ValidThru',
                    propertyName: 'validThru',
                    widthPixels: 120
                }),
                price: new CurrencyColumnModel({
                    columnTitleKey: 'LicenseModuleBundles.List.Price',
                    propertyName: 'price',
                    currencyIsoCode: item => item.currencyIsoCode,
                    widthPixels: 100
                }),
                monthlyPrice: new CurrencyColumnModel({
                    columnTitleKey: 'LicenseModuleBundles.List.MonthlyPrice',
                    propertyName: 'monthlyPrice',
                    currencyIsoCode: item => item.currencyIsoCode,
                    widthPixels: 100
                }),
                poolTime: new NumberColumnModel({
                    columnTitleKey: 'LicenseModuleBundles.List.PoolTime',
                    propertyName: 'poolTime',
                    widthPixels: 100
                }),
                cancelledDate: new DateColumnModel({
                    columnTitleKey: 'LicenseModuleBundles.List.CancelledDate',
                    propertyName: 'cancelledDate',
                    widthPixels: 120
                })
            },
            defaultColumnOrder: [
                'licenseHolder',
                'quantity',
                'title',
                'billedUntilDate',
                'validThru',
                'price',
                'monthlyPrice',
                'poolTime',
                'cancelledDate'
            ],
            defaultSort: new ColumnSortModel({
                column: 'id',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'LicenseModuleBundles.Edit.BaseData.Name',
                        component: LicenseModuleBundlesBaseDataComponent
                    }
                ]
            },
            store: this.licenseModuleBundlesStore,
            buttonsVisibility: new ButtonsVisibilityModel({ hasCreate: false })
        });
    }
}
