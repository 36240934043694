import { Component, computed, effect, inject, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { SidebarService } from '../../../../core/services/sidebar.service';
import { BaseComponent } from '../../../abstractions/base.component';
import { RouteService } from '../../../../core/services/route.service';
import { LayoutService } from '../../../../core/services/layout.service';
import { MenuItemModel } from '../../../../core/models/menu-item.model';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'arc-left-sidebar',
    templateUrl: './left-sidebar.component.html',
    styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent extends BaseComponent {
    readonly sidebarWidthPx = 230;
    readonly portalName = environment.isMity ? 'Mity' : 'Arcavis AG';

    readonly currentSubMenu = signal<MenuItemModel[]>([]);
    readonly isEmptyFavouritesList = signal(false);
    readonly isMobile = computed(() => this.layoutService.isMobile());

    readonly sidebarService = inject(SidebarService);
    readonly layoutService = inject(LayoutService);
    private readonly routeService = inject(RouteService);
    private readonly router = inject(Router);

    private readonly homeDirectory = '/home';

    constructor() {
        super();

        effect(() => {
            if (this.isMobile()) {
                this.sidebarService.closeLeft();
            } else {
                this.sidebarService.openLeft();
            }
        });

        // regenerate submenus on route change
        const routerEventsSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.routeService.subMenuItemsSub.subscribe(response => {
                    this.currentSubMenu.set(response);
                    this.setupSubMenu();
                });
                this.routeService.generateSubMenuForUri(event.urlAfterRedirects ?? event.url);
            }
        });

        this.addSubscriptions(routerEventsSubscription);
    }

    handleLeftBackdropClick(): void {
        this.sidebarService.closeLeft();
    }

    private setupSubMenu(): void {
        if (this.currentSubMenu().length === 0) {
            if (this.router.url === this.homeDirectory) {
                this.isEmptyFavouritesList.set(true);
            } else {
                this.sidebarService.closeLeft();
                this.sidebarService.isLeftDisabled = true;
            }
        } else {
            this.sidebarService.isLeftDisabled = false;
            if (!this.isMobile()) {
                this.sidebarService.openLeft();
            } else {
                this.sidebarService.closeLeft();
            }
        }
    }
}
