import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ModuleBundleEditModel } from '../../../../../models/requests/module-bundle-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ModuleBundleQuantityDiscountModel } from '../../../../../models/module-bundle-quantity-discount.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { NumberColumnModel } from '../../../../../../components/dynamic-table/models/column-types/number-column.model';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';
import { ModuleBundleModel } from '../../../../../models/module-bundle.model';

@Component({
    selector: 'arc-module-bundle-discounts',
    templateUrl: './module-bundle-discounts.component.html',
    styleUrl: './module-bundle-discounts.component.scss'
})
export class ModuleBundleDiscountsComponent extends BaseEditSidebarItemComponent<ModuleBundleModel, ModuleBundleEditModel> {
    override formGroup = new FormGroup({
        quantityDiscounts: new ArcFormControl<ModuleBundleQuantityDiscountModel[]>([])
    });

    discountsTableConfig: EditableTableConfigModel<ModuleBundleEditModel>;

    constructor() {
        super();
        this.discountsTableConfig = new EditableTableConfigModel<ModuleBundleEditModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    fromQuantity: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0.0 })]),
                    toQuantity: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0.0 })]),
                    discountPercent: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0.0, max: 100.0 })])
                }),
            rowHeightPx: 63,
            columns: [
                new NumberColumnModel({
                    propertyName: 'fromQuantity',
                    isEditable: true,
                    columnTitleKey: 'ModuleBundle.Edit.Discounts.FromQuantity'
                }),
                new NumberColumnModel({
                    propertyName: 'toQuantity',
                    isEditable: true,
                    columnTitleKey: 'ModuleBundle.Edit.Discounts.ToQuantity'
                }),
                new NumberColumnModel({
                    propertyName: 'discountPercent',
                    isEditable: true,
                    unit: UnitsEnum.Percent,
                    columnTitleKey: 'ModuleBundle.Edit.Discounts.DiscountPercent'
                })
            ]
        });
    }

    onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<ModuleBundleEditModel> {
        return { quantityDiscounts: this.formGroup.value.quantityDiscounts };
    }
}
