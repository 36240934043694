@if (stackTraceString(); as stackTraceString) {
    <button [cdkCopyToClipboard]="stackTraceString" mat-icon-button>
        <mat-icon>copy</mat-icon>
    </button>
}
@for (item of stackTrace(); track $index) {
    <div>
        <p>{{ item }}</p>
    </div>
}
