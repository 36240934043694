import { Component, inject } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TicketListModel } from '../../../models/responses/ticket-list.model';
import { TicketModel } from '../../../models/ticket.model';
import { TicketsStore } from '../../../services/stores/tickets.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { GenericStatusColumnModel } from '../../../../components/dynamic-table/models/column-types/generic-status-column.model';
import { TicketStatusEnumExtensions } from '../../../models/enums/ticket-status.enum';
import { TicketPrioritiesEnumExtensions } from '../../../models/enums/ticket-priorities.enum';
import { RightSidebarConfigModel } from '../../../../core/models/right-sidebar-config.model';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { TicketBaseDataComponent } from './components/ticket-base-data/ticket-base-data.component';
import { TicketSidebarService } from '../../../../core/services/ticket-sidebar.service';
import { TicketMessagesComponent } from './components/ticket-messages/ticket-messages.component';
import { TicketEditModel } from '../../../models/requests/ticket-edit.model';
import { CreateTicketDialogComponent } from './components/create-ticket/create-ticket-dialog/create-ticket-dialog.component';
import { CreateTicketService } from '../../../../core/services/create-ticket.service';
import { NewTicketComponent } from './components/create-ticket/new-ticket/new-ticket.component';
import { NumberColumnModel } from '../../../../components/dynamic-table/models/column-types/number-column.model';

@Component({
    selector: 'arc-tickets',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './tickets.component.scss'
})
export class TicketsComponent extends PageListViewComponent<TicketListModel, TicketModel, TicketEditModel> {
    private readonly ticketsStore = inject(TicketsStore);
    private readonly ticketSidebarService = inject(TicketSidebarService);
    private readonly createTicketService = inject(CreateTicketService);
    private readonly matDialog = inject(MatDialog);

    constructor() {
        super();
        this.config = new TableListViewConfigModel<TicketListModel, TicketModel, TicketEditModel>({
            entityName: 'Feature',
            availableColumns: {
                title: new StackedColumnModel({
                    columnTitleKey: 'Tickets.List.Title',
                    propertyName: 'title',
                    propertyName2: 'id',
                    sortColumn: 'id',
                    widthPixels: 300
                }),
                createdDate: new DateColumnModel({
                    columnTitleKey: 'Tickets.List.CreatedDate',
                    propertyName: 'createdDate',
                    widthPixels: 150
                }),
                resolvedOrClosedDate: new DateColumnModel({
                    columnTitleKey: 'Tickets.List.ResolvedOrClosedDate',
                    propertyName: 'resolvedOrClosedDate',
                    widthPixels: 150
                }),
                licenseHolder: new StringColumnModel({
                    columnTitleKey: 'Tickets.List.LicenseHolder',
                    propertyName: 'licenseHolder',
                    widthPixels: 200
                }),
                reseller: new StringColumnModel({
                    columnTitleKey: 'Tickets.List.Reseller',
                    propertyName: 'resellerName',
                    widthPixels: 200
                }),
                assignedToUser: new StringColumnModel({
                    columnTitleKey: 'Tickets.List.AssignedToUser',
                    propertyName: 'assignedToUser',
                    widthPixels: 150
                }),
                createdByEmail: new StringColumnModel({
                    columnTitleKey: 'Tickets.List.CreatedByEmail',
                    propertyName: 'createdByEmail',
                    widthPixels: 200
                }),
                priority: new GenericStatusColumnModel({
                    columnTitleKey: 'Tickets.List.Priority',
                    propertyName: 'priority',
                    statusColumn: 'priority',
                    descriptionColumn: 'priorityDescription',
                    widthPixels: 160,
                    bgColor: value => TicketPrioritiesEnumExtensions.getColor(value)
                }),
                status: new GenericStatusColumnModel({
                    columnTitleKey: 'Tickets.List.Status',
                    propertyName: 'status',
                    statusColumn: 'status',
                    descriptionColumn: 'statusDescription',
                    widthPixels: 195,
                    bgColor: value => TicketStatusEnumExtensions.getColor(value)
                }),
                totalTime: new NumberColumnModel({
                    columnTitleKey: 'Tickets.List.TotalTime',
                    propertyName: 'totalTime',
                    widthPixels: 120
                })
            },
            defaultColumnOrder: [
                'title',
                'createdDate',
                'priority',
                'status',
                'assignedToUser',
                'createdByEmail',
                'licenseHolder',
                'reseller',
                'resolvedOrClosedDate'
            ],
            defaultSort: new ColumnSortModel({
                column: 'id',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            editSidebarConfig: {
                editComponents: [],
                customEditHandler: (id?: number) => this.openTicketEdit(id)
            },
            handleCreateButtonClick: this.createTicket.bind(this),
            store: this.ticketsStore
        });
    }

    openTicketEdit(ticketId?: number): Observable<OptionalType<boolean>> {
        const config = new RightSidebarConfigModel({
            component: TicketMessagesComponent,
            extraComponent: TicketBaseDataComponent,
            data: ticketId,
            width: 'full',
            maxWidth: '1300px',
            shouldCloseOnBackdropClick: true
        });

        return this.ticketSidebarService.editTicket(config);
    }

    createTicket(): Observable<boolean> {
        return this.matDialog
            .open(CreateTicketDialogComponent, {
                maxHeight: '98svh',
                maxWidth: '98vw'
            })
            .afterClosed()
            .pipe(
                tap<any>(result => {
                    if(!result?.licenseId) {
                        return;
                    }
                    const config = new RightSidebarConfigModel({
                        component: NewTicketComponent,
                        width: 'full',
                        maxWidth: '800px',
                        shouldCloseOnBackdropClick: true
                    });
                    this.createTicketService.startNewTicket(result.licenseId, result.escalated);
                    return this.ticketSidebarService.createTicket(config);
                })
            );
    }
}
