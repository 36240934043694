import { Component, OnInit, inject, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ApiIntegrationModel } from '../../../../../models/api-intagration.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ApiIntegrationEditModel } from '../../../../../models/requests/api-integration-edit.model';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { UserRoles } from '../../../../../models/enums/user-roles.enum';
import { AuthService } from '../../../../../../core/services/auth.service';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';

@Component({
    selector: 'arc-api-integration-edit-base-data',
    templateUrl: './api-integration-edit-base-data.component.html',
    styleUrl: './api-integration-edit-base-data.component.scss'
})
export class ApiIntegrationEditBaseDataComponent
    extends BaseEditSidebarItemComponent<ApiIntegrationModel, ApiIntegrationEditModel>
    implements OnInit {
    ComplexDataTypesEnum = ComplexDataTypesEnum;

    override formGroup = new FormGroup({
        title: new ArcFormControl<string>(undefined, Validators.required),
        userId: new ArcFormControl<number>(undefined),
        maxRequestsPer15Mins: new ArcFormControl<OptionalType<number>>(undefined),
        approvedDate: new ArcFormControl<OptionalType<Date>>(undefined),
        helpText: new ArcFormControl<OptionalType<string>>(undefined, Validators.required)
    });

    readonly isApiIntegrationAdmin = signal(false);

    private readonly authService = inject(AuthService);
    private readonly translationService = inject(TranslationService);

    ngOnInit(): void {
        this.isApiIntegrationAdmin.set(this.authService.hasRole(UserRoles.ApiIntegrationAdmin));

        if (this.isApiIntegrationAdmin()) {
            this.formGroup.controls.userId.setValidators(Validators.required);
            this.formGroup.controls.maxRequestsPer15Mins.setValidators([
                Validators.required,
                CustomValidators.number({ isInteger: true, min: 1 })
            ]);
        }
    }

    override onItemSet(): void {
        const helpText = this.isCreate() ? this.translationService.getText('ApiIntegrations.Edit.DefaultHelpText') : this.item().helpText;
        this.formGroup.patchValue({ ...this.item(), helpText });
    }

    override prepareSaveModel(): Partial<ApiIntegrationEditModel> {
        const value = this.formGroup.getRawValue();
        return {
            title: value.title,
            userId: value.userId,
            maxRequestsPer15Mins: value.maxRequestsPer15Mins,
            approvedDate: value.approvedDate,
            helpText: value.helpText
        };
    }
}
