import { Component, inject, OnInit, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SecurityStorage } from 'src/core/services/storages/security.storage';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { UserEditModel } from '../../../../../models/requests/user-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { UserRoles } from '../../../../../models/enums/user-roles.enum';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { UserModel } from '../../../../../models/user.model';
import { AuthService } from '../../../../../../core/services/auth.service';

@Component({
    selector: 'arc-users-edit-basic-data',
    templateUrl: './users-edit-basic-data.component.html',
    styleUrl: './users-edit-basic-data.component.scss'
})
export class UsersEditBasicDataComponent extends BaseEditSidebarItemComponent<UserModel, UserEditModel> implements OnInit {
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    override formGroup = new FormGroup(
        {
            city: new ArcFormControl('', Validators.required),
            company: new ArcFormControl<OptionalType<string>>(undefined),
            confirmPassword: new ArcFormControl<OptionalType<string>>(undefined),
            countryIsoCode: new ArcFormControl<OptionalType<string>>('CH'),
            currencyIsoCode: new ArcFormControl('CHF', Validators.required),
            email: new ArcFormControl('', [Validators.required, Validators.email]),
            firstName: new ArcFormControl<OptionalType<string>>(undefined),
            invoiceRecipientEmails: new ArcFormControl<OptionalType<string>>(undefined),
            languageCode: new ArcFormControl('de'),
            phoneNumber: new ArcFormControl<OptionalType<string>>(undefined),
            lastName: new ArcFormControl<OptionalType<string>>(undefined),
            password: new ArcFormControl<OptionalType<string>>(undefined),
            roles: new ArcFormControl<OptionalType<UserRoles>>(undefined, Validators.required),
            street: new ArcFormControl('', Validators.required),
            streetNumber: new ArcFormControl<OptionalType<string>>(undefined),
            zip: new ArcFormControl('', Validators.required),
            isReseller: new ArcFormControl<boolean>(false),
            resellerId: new ArcFormControl<OptionalType<number>>(undefined),
            isSuspended: new ArcFormControl(false)
        },
        { validators: CustomValidators.matchFields(['password', 'confirmPassword']) }
    );

    acceptTermsControl = new ArcFormControl(false);
    setPasswordControl = new ArcFormControl(false);

    allowedLanguages: SelectOptionModel<string>[] = [];

    isAdmin = signal(false);
    isReseller = signal(false);

    private readonly translationService = inject(TranslationService);
    private readonly authService = inject(AuthService);
    private readonly securityStorage = inject(SecurityStorage);
    constructor() {
        super();
        this.allowedLanguages = this.translationService.allowedLanguages.map(l => ({ label: l.name, value: l.code }));
        this.isAdmin.set(this.authService.hasRole(UserRoles.Admin));
    }

    ngOnInit(): void {
        const resellerId = this.securityStorage.getUserInfo()?.resellerId;
        this.isReseller.set(!!resellerId);
        if(this.isReseller()) {
            this.formGroup.patchValue({ resellerId });
        }
    }

    onItemSet(): void {
        this.formGroup.patchValue(this.item());
        // This is needed to treat 0 as undefined
        if (!this.item().roles) {
            this.formGroup.controls.roles.setValue(undefined);
        }

        if (this.isCreate()) {
            this.setPasswordControl.setValue(true);
            this.setPasswordControl.disable();
        }
    }

    override prepareSaveModel(): Partial<UserEditModel> {
        const value = this.formGroup.getRawValue();
        const shouldSetPassword = this.setPasswordControl.getRawValue();
        return {
            city: value.city,
            company: value.company,
            countryIsoCode: value.countryIsoCode,
            currencyIsoCode: value.currencyIsoCode,
            email: value.email,
            firstName: value.firstName,
            invoiceRecipientEmails: value.invoiceRecipientEmails,
            languageCode: value.languageCode,
            phoneNumber: value.phoneNumber,
            lastName: value.lastName,
            roles: value.roles,
            street: value.street,
            streetNumber: value.streetNumber,
            zip: value.zip,
            isReseller: value.isReseller,
            resellerId: value.resellerId,
            isSuspended: value.isSuspended,
            password: shouldSetPassword ? value.password : undefined,
            confirmPassword: shouldSetPassword ? value.confirmPassword : undefined
        };
    }
}
