import { Router } from '@angular/router';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs';

import { ArcFormControl } from '../../../../core/utils/arc-form-control';
import { NewUserModel } from '../../../models/requests/new-user.model';
import { UsersStore } from '../../../services/stores/users.store';
import { SelectOptionModel } from '../../../../core/models/select-option.model';
import { TranslationService } from '../../../../core/services/translation.service';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { AlertService } from '../../../../core/services/alert.service';
import { ErrorsService } from '../../../../core/services/errors.service';
import { BrokenRuleModel } from '../../../models/broken-rule.model';
import { BaseComponent } from '../../../../components/abstractions/base.component';
import { ToasterService } from '../../../../core/services/toaster.service';

@Component({
    selector: 'arc-register',
    templateUrl: './register.component.html',
    styleUrl: './register.component.scss'
})
export class RegisterComponent extends BaseComponent implements OnInit, OnDestroy {
    formGroup = new FormGroup({
        email: new ArcFormControl<string>(undefined, [Validators.required, Validators.email]),
        firstName: new ArcFormControl<OptionalType<string>>(undefined, Validators.required),
        lastName: new ArcFormControl<OptionalType<string>>(undefined, Validators.required),
        company: new ArcFormControl<OptionalType<string>>(undefined, Validators.required),
        city: new ArcFormControl('', Validators.required),
        countryIsoCode: new ArcFormControl<OptionalType<string>>(undefined),
        languageCode: new ArcFormControl<string>(undefined, Validators.required),
        street: new ArcFormControl<string>(undefined, Validators.required),
        streetNumber: new ArcFormControl<OptionalType<string>>(undefined),
        zip: new ArcFormControl<string>(undefined, Validators.required),
        isIntegrator: new ArcFormControl(false)
    });
    allowedLanguages: SelectOptionModel<string>[] = [];

    private readonly router = inject(Router);
    private readonly userStore = inject(UsersStore);
    private readonly translationService = inject(TranslationService);
    private readonly alertService = inject(AlertService);
    private readonly errorsService = inject(ErrorsService);
    private readonly toasterService = inject(ToasterService);

    constructor() {
        super();
        this.allowedLanguages = this.translationService.allowedLanguages.map(l => ({ label: l.name, value: l.code }));
    }
    ngOnInit(): void {
        this.errorsService.shouldDisplayAlertOnError = false;
        this.addSubscriptions(this.errorsService.onBusinessException.subscribe(response => {
            this.setBusinessErrors(response.brokenRules ?? []);
            if (!!response.message) {
                this.toasterService.showError(response.message, undefined, true);
            } else {
                this.toasterService.showError('Register.RegisterFailed');
            }
        }));
    }
    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.errorsService.shouldDisplayAlertOnError = true;
    }

    register(): void {
        this.formGroup.markAllAsTouched();

        if (this.formGroup.invalid) {
            return;
        }

        const value = this.formGroup.getRawValue();
        const newUser: NewUserModel = {
            email: value.email,
            firstName: value.firstName,
            lastName: value.lastName,
            company: value.company,
            city: value.city,
            countryIsoCode: value.countryIsoCode,
            languageCode: value.languageCode,
            street: value.street,
            streetNumber: value.streetNumber,
            zip: value.zip,
            isIntegrator: value.isIntegrator
        };
        this.userStore
            .register(newUser)
            .pipe(first())
            .subscribe((response) => {
                if (!!response.value) {
                    this.alertService.showSuccess(
                        'Register.SuccessMessage',
                        undefined,
                        () => this.redirectToLogin()
                    );
                } else {
                    this.alertService.showError(response.message ?? '');
                }
            });
    }

    redirectToLogin(): void {
        this.router.navigate(['login']);
    }

    setBusinessErrors(brokenRules: BrokenRuleModel[]): void {
        if (!this.formGroup) {
            return;
        }

        for (const brokenRule of brokenRules) {
            if (!brokenRule.property) {
                continue;
            }

            const controlName = brokenRule.property.charAt(0).toLowerCase() + brokenRule.property.slice(1);
            const control = this.formGroup.get(controlName);

            if (!control) {
                continue;
            }

            control.setErrors({ businessError: brokenRule.message });
        }
    }
}
