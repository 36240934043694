import { Component } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { StringColumnModel } from '../../models/column-types/string-column.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-string-column',
    templateUrl: './string-column.component.html',
    styleUrls: ['./string-column.component.scss']
})
export class StringColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseColumnComponent<T, TId> {
    get castedColumnModel(): StringColumnModel {
        return this.columnModel as StringColumnModel;
    }
}
