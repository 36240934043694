<div class="w-full h-full flex flex-col relative">
    @if(isLoading()){
        <div class="w-full h-full flex-1 grid place-items-center">
            <mat-spinner [diameter]="32"></mat-spinner>
        </div>
    } @else {
        <arc-static-table [config]="tableConfig" [staticData]="items()" [shouldUseCompactStyle]="true" class="flex-1" />
    }
    <div class="absolute left-0 bottom-0">
        <button mat-icon-button class="float-right" (click)="addLicenseTransaction()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>