import { Directive } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiResponseModel } from '../../app/models/responses/api-response.model';
import { BaseSearchStore } from './base-search.store';
import { Tools } from '../utils/tools';
import { Identifyable } from './identifyable';

/**
 * Store base class for CRUD.
 */
@Directive()
export abstract class BaseCrudStore<
    T extends Identifyable<TId>,
    TSearch extends Identifyable<TId> = T,
    TCreate extends Identifyable<TId> = T,
    TUpdate extends Identifyable<TId> = TCreate,
    TId = number
> extends BaseSearchStore<TSearch, TId> {
    /**
     * Retrieves an item.
     * @param id Item's ID.
     */
    get(id: TId): Observable<ApiResponseModel<T>> {
        return this._requestService.makeGet<ApiResponseModel<T>>(
            this.getUrl(this._config.actions.get),
            Tools.Utils.getId(id),
            this.isAbsoluteUrl,
            undefined,
            true
        );
    }

    /**
     * Retrieves all items.
     */
    getAll(): Observable<ApiResponseModel<T[]>> {
        return this._requestService.makeGet<ApiResponseModel<T[]>>(
            this.getUrl(this._config.actions.get),
            undefined,
            this.isAbsoluteUrl,
            undefined,
            true
        );
    }

    /**
     * Creates a new item.
     * @param data Item's data.
     */
    add(data: TCreate): Observable<ApiResponseModel<number>> {
        return this._requestService.makePost<ApiResponseModel<number>>(
            this.getUrl(this._config.actions.add),
            data,
            this.isAbsoluteUrl,
            undefined,
            true
        );
    }

    /**
     * Update an item's data.
     * @param data Data to be updated.
     */
    edit(data: TUpdate): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePut<ApiResponseModel<boolean>>(
            `${this.getUrl(this._config.actions.edit)}/${Tools.Utils.getId(data.id)}`,
            data,
            this.isAbsoluteUrl
        );
    }

    /**
     * Removes the informed item.
     * @param id Item's ID to be removed.
     */
    remove(id: TId): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makeDelete<ApiResponseModel<boolean>>(
            this.getUrl(this._config.actions.remove),
            Tools.Utils.getId(id),
            this.isAbsoluteUrl
        );
    }

    /**
     * Removes the informed items.
     * @param ids IDs of items to be removed.
     */
    removeMany(ids: TId[]): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makeDelete<ApiResponseModel<boolean>>(
            this.getUrl(this._config.actions.remove),
            undefined,
            this.isAbsoluteUrl,
            undefined,
            undefined,
            ids.map(id => Tools.Utils.getId(id))
        );
    }
}
