import { ComponentRef, Directive, OnInit, ViewContainerRef, effect, inject, input } from '@angular/core';

import { BaseDetailWidget } from '../../components/abstractions/base.detail-widget';
import { TableListDetailWidgetModel } from '../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { Identifyable } from '../abstractions/identifyable';

@Directive({
    selector: '[arcTableDetailWidget]'
})
export class TableDetailWidgetDirective<TList extends Identifyable<TId>, T extends Identifyable<TId>, TId = number> implements OnInit {
    readonly listDetailWidget = input.required<TableListDetailWidgetModel<TList, T, TId>>();
    readonly listModel = input.required<TList>();
    readonly entityModel = input.required<T>();

    private readonly viewContainerRef = inject(ViewContainerRef);
    private componentRef?: ComponentRef<BaseDetailWidget<TList, T, TId>>;

    constructor() {
        effect(() => {
            const listModel = this.listModel();
            this.componentRef?.setInput('listModel', listModel);
        });
        effect(() => {
            const entityModel = this.entityModel();
            this.componentRef?.setInput('entityModel', entityModel);
        });
    }

    ngOnInit(): void {
        this.componentRef = this.viewContainerRef.createComponent(this.listDetailWidget().component);
        this.componentRef?.setInput('listModel', this.listModel());
        this.componentRef?.setInput('entityModel', this.entityModel());
    }
}
