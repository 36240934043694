import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { LicenseModel } from '../../models/license.model';
import { LicenseListModel } from '../../models/responses/license-list.model';
import { LicenseEditModel } from '../../models/requests/license-edit.model';
import { LicenseConnectionModel } from '../../models/license-connection.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { LicenseModuleItemModel } from '../../models/license-module-item.model';
import { TicketListModel } from '../../models/responses/ticket-list.model';
import { KeyValueModel } from '../../../core/models/key-value.model';
import { LicenseApiIntegrationModel } from '../../models/license-api-integration.model';
import { HelpArticleModel } from '../../models/responses/help-article.model';
import { LicenseHardwareOrderListModel } from '../../models/responses/license-hardware-order-list.model';
import { LicenseInteractionModel } from '../../models/license-interaction.model';

@Injectable({
    providedIn: 'root'
})
export class LicensesStore extends BaseCrudStore<LicenseModel, LicenseListModel, LicenseEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'licenses' }));
    }

    getConnectionById(licenseId: number): Observable<ApiResponseModel<LicenseConnectionModel>> {
        return this._requestService.makeGet<ApiResponseModel<LicenseConnectionModel>>(this.getUrl(`${licenseId}/db-info`));
    }

    getLicenseModuleBundles(licenseId: number): Observable<ApiResponseModel<LicenseModuleItemModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<LicenseModuleItemModel[]>>(this.getUrl(`${licenseId}/modules`));
    }

    getAllLicenseModuleBundles(licenseId: number): Observable<ApiResponseModel<LicenseModuleItemModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<LicenseModuleItemModel[]>>(this.getUrl(`${licenseId}/all-modules`));
    }

    getLicenseByUserId(userId: number): Observable<ApiResponseModel<LicenseModel>> {
        return this._requestService.makeGet<ApiResponseModel<LicenseModel>>(this.getUrl(`user/${userId}`));
    }

    getLicenseTickets(licenseId: number, currentTicketId: number): Observable<ApiResponseModel<TicketListModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<TicketListModel[]>>(
            this.getUrl(`${licenseId}/tickets`),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            new KeyValueModel({ key: 'currentTicketId', value: currentTicketId.toString() })
        );
    }

    getLicenseApiIntegrations(licenseId: number): Observable<ApiResponseModel<LicenseApiIntegrationModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<LicenseApiIntegrationModel[]>>(this.getUrl(`${licenseId}/api-integrations`));
    }

    getLicenseHelpArticles(licenseId: number): Observable<ApiResponseModel<HelpArticleModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<HelpArticleModel[]>>(this.getUrl(`${licenseId}/help-articles`));
    }

    getLicenseHardwareOrders(licenseId: number): Observable<ApiResponseModel<LicenseHardwareOrderListModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<LicenseHardwareOrderListModel[]>>(this.getUrl(`${licenseId}/hardware-orders`));
    }

    getLicenseInteractions(licenseId: number): Observable<ApiResponseModel<LicenseInteractionModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<LicenseInteractionModel[]>>(this.getUrl(`${licenseId}/interactions`));
    }
}
