import { Component, inject, AfterViewInit } from '@angular/core';
import { of } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { SearchRequestService } from '../../../../core/services/search-request.service';
import { SecurityStorage } from '../../../../core/services/storages/security.storage';
import { EnvironmentService } from '../../../../core/services/environment.service';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { ActionButtonModel } from '../../../../core/models/action-button.model';
import { Tools } from '../../../../core/utils/tools/index';
import { PosReportTemplateListModel } from '../../../models/responses/pos-report-template-list.model';
import { PosReportTemplateCardComponent } from './pos-report-template-card/pos-report-template-card.component';
import { ReportTemplateListModel } from '../../../models/responses/report-template-list.model';
import { PosReportTemplateModel } from '../../../models/pos-report-template.model';
import { PosReportTemplateEditRequestModel } from '../../../models/requests/pos-report-template-edit-request.model';
import { PosReportTemplatesStore } from '../../../services/stores/pos-report-templates.store';
import { PosReportTemplateParametersService } from '../../../services/pos-report-template-parameters.service';
import { PosReportTemplateEditBaseDataComponent } from './pos-report-template-edit-items/pos-report-edit-base-data/pos-report-edit-base-data.component';
import { UserRoles } from '../../../models/enums/user-roles.enum';
import { SidebarService } from '../../../../core/services/sidebar.service';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-pos-reports',
    templateUrl: './pos-report-templates.component.html',
    styleUrls: ['./pos-report-templates.component.scss']
})
export class PosReportTemplatesComponent extends PageListViewComponent<PosReportTemplateListModel> implements AfterViewInit {
    readonly cardConfig: CardListViewConfigModel<PosReportTemplateModel, PosReportTemplateListModel, PosReportTemplateEditRequestModel>;

    private readonly posReportTemplatesStore = inject(PosReportTemplatesStore);
    private readonly searchRequestService = inject(SearchRequestService);
    private readonly securityStorage = inject(SecurityStorage);
    private readonly environmentService = inject(EnvironmentService);
    private readonly reportTemplateParametersService = inject(PosReportTemplateParametersService);
    private readonly sidebarService = inject(SidebarService);

    constructor() {
        super();

        const adminCustomButtons = [];
        if (this.securityStorage.getUserInfo()?.roles === UserRoles.Admin) {
            adminCustomButtons.push(
                new ActionButtonModel({
                    text: 'PosReportTemplates.DownloadReportFile',
                    isEnabled: true,
                    icon: 'download',
                    min: 1,
                    max: 1,
                    order: 110,
                    clickFn: (btn: ActionButtonModel, data: any) => {
                        data = Array.isArray(data) ? data : [data];
                        this.handleDownloadButtonClick(data);
                    }
                })
            );
        }

        this.config = new CardListViewConfigModel<PosReportTemplateModel, PosReportTemplateListModel, PosReportTemplateEditRequestModel>({
            entityName: 'PosReportTemplates',
            store: this.posReportTemplatesStore,
            cardComponent: PosReportTemplateCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            cardImageCustomClasses: 'bg-card-image object-contain p-4 h-[50%]',
            cardImage: currentItem => {
                const token = this.securityStorage.getUserInfo()?.token;
                const baseUrl = this.environmentService.baseUrl;

                return `${baseUrl}/pos-report-templates/${currentItem.id}/preview?token=${token}&templateKey=${
                    currentItem.templateKey ?? ''
                }`;
            },
            editSidebarConfig: {
                editComponents: [{ titleKey: 'PosReportTemplates.Edit.BaseData', component: PosReportTemplateEditBaseDataComponent }],
                headerSubtitle: item => of(item.title)
            },
            mainButton: new MainCardButtonModel({
                text: 'General.Actions.Open',
                clickFn: (data: any) => {
                    data = Array.isArray(data) ? data : [data];
                    this.reportTemplateParametersService.openReportParametersDialog(data[0]['id']);
                }
            }),
            customActionButtons: [
                new ActionButtonModel({
                    text: 'PosReportTemplates.OpenInEditor',
                    isEnabled: true,
                    min: 1,
                    max: 1,
                    icon: 'design_services',
                    clickFn: (btn: ActionButtonModel, data: any) => {
                        data = Array.isArray(data) ? data : [data];
                        this.handleOpenInEditorButtonClick(data);
                    }
                }),
                ...adminCustomButtons
            ]
        });

        this.cardConfig = this.config;
    }


    handleOpenInEditorButtonClick(items: Identifyable[]): void {
        const id = items[0].id;
        this._router.navigate(['pos-report-templates', 'edit', `tp_${id}`]).then();
    }

    handleDownloadButtonClick(data: ReportTemplateListModel[]): void {
        this.posReportTemplatesStore.downloadReport(data[0].id).subscribe(response => {
            const blob = new Blob(['', response.body], { type: response.body.type });
            Tools.Utils.saveFile(blob, Tools.Utils.getFileNameFromResponse(response));
        });
    }

    openAddReport(): void {
        this.sidebarService
            .openEdit({
                entityName: this.config.entityName,
                store: this.config.store,
                editComponents: [...this.config.editSidebarConfig!.editComponents],
                headerTitle: this.sidebarService.getDefaultRightSidebarHeaderTitleFn(this.config.entityName),
                headerSubtitle: currentItem => of((currentItem as any).title)
            })
            .subscribe(shouldReload => {
                if (shouldReload) {
                    this.searchRequestService.forceReload();
                }
            });
    }
}
