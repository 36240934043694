import { Component, inject, computed } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { PosReportTemplateEditRequestModel } from '../../../../../models/requests/pos-report-template-edit-request.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { PosReportTypeEnum } from '../../../../../../core/models/enums/pos-report-type.enum';

@Component({
    selector: 'arc-pos-report-edit',
    templateUrl: './pos-report-edit-base-data.component.html',
    styleUrls: ['./pos-report-edit-base-data.component.scss']
})
export class PosReportTemplateEditBaseDataComponent
    extends BaseEditSidebarItemComponent<PosReportTemplateEditRequestModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl('', Validators.required),
        title_en: new ArcFormControl('', Validators.required),
        title_it: new ArcFormControl('', Validators.required),
        description_de: new ArcFormControl('', Validators.required),
        description_fr: new ArcFormControl('', Validators.required),
        description_en: new ArcFormControl('', Validators.required),
        description_it: new ArcFormControl('', Validators.required),
        reportType: new ArcFormControl(PosReportTypeEnum.Report, Validators.required),
        key: new ArcFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
        requiredModuleId: new ArcFormControl(),
        version: new ArcFormControl(1.0, Validators.required),
        reportFile: new ArcFormControl<OptionalType<File>>(undefined)
    });

    reportTypeItems = computed(() => {
        const items = [
            {
                label: this.translationService.getText('PosReportTemplates.PosReportTypeEnum.Report'),
                value: PosReportTypeEnum.Report
            },
            {
                label: this.translationService.getText('PosReportTemplates.PosReportTypeEnum.Receipt'),
                value: PosReportTypeEnum.Receipt
            },
            {
                label: this.translationService.getText('PosReportTemplates.PosReportTypeEnum.PosEndOfDay'),
                value: PosReportTypeEnum.PosEndOfDay
            },
            {
                label: this.translationService.getText('PosReportTemplates.PosReportTypeEnum.UserEndOfDay'),
                value: PosReportTypeEnum.UserEndOfDay
            },
            {
                label: this.translationService.getText('PosReportTemplates.PosReportTypeEnum.Order'),
                value: PosReportTypeEnum.Order
            }
        ];

        return items;
    });

    private reportFileBytes?: number[];
    private reportFileName?: string;

    private readonly translationService = inject(TranslationService);

    constructor() {
        super();

        const reportFileChangesSub = this.formGroup.controls.reportFile.valueChanges.subscribe(async (file?: File) => {
            this.reportFileBytes = !!file ? Array.from(new Uint8Array(await file.arrayBuffer())) : undefined;
            this.reportFileName = file?.name;
        });
        this.addSubscriptions(reportFileChangesSub);
    }

    override onItemSet(): void {
        this.formGroup.patchValue({ ...this.item() });

        if (this.isCreate()) {
            this.formGroup.controls.reportFile.setValidators(Validators.required);
        }
    }

    override prepareSaveModel(): Partial<PosReportTemplateEditRequestModel> {
        const value = this.formGroup.getRawValue();
        return {
            title_de: value.title_de,
            title_fr: value.title_fr,
            title_en: value.title_en,
            title_it: value.title_it,
            description_de: value.description_de,
            description_fr: value.description_fr,
            description_en: value.description_en,
            description_it: value.description_it,
            reportType: value.reportType,
            key: value.key,
            requiredModuleId: value.requiredModuleId,
            version: value.version,
            reportBlobData: this.reportFileBytes,
            fileName: this.reportFileName
        };
    }
}
