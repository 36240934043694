import { Component, inject, OnInit, signal } from '@angular/core';

import { LicenseInteractionModel } from '../../../../../models/license-interaction.model';
import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { LicenseModel } from '../../../../../models/license.model';
import { LicenseListModel } from '../../../../../models/responses/license-list.model';
import { LicensesStore } from '../../../../../services/stores/licenses.store';
import { DateColumnModel } from '../../../../../../components/dynamic-table/models/column-types/date-column.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { StaticTableConfigModel } from '../../../../../../components/list-views/static-table/models/static-table-config.model';
import { LicenseInteractionTypeComponent as LicenseInteractionActionComponent } from '../../components/license-interaction-action/license-interaction-action.component';
import { IconColumnModel } from '../../../../../../components/dynamic-table/models/column-types/icon-column.model';
import { IconModel } from '../../../../../../core/models/icon.model';
import { LicenseInteractionTypesEnum } from '../../../../../models/enums/license-interaction-types.enum';
import { CustomColumnModel } from '../../../../../../components/dynamic-table/models/column-types/custom-column.model';

@Component({
    selector: 'arc-license-tickets-interactions-widget',
    templateUrl: './license-interactions-detail-widget.html',
    styleUrl: './license-interactions-detail-widget.scss'
})
export class LicenseInteractionsDetailWidget extends BaseDetailWidget<LicenseListModel, LicenseModel> implements OnInit {
    readonly tableConfig: StaticTableConfigModel = {
        defaultPageSize: 4,
        availableColumns: [
            new DateColumnModel({
                columnTitleKey: 'License.Details.InteractionsWidget.ValueDate',
                propertyName: 'valueDate',
                format: 'short',
                widthPixels: 65
            }),
            new IconColumnModel({
                columnTitleKey: 'License.Details.InteractionsWidget.Type',
                propertyName: 'licenseInteractionType',
                widthPixels: 35,
                iconMapping: new Map([
                    [LicenseInteractionTypesEnum.Chat, new IconModel({ name: 'chat' })],
                    [LicenseInteractionTypesEnum.InPerson, new IconModel({ name: 'person' })],
                    [LicenseInteractionTypesEnum.Email, new IconModel({ name: 'email' })],
                    [LicenseInteractionTypesEnum.Phone, new IconModel({ name: 'phone' })]
                ])
            }),
            new StringColumnModel({
                columnTitleKey: 'License.Details.InteractionsWidget.Notes',
                propertyName: 'notes',
                widthPixels: 155
            }),
            new StringColumnModel({
                columnTitleKey: 'License.Details.InteractionsWidget.Username',
                propertyName: 'username',
                widthPixels: 130
            }),
            new CustomColumnModel({
                propertyName: 'LicenseInteractionActionComponent',
                customComponent: LicenseInteractionActionComponent,
                widthPixels: 40
            })
        ]
    };
    readonly isLoading = signal(false);
    readonly items = signal<LicenseInteractionModel[]>([]);

    private readonly licensesStore = inject(LicensesStore);

    ngOnInit(): void {
        this.isLoading.set(true);
        this.items.set([]);
        const id = this.entityModel().id;
        this.licensesStore.getLicenseInteractions(id).subscribe(response => {
            this.items.set(response?.value || []);
            this.isLoading.set(false);
        });
    }
}
