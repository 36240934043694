<div class="w-full">
    <!-- label container -->
    <div class="mx-[8px] flex w-full" [style.translate.%]="-50 / (optionCount() - 1)">
        @for (option of options(); track $index; let idx = $index; let count = $count) {
            <div class="shrink-0 grow-0 text-center" [style.width.%]="100 / (count - 1)" [style.left.%]="100 / (count - 1) * idx"
                (click)="indexChanged(idx)">
                @if (!!option.labelTitle) {
                    <p class="font-bold">{{ option.isLabelTranslated ? option.labelTitle : (option.labelTitle | transloco) }}</p>
                }
                <p [class.smd:hidden]="!!option.labelTitle">{{ option.isLabelTranslated ? option.label : (option.label | transloco) }}</p>
            </div>
        }
    </div>

    <mat-slider min="0" [max]="optionCount() - 1" step="1" tickInterval="1" [showTickMarks]="true" class="!w-full relative"
        [ngClass]="sliderClasses()">
        <input matSliderThumb [(ngModel)]="currentIndex" (ngModelChange)="indexChanged($event)">
    </mat-slider>
</div>

