import { Component, HostListener, Input, forwardRef, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';
import { ToasterService } from '../../../core/services/toaster.service';
import { AddImageFromUrlDialogComponent } from './add-url-dialog/add-image-from-url-dialog.component';
import { Tools } from '../../../core/utils/tools/index';

/**
 * File upload component.
 * If adding from URL, the return will be a File object with the name filled with the URL. Everything else will be default.
 */
@Component({
    selector: 'arc-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true
        }
    ]
})
export class FileUploadComponent extends BaseControlValueAccessor<File> {
    // comma separated list, e.g.: ".xls,.csv,.xml"
    @Input() fileTypes?: string;
    @Input() shouldForceMinHeight = true;
    @Input() allowUrlUpload = false;
    @Input() maxFileSizeInKb?: number;

    isDragging = false;
    get customClasses(): string[] {
        const cClasses: string[] = [];

        if (this.shouldForceMinHeight) {
            cClasses.push('min-h-[200px]');
        }

        return cClasses;
    }

    private readonly matDialog = inject(MatDialog);
    private readonly toasterService = inject(ToasterService);

    @HostListener('dragenter', ['$event'])
    @HostListener('dragover', ['$event'])
    onDragEnter(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.isDragging = true;
    }

    @HostListener('dragleave', ['$event'])
    @HostListener('dragend', ['$event'])
    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.isDragging = false;
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.isDragging = false;
        const file = event.dataTransfer?.files?.item(0) ?? undefined;

        this.fileChanged(file);
    }

    onFileInputChanged(event: Event): void {
        const target = event.target as HTMLInputElement;
        this.fileChanged(target.files?.item(0) ?? undefined);
        // reset file input
        target.value = '';
    }

    addFromUrl(): void {
        const dialogRef = this.matDialog.open(AddImageFromUrlDialogComponent, {
            height: '200px',
            maxHeight: '98svh',
            width: '400px',
            maxWidth: '98vw'
        });
        dialogRef.afterClosed().subscribe(url => {
            if (!!url) {
                this.valueChanged(new File([], url));
            }
        });
    }

    protected override isValueValid(value?: File): boolean {
        return this.isCorrectFileType(value) && this.hasCorrectFileSize(value);
    }

    private isCorrectFileType(file?: File): boolean {
        if (!file || !this.fileTypes) {
            return true;
        }

        const fileTypesList = this.fileTypes.split(',').map(t => t.toLowerCase().trim());
        const fileNameParts = file.name.split('.');
        const fileExt = fileNameParts.length > 1 ? `.${fileNameParts[fileNameParts.length - 1].toLowerCase()}` : '';

        // check if the file extension is in this.fileTypes
        if (fileTypesList.includes(fileExt)) {
            return true;
        }

        const fileMimeType = file.type.toLowerCase();

        // check if the file MIME type matches one in this.fileTypes (could have a placeholder, e.g. video/*)
        return fileTypesList.some(t => !!t.replace('*', '.*').match(fileMimeType));
    }

    private fileChanged(file?: File): void {
        if (!this.isCorrectFileType(file)) {
            this.toasterService.showError('Components.FileUpload.WrongFileType');
            this.valueChanged(undefined);

            return;
        } else if (!this.hasCorrectFileSize(file)) {
            this.toasterService.showError('Components.FileUpload.IncorrectFileSize',
                undefined,
                undefined,
                undefined,
                { maxSize: Tools.Utils.fileSizeToHumanReadable(this.maxFileSizeInKb! * 1024) }
            );
            this.valueChanged(undefined);

            return;
        }

        this.valueChanged(file);
    }

    private hasCorrectFileSize(file?: File): boolean {
        return !file || !this.maxFileSizeInKb || file.size <= this.maxFileSizeInKb * 1024;
    }
}
