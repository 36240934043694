<div class="w-full" [ngClass]="{ 'border border-solid border-error rounded-md p-2': !!businessError() && formControl?.touched  }">
    <div class="w-full overflow-x-auto" *ngIf="!!config.columns && config.columns.length > 0; else cards">
        <table mat-table [dataSource]="dataSource" matSort class="w-full relative">

            <!-- dynamic columns -->
            <ng-container *ngFor="let columnModel of config.columns" [matColumnDef]="columnModel.identifier"
                [style.height.px]="config.rowHeightPx">
                <th mat-header-cell *matHeaderCellDef class="!pl-0 !pr-[{{rowGapPx}}px]" [style.width.px]="columnModel.widthPixels"
                    [style.min-width.px]="columnModel.widthPixels">
                    @if (!columnModel.shouldHideColumnTitle) {
                        <span>
                            @if (!columnModel.titleSignal) {
                                {{ !columnModel.columnTitleKey ? '' : (columnModel.columnTitleKey | transloco) }}
                                {{ !!columnModel.columnTitleSuffix ? ' ' + columnModel.columnTitleSuffix : '' }}
                            } @else {
                                {{ columnModel.titleSignal() }}
                            }
                        </span>
                    }
                </th>
                <td mat-cell *matCellDef="let formGroup; let i = index" class="!pl-0 !pr-[{{rowGapPx}}px]"
                    [style.height.px]="config.rowHeightPx">
                    <div class="w-full h-full py-2 flex items-center">
                        <ng-container arcDynamicColumn [columnModel]="columnModel" [item]="formGroup.value" [formGroup]="formGroup">
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <!-- delete button -->
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef class="!p-0"></th>
                <td mat-cell *matCellDef="let formGroup; let i = index" class="!p-0">
                    @if (canDeleteRow(formGroup)) {
                        <button type="button" mat-icon-button (click)="deleteRow(i)" [disabled]="!canDeleteRow(formGroup)">
                            <mat-icon>close</mat-icon>
                        </button>
                    }
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" [style.height.px]="config.rowHeightPx">
            </tr>
            <tr mat-row *matRowDef="let formGroup; columns: displayedColumns" [style.height.px]="config.rowHeightPx">
            </tr>

            <tr *matNoDataRow>
                <td [attr.colspan]="displayedColumns.length" class="p-4 text-center">
                    {{ 'Components.EditableTable.NoRows' | transloco }}
                </td>
            </tr>
        </table>
    </div>

    <ng-template #cards>
        <div class="w-full flex flex-col gap-4">
            <mat-card [formGroup]="formGroup" *ngFor="let formGroup of dataSource.filteredData; let i = index"
                class="w-full !border-2 !border-app !border-solid">
                <mat-card-content class="h-full">
                    <div class="flex flex-nowrap">
                        <ng-container *ngTemplateOutlet="template; context: { $implicit: formGroup, index: i }">
                        </ng-container>
                        <div class="flex items-center">
                            <button type="button" mat-icon-button (click)="deleteRow(i)">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </ng-template>

    <div *ngIf="config.showPaging" class="w-full overflow-x-auto">
        <mat-paginator [length]="dataSource.data.length" [pageSize]="config.pageSize"></mat-paginator>
    </div>

    <div class="w-full flex justify-end gap-4 mt-4">
        <ng-container *ngFor="let btn of config.additionalButtons">
            <arc-button type="stroked" mat-stroked-button [isDisabled]="btn.isDisabled"
                [matTooltip]="!!btn.tooltipKey ? (btn.tooltipKey | transloco) : ''" matBadge="?" matBadgeSize="small"
                [matBadgeHidden]="!btn.tooltipKey" (click)="handleAdditionalAddButtonClicked(btn)"
                [isLoading]="additionalButtonsLoading[btn.labelKey]">
                {{ btn.labelKey| transloco }}
            </arc-button>
        </ng-container>
        <button *ngIf="!config.shouldHideAddButton" type="button" mat-stroked-button (click)="addRow()">
            {{ 'General.Actions.Add' | transloco }}
        </button>
    </div>
</div>
@if (!!businessError() && formControl?.touched) {
    <p class="mt-2 text-center text-error">
        {{ businessError() | errorTranslator | async }}
    </p>
}