<div class="flex flex-col gap-4 mt-4">
    <mat-radio-group [formControl]="formGroup.controls.type" class="flex justify-between w-full mb-6">
        <mat-radio-button [value]="TicketTypesEnum.Problem" class="w-1/2">
            <div class="font-semibold"> {{'Tickets.Types.Problem.Title' | transloco}}</div>
            <div> {{'Tickets.Types.Problem.Description' | transloco}}</div>
        </mat-radio-button>
        <mat-radio-button [value]="TicketTypesEnum.Request" class="w-1/2">
            <div class="font-semibold"> {{'Tickets.Types.Request.Title' | transloco}}</div>
            <div> {{'Tickets.Types.Request.Description' | transloco}}</div>
        </mat-radio-button>
    </mat-radio-group>
    <p class="mt-4" [ngClass]="{'text-element-disabled': formGroup.controls.categoryHelpTopicId.disabled}">{{'Tickets.Request.CategoryDescription' | transloco}}</p>
    <arc-select class="w-1/2" labelKey="Tickets.Request.Category" [options]="helpTopics()"
        [control]="formGroup.controls.categoryHelpTopicId"></arc-select>
    <p class="mt-4" [ngClass]="{'text-element-disabled': formGroup.controls.title.disabled}">{{'Tickets.Request.Description' | transloco}}</p>
    <arc-input [control]="formGroup.controls.title" labelKey="Tickets.Request.Title" [showTextCount]="showTextCount()" [maxLength]="256"></arc-input>
</div>