import { computed, Directive, output, signal } from '@angular/core';

import { BaseComponent } from '../../components/abstractions/base.component';
import { TableSelectionModel } from '../models/table-selection.model';

@Directive({})
export abstract class BaseSelectableTable<T> extends BaseComponent {
    readonly selectionChanged = output<T[]>();

    data = signal<T[]>([]);
    // This iteration is required so we check only the current page status.
    isEverythingSelected = computed(() => this.data().every(r => this.selectionModel.isSelected(r)));
    abstract selectionModel: TableSelectionModel<T>;

    itemSelectionChanged(item: T): void {
        this.selectionModel.toggle(item);
        this.selectionChanged.emit(this.selectionModel.selected());
    }

    toggleAllRows(): void {
        if (this.isEverythingSelected()) {
            this.clearSelection();
        } else {
            this.selectAll();
        }
    }

    clearSelection(): void {
        this.selectionModel.clear(this.data());
        this.selectionChanged.emit(this.selectionModel.selected());
    }

    selectAll(): void {
        this.selectionModel.select(...this.data());
        this.selectionChanged.emit(this.selectionModel.selected());
    }
}
