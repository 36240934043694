import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { LicenseModuleBundleEditModel } from '../../../../../models/requests/license-module-bundle-edit.model';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { LicenseModuleBundleModel } from '../../../../../models/license-module-bundle.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-license-module-bundles-base-data',
    templateUrl: './license-module-bundles-base-data.component.html',
    styleUrl: './license-module-bundles-base-data.component.scss'
})
// eslint-disable-next-line max-len
export class LicenseModuleBundlesBaseDataComponent extends BaseEditSidebarItemComponent<LicenseModuleBundleModel, LicenseModuleBundleEditModel> {
    override formGroup = new FormGroup({
        quantity: new ArcFormControl<OptionalType<number>>(undefined, [Validators.required, Validators.min(0)]),
        validThru: new ArcFormControl<OptionalType<Date>>(undefined, Validators.required),
        validFrom: new ArcFormControl<OptionalType<Date>>(undefined, Validators.required),
        cancelledDate: new ArcFormControl<OptionalType<Date>>(undefined),
        billedUntilDate: new ArcFormControl<OptionalType<Date>>(undefined),
        price: new ArcFormControl<OptionalType<number>>(undefined, [Validators.required, Validators.min(0)]),
        poolTime: new ArcFormControl<OptionalType<number>>(undefined, [Validators.required, Validators.min(0)]),
        monthlyPrice: new ArcFormControl<OptionalType<number>>(undefined, Validators.required),
        isDemo: new ArcFormControl(false)
    });

    onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<LicenseModuleBundleEditModel> {
        const value = this.formGroup.getRawValue();
        return {
            quantity: value.quantity,
            validThru: value.validThru,
            validFrom: value.validFrom,
            cancelledDate: value.cancelledDate,
            billedUntilDate: value.billedUntilDate,
            price: value.price,
            poolTime: value.poolTime,
            monthlyPrice: value.monthlyPrice,
            isDemo: value.isDemo
        };
    }
}
