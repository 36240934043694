import { Component, inject, OnInit, signal } from '@angular/core';

import { LicenseHardwareOrderListModel } from '../../../../../models/responses/license-hardware-order-list.model';
import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { CurrencyColumnModel } from '../../../../../../components/dynamic-table/models/column-types/currency-column.model';
import { DateColumnModel } from '../../../../../../components/dynamic-table/models/column-types/date-column.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { StaticTableConfigModel } from '../../../../../../components/list-views/static-table/models/static-table-config.model';
import { LicenseModel } from '../../../../../models/license.model';
import { LicenseListModel } from '../../../../../models/responses/license-list.model';
import { LicensesStore } from '../../../../../services/stores/licenses.store';
import { NumberColumnModel } from '../../../../../../components/dynamic-table/models/column-types/number-column.model';
import { CustomColumnModel } from '../../../../../../components/dynamic-table/models/column-types/custom-column.model';
import { LicenseHardwareOrderStatusComponent } from '../../../license-hardware-orders/components/hardware-order-status/hardware-order-status.component';
import { ActionButtonsService } from '../../../../../../core/services/action-buttons.service';

@Component({
    selector: 'arc-license-hardware-orders-widget',
    templateUrl: './license-hardware-orders.detail-widget.html',
    styleUrl: './license-hardware-orders.detail-widget.scss'
})
export class LicenseHardwareOrdersDetailWidget extends BaseDetailWidget<LicenseListModel, LicenseModel> implements OnInit {
    readonly tableConfig: StaticTableConfigModel = {
        defaultPageSize: 4,
        availableColumns: [
            new DateColumnModel({
                columnTitleKey: 'License.Details.HardwareOrdersWidget.OrderedDate',
                propertyName: 'orderedDate',
                widthPixels: 88
            }),
            new NumberColumnModel({
                columnTitleKey: 'License.Details.HardwareOrdersWidget.Quantity',
                propertyName: 'quantity',
                widthPixels: 44
            }),
            new StringColumnModel({
                columnTitleKey: 'License.Details.HardwareOrdersWidget.Title',
                propertyName: 'title',
                widthPixels: 70
            }),
            new CustomColumnModel({
                columnTitleKey: 'License.Details.HardwareOrdersWidget.SentDate',
                customComponent: LicenseHardwareOrderStatusComponent,
                widthPixels: 100
            }),
            new CurrencyColumnModel({
                columnTitleKey: 'License.Details.HardwareOrdersWidget.Price',
                propertyName: 'price',
                currencyIsoCode: item => item.currencyIsoCode,
                widthPixels: 70
            })
        ]
    };
    readonly isLoading = signal(false);
    readonly items = signal<LicenseHardwareOrderListModel[]>([]);

    private readonly licensesStore = inject(LicensesStore);
    private readonly actionButtonsService = inject(ActionButtonsService);
    private readonly licenseAddHardwareContextAction = 'LicenseAddHardwareContextAction';

    ngOnInit(): void {
        this.isLoading.set(true);
        this.items.set([]);
        this.licensesStore.getLicenseHardwareOrders(this.entityModel().id).subscribe(response => {
            this.items.set(response?.value || []);
            this.isLoading.set(false);
        });
    }

    addHardwareOrder(): void {
        if (this.actionButtonsService.isHidden(this.licenseAddHardwareContextAction, this.listModel())) {
            return;
        }
        this.actionButtonsService.handleClick(this.licenseAddHardwareContextAction, this.listModel());
    }
}
