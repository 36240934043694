@if (columnModel.isEditable && !!control) {
    <arc-input [control]="control | formControl" [action]="castedColumnModel.action"
        [actionIcon]="castedColumnModel.actionIcon"></arc-input>
} @else {
    <p [class.truncate]="!castedColumnModel.suffixPropertyName || castedColumnModel.isCompact || castedColumnModel.shouldTruncateText"
        [style.text-align]="castedColumnModel.textAlignment"
        [class.text-on-app-light]="!!castedColumnModel.suffixPropertyName || control?.disabled"
        [matTooltip]="castedColumnModel.shouldShowTooltip ? getItemValue(castedColumnModel.propertyName) : null">
        <span class="text-on-app" [class.text-sm]="castedColumnModel.isCompact"
            [class.truncate]="!castedColumnModel.suffixPropertyName"
            [class.font-bold]="isBold(castedColumnModel.propertyName)">
            {{ getItemValue(castedColumnModel.propertyName) }}
        </span>
        <span *ngIf="!!castedColumnModel.suffixPropertyName" class="ml-2 text-sm text-on-app-light"
            [class.text-xs]="castedColumnModel.isCompact">
            {{ getItemValue(castedColumnModel.suffixPropertyName) }}
        </span>
    </p>
}