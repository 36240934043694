import { Component, Input, OnInit } from '@angular/core';

import { CardListViewConfigModel } from './models/card-list-view-config.model';
import { BaseListViewComponent } from '../../../core/abstractions/base-list-view-component';
import { MainCardButtonModel } from '../../../core/models/main-card-button.model';
import { Identifyable } from '../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-card-list-view',
    templateUrl: './card-list-view.component.html',
    styleUrls: ['./card-list-view.component.scss']
})
export class CardListViewComponent<
    T extends Identifyable<TId>,
    TList extends Identifyable<TId>,
    TCreate extends Identifyable<TId> = T,
    TUpdate extends Identifyable<TId> = TCreate,
    TId = number
> extends BaseListViewComponent<T, TList, TCreate, TUpdate, TId> implements OnInit {
    @Input() config!: CardListViewConfigModel<T, TList, TCreate, TUpdate, TId>;

    imageClasses?: string;
    mainButton?: MainCardButtonModel;
    hasMore = false;

    ngOnInit(): void {
        super.onInit();

        this.imageClasses = this.config.cardImageCustomClasses;
        this.mainButton = this.config.mainButton;
    }

    onImageClick(item: Identifyable<TId>): void {
        if (!!this.mainButton) {
            this.mainButton!.clickFn(item);
        }
    }

    loadMore(): void {
        const nextSet = this.paginator.pageSize * 2;
        this.paginator._changePageSize(nextSet);
    }

    override afterLoadedCallback(): void {
        this.hasMore = this.data().length < this.totalRecords;
    }
}
