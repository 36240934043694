<div
    class="inline-flex justify-center items-center py-1 px-2 rounded"
    [style.height.px]="32"
    [style.min-width.px]="110"
    [ngClass]="colorClasses">
    @if(item.isLocked) { 
        {{ 'License.List.IsLocked' | transloco }} 
    }@else if(item.isDemo) { 
        {{ 'License.List.IsDemo' | transloco }} 
    }@else if(item.status === LicenseStatusEnum.Draft){ 
        {{ 'License.Status.Draft' | transloco }} 
    }@else if(!!item.resellerId) { 
        {{ 'License.Edit.Users.Reseller' | transloco }} 
    }@else {
        {{ item.validThru  | arcDate: 'shortDate'}}
    }
</div>