import { Component, inject, AfterViewInit } from '@angular/core';
import { of } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { ReportTemplateListModel } from '../../../models/responses/report-template-list.model';
import { SearchRequestService } from '../../../../core/services/search-request.service';
import { SecurityStorage } from '../../../../core/services/storages/security.storage';
import { EnvironmentService } from '../../../../core/services/environment.service';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { ReportTemplateModel } from '../../../models/report-template.model';
import { ActionButtonModel } from '../../../../core/models/action-button.model';
import { Tools } from '../../../../core/utils/tools/index';
import { Identifyable } from '../../../../core/abstractions/identifyable';
import { SidebarService } from '../../../../core/services/sidebar.service';
import { ReportTemplatesStore } from '../../../services/stores/report-templates.store';
import { ReportTemplateEditRequestModel } from '../../../models/requests/report-template-edit-request.model';
import { ReportTemplateParametersService } from '../../../services/stores/report-template-parameters.service';
import { ReportTemplateCardComponent } from './report-template-card/report-template-card.component';
import { ReportTemplateEditBaseDataComponent } from './report-template-edit-items/report-template-edit-base-data/report-template-edit-base-data.component';
import { UserRoles } from '../../../models/enums/user-roles.enum';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';

@Component({
    selector: 'arc-reports',
    templateUrl: './report-templates.component.html',
    styleUrls: ['./report-templates.component.scss']
})
export class ReportTemplatesComponent extends PageListViewComponent<ReportTemplateListModel> implements AfterViewInit {
    readonly cardConfig: CardListViewConfigModel<ReportTemplateModel, ReportTemplateListModel, ReportTemplateEditRequestModel>;

    private readonly reportsStore = inject(ReportTemplatesStore);
    private readonly searchRequestService = inject(SearchRequestService);
    private readonly securityStorage = inject(SecurityStorage);
    private readonly environmentService = inject(EnvironmentService);
    private readonly reportParametersService = inject(ReportTemplateParametersService);
    private readonly sidebarService = inject(SidebarService);

    constructor() {
        super();

        const adminCustomButtons = [];
        if (this.securityStorage.getUserInfo()?.roles === UserRoles.Admin) {
            adminCustomButtons.push(
                new ActionButtonModel({
                    text: 'ReportTemplates.DownloadReportFile',
                    isEnabled: true,
                    min: 1,
                    max: 1,
                    icon: 'download',
                    order: 110,
                    clickFn: (btn: ActionButtonModel, data: any) => {
                        data = Array.isArray(data) ? data : [data];
                        this.handleDownloadButtonClick(data);
                    }
                })
            );
        }

        this.config = new CardListViewConfigModel<ReportTemplateModel, ReportTemplateListModel, ReportTemplateEditRequestModel>({
            entityName: 'ReportTemplates',
            store: this.reportsStore,
            cardComponent: ReportTemplateCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            cardImageCustomClasses: 'bg-card-image object-contain p-4 h-[50%]',
            cardImage: currentItem => {
                const token = this.securityStorage.getUserInfo()?.token;
                const baseUrl = this.environmentService.baseUrl;

                return `${baseUrl}/report-templates/${currentItem.id}/preview?token=${token}&templateKey=${currentItem.templateKey ?? ''}`;
            },
            editSidebarConfig: {
                editComponents: [{ titleKey: 'ReportTemplates.Edit.BaseData', component: ReportTemplateEditBaseDataComponent }],
                headerSubtitle: currentItem => of(currentItem.title)
            },
            mainButton: new MainCardButtonModel({
                text: 'General.Actions.Open',
                clickFn: (data: any) => {
                    data = Array.isArray(data) ? data : [data];
                    this.reportParametersService.openReportParametersDialog(data[0]['id']);
                }
            }),
            customActionButtons: [
                new ActionButtonModel({
                    text: 'ReportTemplates.OpenInEditor',
                    isEnabled: true,
                    min: 1,
                    max: 1,
                    icon: 'design_services',
                    clickFn: (btn: ActionButtonModel, data: any) => {
                        data = Array.isArray(data) ? data : [data];
                        this.handleOpenInEditorButtonClick(data);
                    }
                }),
                ...adminCustomButtons
            ]
        });

        this.cardConfig = this.config;
    }

    handleOpenInEditorButtonClick(items: Identifyable[]): void {
        const id = items[0].id;
        this._router.navigate(['report-templates', 'edit', `t_${id}`]).then();
    }

    handleDownloadButtonClick(data: ReportTemplateListModel[]): void {
        this.reportsStore.downloadReport(data[0].id).subscribe(response => {
            const blob = new Blob(['', response.body], { type: response.body.type });
            Tools.Utils.saveFile(blob, Tools.Utils.getFileNameFromResponse(response));
        });
    }

    openAddReport(): void {
        this.sidebarService
            .openEdit({
                entityName: this.config.entityName,
                store: this.config.store,
                editComponents: [...this.config.editSidebarConfig!.editComponents],
                headerTitle: this.sidebarService.getDefaultRightSidebarHeaderTitleFn(this.config.entityName),
                headerSubtitle: currentItem => of((currentItem as any).title)
            })
            .subscribe(shouldReload => {
                if (shouldReload) {
                    this.searchRequestService.forceReload();
                }
            });
    }
}
