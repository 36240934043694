import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { PortalLogModel } from '../../../models/portal-log.model';
import { PortalLogListModel } from '../../../models/responses/portal-log-list.model';
import { PortalLogsStore } from '../../../services/stores/porta-logs.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { GenericStatusColumnModel } from '../../../../components/dynamic-table/models/column-types/generic-status-column.model';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { LogLevelEnumExtensions } from '../../../../core/models/enums/log-level.enum';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { SystemLogDetailsTableComponent } from './sytem-log-details-widgets/system-log-details-table/system-log-details-table.component';
import { SystemLogMessageComponent } from './sytem-log-details-widgets/system-log-message/system-log-message.component';
import { SystemLogStacktraceComponent } from './sytem-log-details-widgets/system-log-stacktrace/system-log-stacktrace.component';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-system-log',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './system-log.component.scss'
})
export class SystemLogComponent extends PageListViewComponent<PortalLogListModel, PortalLogModel> {
    private readonly portalLogsStore = inject(PortalLogsStore);

    constructor() {
        super();
        this.config = new TableListViewConfigModel<PortalLogListModel, PortalLogModel>({
            entityName: 'Log',
            availableColumns: {
                id: new StringColumnModel({
                    columnTitleKey: 'PortalLogs.List.Id',
                    propertyName: 'id',
                    widthPixels: 400
                }),
                correlation: new StringColumnModel({
                    columnTitleKey: 'PortalLogs.List.CorrelationId',
                    propertyName: 'correlationId',
                    widthPixels: 400
                }),
                date: new DateColumnModel({
                    columnTitleKey: 'PortalLogs.List.Date',
                    propertyName: 'timeStamp',
                    format: 'short',
                    widthPixels: 200
                }),
                level: new GenericStatusColumnModel({
                    columnTitleKey: 'PortalLogs.List.Level',
                    propertyName: 'level',
                    statusColumn: 'level',
                    descriptionColumn: 'levelDescription',
                    widthPixels: 160,
                    bgColor: value => LogLevelEnumExtensions.getColor(value)
                }),
                message: new StringColumnModel({
                    columnTitleKey: 'PortalLogs.List.Message',
                    propertyName: 'message',
                    widthPixels: 400
                }),
                context: new StringColumnModel({
                    columnTitleKey: 'PortalLogs.List.Context',
                    propertyName: 'context',
                    widthPixels: 400
                }),
                user: new StringColumnModel({
                    columnTitleKey: 'PortalLogs.List.User',
                    propertyName: 'userName',
                    widthPixels: 150
                })
            },
            defaultSort: new ColumnSortModel({
                column: 'timeStamp',
                direction: SortDirectionEnum.Desc
            }),
            defaultColumnOrder: ['date', 'level', 'message', 'correlation', 'user', 'context'],
            availableDetailWidgets: {
                message: new TableListDetailWidgetModel({
                    name: 'PortalLogs.Details.MessageWidget',
                    component: SystemLogMessageComponent
                }),
                detailsTable: new TableListDetailWidgetModel({
                    name: 'PortalLogs.Details.DetailsTable',
                    component: SystemLogDetailsTableComponent
                }),
                stackTrace: new TableListDetailWidgetModel({
                    name: 'PortalLogs.Details.StackTraceWidget',
                    component: SystemLogStacktraceComponent
                })
            },
            defaultDetailWidgetOrder: ['message', 'detailsTable', 'stackTrace'],
            store: this.portalLogsStore,
            buttonsVisibility: new ButtonsVisibilityModel({ hasCreate: false, hasDelete: false, hasEdit: false, hasExport: false })
        });
    }
}
