<div class="h-full flex flex-col w-full">
    <table mat-table [dataSource]="dataSource" [class.compact-style]="shouldUseCompactStyle()" [class.single-line-row]="isSingleLineRow()">
        <ng-container matColumnDef="checkboxes">
            <th mat-header-cell *matHeaderCellDef class="!p-0" [style.width.px]="32">
                <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selectionModel.hasValue() && isEverythingSelected()"
                    [indeterminate]="selectionModel.hasValue() && !isEverythingSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" class="!p-0">
                <mat-checkbox (change)="itemSelectionChanged(row)" [checked]="selectionModel.isSelected(row)" />
            </td>
        </ng-container>
        @for (column of config().availableColumns; track column.identifier) {
            <ng-container [matColumnDef]="column.identifier">
                @if (!column.shouldHideColumnTitle) {
                    <th mat-header-cell *matHeaderCellDef [class.text-xs]="shouldUseCompactStyle()"
                        [style.min-width.px]="column.widthPixels" [style.max-width.px]="column.widthPixels"
                        [style.width.px]="column.widthPixels">
                        @if (!column.titleSignal) {
                            {{ column.columnTitleKey | transloco }}
                            {{ !!column.columnTitleSuffix ? ' ' + column.columnTitleSuffix : '' }}
                        } @else {
                            {{ column.titleSignal() }}
                        }
                    </th>
                }
                <td mat-cell *matCellDef="let row">
                    <!-- dynamic columns -->
                    <div class="h-full flex items-center pr-[4px]" [style.padding-right.px]="columnGap"
                        [style.min-width.px]="column.widthPixels" [style.max-width.px]="column.widthPixels">
                        <ng-container arcDynamicColumn [columnModel]="column" [item]="row" />
                    </div>
                </td>
            </ng-container>
        }

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4"></td>
        </tr>
    </table>
    @if (shouldUseCompactStyle()) {
        <mat-paginator class="absolute bottom-0.5 right-0 bg-transparent w-full" [hidePageSize]="true" [length]="totalRecords()"
            [showFirstLastButtons]="false">
        </mat-paginator>
    } @else {
        <mat-paginator [length]="totalRecords()" [showFirstLastButtons]="true" />
    }
</div>
