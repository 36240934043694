import { Component, inject, input, OnInit, signal } from '@angular/core';

import { KeyValueModel } from '../../../../../../core/models/key-value.model';
import { LicenseModel } from '../../../../../models/license.model';
import { LicensesStore } from '../../../../../services/stores/licenses.store';

@Component({
    selector: 'arc-license-details',
    templateUrl: './license-details.component.html',
    styleUrl: './license-details.component.scss'
})
export class LicenseDetailsComponent implements OnInit {
    license = input.required<LicenseModel>();
    isLoading = signal(true);
    minValidFrom = signal<Date | undefined>(undefined);
    maxValidThru = signal<Date | undefined>(undefined);

    licenseModules = signal<KeyValueModel[]>([]);

    private readonly licenseStore = inject(LicensesStore);

    ngOnInit(): void {
        this.licenseStore.getLicenseModuleBundles(this.license().id).subscribe(result => {
            this.licenseModules.set(
                result.value
                    ?.filter(x => !x.cancelledDate || x.cancelledDate > new Date())
                    .map(y => new KeyValueModel({ key: y.key, value: y.title })) || []
            );
            this.minValidFrom.set(result.value?.map(y => y.validFrom).sort((a, b) => a.getTime() - b.getTime())[0]);
            this.maxValidThru.set(result.value?.map(y => y.validThru).sort((a, b) => b.getTime() - a.getTime())[0]);
            this.isLoading.set(false);
        });
    }
}

