import { Directive, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

import { BaseComponent } from '../../abstractions/base.component';
import { Tools } from '../../../core/utils/tools';
import { TranslationService } from '../../../core/services/translation.service';
import { ModuleIdentificationsEnum } from '../../../app/models/enums/module-identifications.enum';

@Directive()
export abstract class BaseControlComponent extends BaseComponent implements OnInit {
    @Input() control!: AbstractControl;
    @Input() label?: string;
    @Input() labelKey?: string;
    @Input() placeholder?: string;
    @Input() placeholderKey?: string;
    @Input() tagId = '';
    @Input() color: ThemePalette = 'primary';
    @Input() hasFloatingLabel = false;
    @Input() hasFixedSubscript = false;
    @Input() requiredModule?: ModuleIdentificationsEnum;

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly blur = new EventEmitter<void>();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly change = new EventEmitter<any>();

    isAllowed = true;

    protected readonly _translationService = inject(TranslationService);

    get castedControl(): FormControl {
        return this.control as FormControl;
    }
    get formGroup(): FormGroup {
        return this.control.parent as FormGroup;
    }

    ngOnInit(): void {
        if (!this.tagId) {
            this.tagId = Tools.Utils.newGuid();
        }
    }

    handleNotAllowed(): void {
        this.isAllowed = false;
        this.control.disable();
    }

    onBlur(): void {
        this.blur.emit();
    }

    onChange(event: any): void {
        this.change.emit(event);
    }
}
