import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { UserListModel } from '../../../models/responses/user-list.model';
import { UserModel } from '../../../models/user.model';
import { UsersStore } from '../../../services/stores/users.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { UsersEditBasicDataComponent } from './users-edit-items/users-edit-basic-data/users-edit-basic-data.component';
import { UserEditModel } from '../../../models/requests/user-edit.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';

@Component({
    selector: 'arc-users',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './users.component.scss'
})
export class UsersComponent extends PageListViewComponent<UserListModel, UserModel, UserEditModel> {
    private readonly usersStore = inject(UsersStore);
    constructor() {
        super();
        this.config = new TableListViewConfigModel<UserListModel, UserModel, UserEditModel>({
            entityName: 'User',
            availableColumns: {
                id: new StringColumnModel({
                    columnTitleKey: 'User.List.Id',
                    propertyName: 'id',
                    widthPixels: 80
                }),
                displayName: new StackedColumnModel({
                    columnTitleKey: 'User.List.DisplayName',
                    propertyName: 'userName',
                    propertyName2: 'company',
                    widthPixels: 300
                }),
                email: new StringColumnModel({
                    columnTitleKey: 'User.List.Email',
                    propertyName: 'email',
                    widthPixels: 300
                }),
                phoneNumber: new StringColumnModel({
                    columnTitleKey: 'User.List.Phone',
                    propertyName: 'phoneNumber',
                    widthPixels: 150
                }),
                description: new StringColumnModel({
                    columnTitleKey: 'User.List.Description',
                    propertyName: 'description',
                    widthPixels: 250
                }),
                language: new StringColumnModel({
                    columnTitleKey: 'User.List.Language',
                    propertyName: 'languageCode',
                    widthPixels: 100
                })
            },
            defaultColumnOrder: ['id', 'displayName', 'email', 'phoneNumber', 'description', 'language'],
            defaultSort: new ColumnSortModel({
                column: 'id',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'User.Edit.Name',
                        component: UsersEditBasicDataComponent
                    }
                ]
            },
            store: this.usersStore
        });
    }
}
