<h1 mat-dialog-title>{{ 'CustomerInteraction.Title' | transloco }}</h1>

<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto !flex flex-col gap-4 !pt-2">
    <arc-quick-search [config]="ComplexDataTypesEnum.License | arcQuickSearchConfig" [formControl]="formGroup.controls.licenseId"
        label="CustomerInteraction.License"></arc-quick-search>
    <arc-quick-search [config]="ComplexDataTypesEnum.User | arcQuickSearchConfig" [formControl]="formGroup.controls.userId"
        label="CustomerInteraction.User"></arc-quick-search>
    <div class="flex gap-4">
        <arc-button-toggle-group [control]="formGroup.controls.type" [items]="customerInteractionTypeItems"></arc-button-toggle-group>
        <arc-button-toggle-group [control]="formGroup.controls.mood" [items]="customerInteractionMoodItems"></arc-button-toggle-group>
    </div>
    <arc-textarea [control]="formGroup.controls.message" labelKey="CustomerInteraction.Message"></arc-textarea>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="{ shouldReload: false }">{{ 'General.Actions.Cancel' | transloco }}</button>
    @if(canCreate()) {
        <arc-button type="flat" color="accent" [isLoading]="isCreating()" (clicked)="createCustomerInteraction()">
            {{ 'General.Actions.Create' | transloco }}
        </arc-button>
    }
</mat-dialog-actions>