import { Component, computed, inject, input } from '@angular/core';

import { ActionButtonsService } from '../../../../../../core/services/action-buttons.service';
import { LicenseModuleItemModel } from '../../../../../models/license-module-item.model';
import { LicenseModel } from '../../../../../models/license.model';

@Component({
    selector: 'arc-license-module-item',
    templateUrl: './license-module-item.component.html',
    styleUrl: './license-module-item.component.scss'
})
export class LicenseModuleItemComponent {
    module = input.required<LicenseModuleItemModel>();
    license = input.required<LicenseModel>();

    readonly now = new Date();

    moduleClasses = computed(() => {
        if (!this.module().isLicensed) {
            return 'border-2 border-solid border-light-gray dark:border-dark-gray rounded';
        } else if (!!this.module().cancelledDate) {
            return this.module().cancelledDate! > new Date() ? 'bg-warn text-on-warn' : 'bg-error text-on-error';
        } else if (this.module().validFrom > new Date()) {
            return 'bg-success-light text-accent';
        } else if (this.module().validFrom <= new Date()) {
            return 'bg-success text-on-success';
        }
        return '';
    });

    private readonly actionButtonsService = inject(ActionButtonsService);

    handleClick(): void {
        const contextAction =
            this.module().cancelledDate || !this.module().isLicensed
                ? 'LicenseAddModulesContextAction_True'
                : 'LicenseCancelModulesContextAction';
        const parameters = {
            [this.module().id]: true
        };
        this.actionButtonsService.handleClick(contextAction, this.license(), parameters);
    }
}
