import { Component, inject } from '@angular/core';
import { UserRoles } from 'src/app/models/enums/user-roles.enum';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { HelpArticleListModel } from '../../../models/responses/help-article-list.model';
import { HelpArticleModel } from '../../../models/responses/help-article.model';
import { HelpArticlesStore } from '../../../services/stores/help-articles.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { HelpTopicItemComponent } from './components/help-topic-item/help-topic-item.component';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { HelpSubTopicItemComponent } from './components/help-sub-topic-item/help-sub-topic-item.component';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { HelpDescriptionComponent } from './help-detail-widgets/help-description/help-description.component';
import { HelpEditComponent } from './help-edit-items/help-edit/help-edit.component';
import { HelpArticleEditModel } from '../../../models/requests/help-article-edit.model';

@Component({
    selector: 'arc-help',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './help.component.scss'
})
export class HelpComponent extends PageListViewComponent<HelpArticleListModel, HelpArticleModel, HelpArticleEditModel> {
    private readonly helpArticlesStore = inject(HelpArticlesStore);

    constructor() {
        super();
        const canEdit = this._authService.hasRole(UserRoles.HelpArticlesAdmin);
        this.config = new TableListViewConfigModel<HelpArticleListModel, HelpArticleModel, HelpArticleEditModel>({
            entityName: 'HelpArticle',
            availableColumns: {
                title: new StackedColumnModel({
                    columnTitleKey: 'HelpArticle.List.Title',
                    propertyName: 'title',
                    propertyName2: 'id',
                    widthPixels: 260
                }),
                helpTopic: new CustomColumnModel({
                    columnTitleKey: 'HelpArticle.List.HelpTopic',
                    propertyName: 'helpTopic',
                    widthPixels: 200,
                    customComponent: HelpTopicItemComponent
                }),
                helpSubTopic: new CustomColumnModel({
                    columnTitleKey: 'HelpArticle.List.HelpSubTopic',
                    propertyName: 'helpSubTopic',
                    widthPixels: 220,
                    customComponent: HelpSubTopicItemComponent
                }),
                lastChangeUserName: new StringColumnModel({
                    columnTitleKey: 'HelpArticle.List.LastChangeUserName',
                    propertyName: 'lastChangeUserName',
                    widthPixels: 160
                }),
                lastChangeDate: new DateColumnModel({
                    columnTitleKey: 'HelpArticle.List.LastChangeDate',
                    propertyName: 'lastChangeDate',
                    format: 'short',
                    widthPixels: 180
                }),
                views: new StringColumnModel({
                    columnTitleKey: 'HelpArticle.List.Views',
                    propertyName: 'views',
                    widthPixels: 120
                }),
                upVotes: new StringColumnModel({
                    columnTitleKey: 'HelpArticle.List.UpVotes',
                    propertyName: 'upVotes',
                    widthPixels: 120
                }),
                downVotes: new StringColumnModel({
                    columnTitleKey: 'HelpArticle.List.DownVotes',
                    propertyName: 'downVotes',
                    widthPixels: 120
                })
            },
            defaultColumnOrder: [
                'title',
                'helpTopic',
                'helpSubTopic',
                'lastChangeUserName',
                'lastChangeDate',
                'views',
                'upVotes',
                'downVotes'
            ],
            defaultSort: new ColumnSortModel({
                column: 'id',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {
                description: new TableListDetailWidgetModel({
                    name: 'HelpArticle.Detail.DescriptionWidget',
                    component: HelpDescriptionComponent,
                    shouldHideHeader: true,
                    takeFullSizeIfOnly: true
                })
            },
            defaultDetailWidgetOrder: ['description'],
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'HelpArticle.Edit.Name',
                        component: HelpEditComponent
                    }
                ]
            },
            store: this.helpArticlesStore,
            showCheckboxes: canEdit,
            buttonsVisibility: {
                hasEdit: canEdit,
                hasDelete: canEdit,
                hasCreate: canEdit,
                hasExport: false
            }
        });
    }
}
