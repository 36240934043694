import { Utils } from '../utils/tools/utils.tools';
import { ModalVisibilityEnum } from './enums/modal-visibility.enum';
import { Identifyable } from '../abstractions/identifyable';
import { DictionaryType } from './types/dictionary.type';

export class ActionButtonModel {
    key: string = Utils.newGuid();
    text!: string;
    isTextTranslated = false;
    icon?: string;
    min!: number;
    max!: number;
    hasParameters!: boolean;
    hasSteps!: boolean;
    isHiddenOnSelection!: boolean;
    steps?: number;
    color?: string;
    isEnabled = false;
    isLoading = false;
    order = 100;
    conditionField?: string;
    failedConditionMessage?: string;
    modalVisibility = ModalVisibilityEnum.None;
    clickFn?: (btn: ActionButtonModel, data: any, extraParameters?: DictionaryType<any>) => void;
    isHidden?: (btn: ActionButtonModel, data?: any) => boolean;

    constructor(init: Partial<ActionButtonModel>) {
        Object.assign(this, init);
    }

    /**
     * Checks whether the button is currently hidden or not.
     * @param currentItem Current record's data.
     */
    isCurrentlyHidden(currentItem?: Identifyable<any>): boolean {
        return !!this.conditionField
            ? !this.isConditionFieldTrue(currentItem)
            : !!this.isHidden
                ? this.isHidden(this, currentItem)
                : false;
    }

    private isConditionFieldTrue(currentItem?: Identifyable<any>): boolean {
        if (currentItem === undefined) {
            return false;
        }
        const dataAsAny = currentItem as any;
        const conditionField = this.getConditionField();
        const bracketIndex = conditionField.indexOf('[');
        if (bracketIndex !== -1 && conditionField.includes(']', bracketIndex)) {
            const propertyName = conditionField.substring(0, conditionField.indexOf('['));
            const valueString = conditionField.substring(conditionField.indexOf('[') + 1, conditionField.indexOf(']'));
            const values = valueString.split(',').map(value => Number(value.trim())); // Convert strings to numbers immediately
            if (propertyName in dataAsAny) {
                const itemValues = dataAsAny[propertyName];
                // Check if the single value is part of the array in currentItem
                return values.some(value => itemValues.includes(value));
            }
        } else {
            return !dataAsAny || (!(conditionField in dataAsAny) ? false : !!dataAsAny[conditionField]);
        }

        return false;
    }

    private getConditionField(): string {
        return !this.conditionField ? '' : this.conditionField!.charAt(0).toLowerCase() + this.conditionField.slice(1);
    }
}
