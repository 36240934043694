import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { FeatureModel } from '../../models/feature.model';
import { FeatureListModel } from '../../models/responses/feature-list.model';
import { FeatureVoteListModel } from '../../models/responses/feeature-vote.list.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { FeatureEditModel } from '../../models/requests/feature-edit.model';

@Injectable({
    providedIn: 'root'
})
export class FeaturesStore extends BaseCrudStore<FeatureModel, FeatureListModel, FeatureEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'features' }));
    }

    getVotes(id: number): Observable<ApiResponseModel<FeatureVoteListModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<FeatureVoteListModel[]>>(this.getUrl(`${id}/votes`));
    }

    deleteFeatureVote(featureVoteId: number): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makeDelete<ApiResponseModel<boolean>>(this.getUrl(`votes/${featureVoteId}`));
    }
}
