@if(!ticket() || !license()) {
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
} @else {
    <div class="flex flex-col gap-4">
        <div class="flex flex-row justify-between items-center">
            <div class="text-2xl">{{ ticket()?.title }}</div>
            <div class="text-base">{{ 'Tickets.Edit.TimeSpent' | transloco }} {{ timeSpent() }}</div>
        </div>
        <div class="flex flex-row justify-between">
            @if(!!ticket()?.assignedToUser) {
                <div>
                    <div class="text-base">{{ 'Tickets.Edit.AssignedTo' | transloco }}</div>
                    <div class="text-lg">{{ ticket()?.assignedToUser }}</div>
                </div>
            }
            <div>
                <div class="text-base">{{ 'Tickets.Edit.Priority' | transloco }}</div>
                <div class="text-lg">{{ ticket()?.priorityDescription }}</div>
            </div>

        </div>
        
        <div class="border border-on-app-light border-solid rounded-xl p-5">
            <arc-license-db-info [license]="license()!" [isDarkTheme]="true"></arc-license-db-info>
        </div>
        <div class="border border-on-app-light border-solid rounded-xl p-5">
            <arc-license-details [license]="license()!"></arc-license-details>
        </div>
        <div>
            <div class="text-base">{{ 'Tickets.Edit.Tickets' | transloco }}</div>
            <arc-ticket-list></arc-ticket-list>
        </div>
    </div>

} 