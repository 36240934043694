import { ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Directive, OnInit, ViewContainerRef, effect, inject, input } from '@angular/core';

import { BaseCardComponent } from '../../components/abstractions/base-card.component';
import { Identifyable } from '../abstractions/identifyable';
import { OptionalType } from '../models/types/optional.type';

@Directive({
    selector: '[arcCardContent]'
})
export class CardContentDirective<TList extends Identifyable<TId>, TId = number> implements OnInit {
    item = input.required<TList>();
    component = input.required<ComponentType<BaseCardComponent<TList, TId>>>();

    private readonly viewContainerRef = inject(ViewContainerRef);
    private componentRef: OptionalType<ComponentRef<BaseCardComponent<TList, TId>>>;

    constructor() {
        effect(() => {
            const item = this.item();
            if (!!item) {
                this.setItem(item);
            }
        });
    }

    ngOnInit(): void {
        this.componentRef = this.viewContainerRef.createComponent(this.component());
        this.setItem(this.item());
    }

    private setItem(item: TList): void {
        this.componentRef?.setInput('item', item);
    }
}
