import { Pipe, PipeTransform, inject } from '@angular/core';

import { ComplexDataTypesEnum } from '../models/complex-data-types.enum';
import { QuickSearchConfigModel } from '../../components/form/quick-search/models/quick-search-config.model';
import { LicenseListModel } from '../../app/models/responses/license-list.model';
import { LicensesStore } from '../../app/services/stores/licenses.store';
import { UserListModel } from '../../app/models/responses/user-list.model';
import { UsersStore } from '../../app/services/stores/users.store';
import { ResellersStore } from '../../app/services/stores/resellers.store';

@Pipe({ name: 'arcQuickSearchConfig' })
export class QuickSearchConfigPipe implements PipeTransform {
    private readonly licensesStore = inject(LicensesStore);
    private readonly usersStore = inject(UsersStore);
    private readonly resellersStore = inject(ResellersStore);
    transform(complexDataType: ComplexDataTypesEnum, shouldHideDataSelectDialogButton = false): QuickSearchConfigModel<any> {
        switch (complexDataType) {
            case ComplexDataTypesEnum.License:
                return new QuickSearchConfigModel<LicenseListModel>({
                    store: this.licensesStore,
                    displayFn: license => license?.licenseHolder ?? '',
                    shouldHideDataSelectDialogButton,
                    dataSelectionDialogConfig: { type: complexDataType }
                });
            case ComplexDataTypesEnum.User:
                return new QuickSearchConfigModel<UserListModel>({
                    store: this.usersStore,
                    displayFn: user => user?.userName ?? '',
                    shouldHideDataSelectDialogButton,
                    dataSelectionDialogConfig: { type: complexDataType }
                });
            case ComplexDataTypesEnum.Reseller:
                return new QuickSearchConfigModel<UserListModel>({
                    store: this.resellersStore,
                    displayFn: user => user?.userName ?? '',
                    shouldHideDataSelectDialogButton,
                    dataSelectionDialogConfig: { type: complexDataType }
                });
        }
    }
}
