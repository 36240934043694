import { Injectable, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Params, Route, Router, Event as RouterEvent } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';

import { routes } from '../../app/routing/app-routing.module';
import { RouteDataModel } from '../models/route-data.model';
import { OptionalType } from '../models/types/optional.type';
import { MenuItemModel } from '../models/menu-item.model';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class RouteService implements OnDestroy {
    routerEvent$: Observable<RouterEvent>;
    subMenuItemsSub: Subject<MenuItemModel[]> = new Subject();

    private readonly labelPrefix = 'Layout.Menu';
    private readonly allRoutes: Route[];
    private readonly routerEventSub = new Subject<RouterEvent>();
    private readonly routerEventSubscription: Subscription;
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);

    private readonly authService = inject(AuthService);

    constructor() {
        this.routerEvent$ = this.routerEventSub.asObservable();
        this.allRoutes = this.getAllRoutesWithDataRecursive();

        this.routerEventSubscription = this.router.events.subscribe(val => {
            this.routerEventSub.next(val);
        });
    }

    ngOnDestroy(): void {
        this.routerEventSubscription.unsubscribe();
    }

    getQueryParams(): Params {
        return this.activatedRoute.snapshot.queryParams;
    }

    getRouteDataFromUri(uri: string): OptionalType<RouteDataModel> {
        const tree = this.router.parseUrl(uri);
        const currentPath = tree.root.children[PRIMARY_OUTLET]?.segments.map(s => s.path).join('/');
        const currentData = this.allRoutes.find(r => r.path === currentPath);

        if (!!currentData) {
            return currentData.data as OptionalType<RouteDataModel>;
        }

        return this.allRoutes.find(
            r => r.data?.['withoutParamPath'] === this.getPathWithoutParam(currentPath || '', +(r.data?.['paramIdx'] || -1))
        )?.data as OptionalType<RouteDataModel>;
    }

    generateMenu(): MenuItemModel[] {
        return this.getFilteredRoutes();
    }

    generateSubMenuForUri(uri: string): void {
        const currentRouteData = this.getRouteDataFromUri(uri);
        const submenu = currentRouteData?.subMenu;

        if (!submenu) {
            return;
        }

        this.generateSubMenu(submenu).subscribe(result => this.subMenuItemsSub.next(result));
    }

    getFirstAllowedSubmenu(uri: string): MenuItemModel {
        const data = this.getRouteDataFromUri(uri);
        const subMenus = this.getFilteredRoutes(false, data?.subMenu);

        return subMenus?.[0];
    }

    private getPathWithoutParam(path: string, paramIdx: number): string {
        const paths = path.split('/');
        paths.splice(paramIdx, 1);

        return paths.join('/');
    }

    private generateSubMenu(submenuName: string): Observable<MenuItemModel[]> {
        return of(this.getFilteredRoutes(false, submenuName));
    }

    private getFilteredRoutes(isMainMenu = true, submenuName = ''): MenuItemModel[] {
        return this.allRoutes
            .filter(r => {
                const data = r.data as RouteDataModel;
                const isAllowed = !data.requiredModule;
                const hasRequiredRole = !data.requiredRole || this.authService.hasRole(data.requiredRole);
                return (
                    (isMainMenu ? data.showInMainMenu : data.subMenu === submenuName && !data.showInMainMenu) &&
                    isAllowed &&
                    !!data.title &&
                    hasRequiredRole
                );
            })
            .filter(() => true)
            .sort((a, b) => {
                const dataA = a.data as RouteDataModel;
                const dataB = b.data as RouteDataModel;

                return isMainMenu
                    ? (dataA.mainMenuIndex ?? 0) - (dataB.mainMenuIndex ?? 0)
                    : (dataA.subMenuIndex ?? 0) - (dataB.subMenuIndex ?? 0);
            })
            .map(r => {
                const data = r.data as RouteDataModel;
                return new MenuItemModel({
                    path: r.path!,
                    labelKey: `${this.labelPrefix}.${data.title}`,
                    isDisabled: data.isDisabled,
                    startingQueryParams: data.startingQueryParams
                });
            });
    }

    private getAllRoutesWithDataRecursive(childRoutes?: Route[], parent?: Route): Route[] {
        const allRoutes: Route[] = [];
        const localRoutes = childRoutes || routes;

        localRoutes.forEach(r => {
            const parentLength = parent?.path?.split('/').length || 0;
            const paramIdx = r.path?.split('/').findIndex(a => a.match(/:.*/)) || -1;

            if (!!parent && !!parent.path) {
                r.path = parent.path + '/' + r.path;
            }

            if (!!r.data) {
                if (paramIdx >= 0) {
                    const newPath = r.path?.replace(/\/?:.*\/?/, '/') || '';
                    r.data['paramIdx'] = paramIdx + parentLength;
                    r.data['withoutParamPath'] = newPath.endsWith('/') ? newPath.slice(0, -1) : newPath;
                }

                allRoutes.push(r);
            }

            if (!!r.children && r.children.length > 0) {
                allRoutes.push(...this.getAllRoutesWithDataRecursive(r.children, r));
            }
        });

        return allRoutes;
    }
}
