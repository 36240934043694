import { OptionalType } from '../models/types/optional.type';
import { StorageTypeEnum } from '../models/enums/storage-type.enum';

/**
 * Storage provider.
 */
export abstract class BaseStorage {
    protected _separator = '.';
    protected _storage?: Storage;

    private readonly storageType: string;
    private prefix = 'arcavisportal';
    private table: string;
    private isSupported = false;

    protected constructor(table = '', type = StorageTypeEnum.Local) {
        this.table = table;
        this.storageType = type;

        this.setup();
    }

    /**
     * Checks whether the browser has support for the storage type.
     */
    isBrowserSupported(): boolean {
        return this.isSupported;
    }

    /**
     * Saves a record using the provided key.
     * @param data Data to be saved.
     * @param key Key in local storage.
     */
    save<T>(data: T, key: string): void {
        if (!this.isSupported) {
            return;
        }

        if (this._storage) {
            const savedData: string = !data ? '' : JSON.stringify(data);
            this._storage.setItem(this.getKey(key), savedData);
        }
    }

    /**
     * Retrieves a record using the provided key.
     * @param key Key in local storage.
     */
    get<T>(key: string): OptionalType<T> {
        if (!this.isSupported) {
            return undefined;
        }

        const item = this._storage ? this._storage.getItem(this.getKey(key)) : undefined;

        if (!item || item === 'null') {
            return undefined;
        }

        try {
            return JSON.parse(item) as T;
        } catch (e) {
            return undefined;
        }
    }

    /**
     * Removes the selected item(s).
     * @param keys Keys of the items to be removed.
     */
    remove(...keys: string[]): void {
        if (!this.isSupported) {
            return;
        }

        keys.forEach((key: string) => {
            try {
                this._storage!.removeItem(this.getKey(key));
            } catch (e) {}
        });
    }

    /**
     * Erases all data from local table.
     */
    clear(): void {
        const existingKeys = this.getKeys();

        for (const key of Object.keys(this._storage!)) {
            const currentKey = existingKeys.find(k => k === key);

            if (currentKey) {
                this.remove(currentKey);
            }
        }
    }

    /**
     * Retrieves all keys from local database.
     */
    getKeys(): Array<string> {
        if (!this.isSupported) {
            return [];
        }

        const prefix = this.getPrefix();
        const prefixLength = prefix.length;
        const keys: Array<string> = [];

        for (const key of Object.keys(this._storage!)) {
            if (key.substr(0, prefixLength) === prefix) {
                keys.push(key.substr(prefixLength));
            }
        }

        return keys;
    }

    private getKey(key: string): string {
        return `${this.getPrefix()}${key}`;
    }

    private getPrefix(): string {
        return `${this.prefix}${this._separator}${this.table.length > 0 ? this.table + this._separator : ''}`;
    }

    /**
     * Sets up the environment.
     */
    private setup(): void {
        try {
            // @ts-ignore
            this.isSupported = !!(this.storageType in window && window[this.storageType]);

            const previousTable = this.table;
            this.table = 'tst';

            if (this.isSupported) {
                // @ts-ignore
                this._storage = window[this.storageType];

                // When Safari (OSX or iOS) is in private mode, localStorage seems to be available, but when
                // you try to run .setItem an exception will be generated:
                // ("QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made to add something to storage that exceeded the quota.")
                // https://bugs.webkit.org/show_bug.cgi?id=157010
                // Fixed from Safari 11
                const key = this.getKey(`__${Math.round(Math.random() * 1e7)}`);

                this._storage!.setItem(key, '');
                this._storage!.removeItem(key);
            }

            this.table = previousTable;
        } catch (e) {
            // TODO: we may like to warn the users they can't run on private mode while on Safari
            this.isSupported = false;
        }
    }
}
