import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, first, map } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { HelpArticleEditModel } from '../../../../../models/requests/help-article-edit.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { HelpArticlesStore } from '../../../../../services/stores/help-articles.store';
import { KeyValueModel } from '../../../../../../core/models/key-value.model';
import { GeneralDataService } from '../../../../../../core/services/general-data.service';
import { HelpTagModel } from '../../../../../models/responses/help-tag.model';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { BlogArticleModel } from '../../../../../models/responses/blog-article.model';
import { BlogArticleEditModel } from '../../../../../models/requests/blog-article-edit.model';

@Component({
    selector: 'arc-blog-edit',
    templateUrl: './blog-edit.component.html',
    styleUrl: './blog-edit.component.scss'
})
export class BlogEditComponent extends BaseEditSidebarItemComponent<BlogArticleModel, BlogArticleEditModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    language = new FormControl<'en' | 'fr' | 'de' | 'it'>('de');
    helpTopics: SelectOptionModel<string>[] = [];
    helpSubTopics: SelectOptionModel<string>[] = [];

    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        text_de: new ArcFormControl('', Validators.required),
        summary_de: new ArcFormControl('', Validators.required),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        text_en: new ArcFormControl<OptionalType<string>>(undefined),
        summary_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        text_fr: new ArcFormControl<OptionalType<string>>(undefined),
        summary_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        text_it: new ArcFormControl<OptionalType<string>>(undefined),
        summary_it: new ArcFormControl<OptionalType<string>>(undefined),
        helpTopicId: new ArcFormControl<OptionalType<number>>(undefined, Validators.required),
        helpSubTopicId: new ArcFormControl<OptionalType<number>>(undefined, Validators.required),
        isPublic: new ArcFormControl(false)
    });

    helpTagsControl = new ArcFormControl<KeyValueModel[]>([]);

    helpTags: KeyValueModel<string, string>[] = [];

    get title(): FormControl {
        switch (this.language.value) {
            case 'en':
                return this.formGroup.controls.title_en;
            case 'fr':
                return this.formGroup.controls.title_fr;
            case 'de':
                return this.formGroup.controls.title_de;
            case 'it':
                return this.formGroup.controls.title_it;
            default:
                return this.formGroup.controls.title_de;
        }
    }

    get text(): FormControl {
        switch (this.language.value) {
            case 'en':
                return this.formGroup.controls.text_en;
            case 'fr':
                return this.formGroup.controls.text_fr;
            case 'de':
                return this.formGroup.controls.text_de;
            case 'it':
                return this.formGroup.controls.text_it;
            default:
                return this.formGroup.controls.text_de;
        }
    }

    get summary(): FormControl {
        switch (this.language.value) {
            case 'en':
                return this.formGroup.controls.summary_en;
            case 'fr':
                return this.formGroup.controls.summary_fr;
            case 'de':
                return this.formGroup.controls.summary_de;
            case 'it':
                return this.formGroup.controls.summary_it;
            default:
                return this.formGroup.controls.summary_de;
        }
    }
    readonly translationService = inject(TranslationService);

    languageItems = [
        { value: 'de', label: this.translationService.getText('General.LanguagesShort.De') },
        { value: 'en', label: this.translationService.getText('General.LanguagesShort.En') },
        { value: 'fr', label: this.translationService.getText('General.LanguagesShort.Fr') },
        { value: 'it', label: this.translationService.getText('General.LanguagesShort.It') }
    ];

    private readonly generalDataService = inject(GeneralDataService);
    private readonly helpArticlesStore = inject(HelpArticlesStore);

    constructor() {
        super();
        this.helpArticlesStore.getHelpTopics().subscribe(result => {
            this.helpTopics = result.value?.map(item => ({ label: item.key ?? '', value: item.value ?? '' })) ?? [];
        });
    }

    onItemSet(): void {
        this.generalDataService.getGeneralData(GeneralDataTypeEnum.HelpTags).subscribe(r => {
            this.helpTags = (r ?? []).filter(tag => this.item().helpTags?.some(selectedTag => selectedTag.id === Number(tag.key))) ?? [];
        });
        const tags = this.item().helpTags?.map(t => new KeyValueModel({ key: t.id.toString(), value: t.title })) ?? [];
        this.formGroup.patchValue(this.item());
        this.helpTagsControl.patchValue(tags);
        this.handleHelpTopicChange();
    }

    override prepareSaveModel(): Partial<HelpArticleEditModel> {
        const value = this.formGroup.getRawValue();

        const tagListModel: HelpTagModel[] = [];
        this.helpTagsControl.value.forEach(t => {
            tagListModel.push(new HelpTagModel({ id: Number(t.key), title: t.value ?? '' }));
        });
        // clear cache in case a new Tag has been added, otherwise it wont get displayed
        this.generalDataService.clearCache(GeneralDataTypeEnum.HelpTags);

        return {
            title_de: value.title_de,
            text_de: value.text_de,
            summary_de: value.summary_de,
            title_en: value.title_en,
            text_en: value.text_en,
            summary_en: value.summary_en,
            title_fr: value.title_fr,
            text_fr: value.text_fr,
            summary_fr: value.summary_fr,
            title_it: value.title_it,
            text_it: value.text_it,
            summary_it: value.summary_it,
            helpTopicId: value.helpTopicId,
            helpSubTopicId: value.helpSubTopicId,
            isPublic: value.isPublic,
            helpTags: tagListModel
        };
    }

    translate(): void {
        const controlsToTranslate = ['title', 'text', 'summary'];
        this.translationService.translateFormControls(this.formGroup, controlsToTranslate).pipe(first()).subscribe();
    }

    handleHelpTopicChange(): void {
        const topicId = this.formGroup.controls.helpTopicId.value;
        if (!topicId) {
            return;
        }
        this.helpArticlesStore.getHelpSubTopics(topicId).subscribe(result => {
            this.helpSubTopics = result.value?.map(item => ({ label: item.key ?? '', value: item.value ?? '' })) ?? [];
        });
    }

    dropdownOptionDisplay(option: KeyValueModel): string {
        return option.value ?? '';
    }

    dropdownValueDisplay(option: OptionalType<KeyValueModel>): string {
        return option?.value ?? '';
    }

    tagsSearch(query: string): Observable<KeyValueModel[]> {
        return this.generalDataService
            .getGeneralData(GeneralDataTypeEnum.HelpTags)
            .pipe(
                map(response =>
                    query ? response?.filter(f => f.value?.toLowerCase().includes(query.toLowerCase())) ?? [] : response ?? []
                )
            );
    }
}
