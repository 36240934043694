import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ModuleBundleEditModel } from '../../../../../models/requests/module-bundle-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { Utils } from '../../../../../../core/utils/tools/utils.tools';
import { ModuleBundleModel } from '../../../../../models/module-bundle.model';

@Component({
    selector: 'arc-module-bundle-image',
    templateUrl: './module-bundle-image.component.html',
    styleUrl: './module-bundle-image.component.scss'
})
export class ModuleBundleImageComponent extends BaseEditSidebarItemComponent<ModuleBundleModel, ModuleBundleEditModel> implements OnInit {
    override formGroup = new FormGroup({
        imageBlobData: new ArcFormControl<OptionalType<number[]>>(undefined),
        imageBlobFileName: new ArcFormControl<OptionalType<string>>(undefined)
    });
    fileControl = new ArcFormControl<OptionalType<File>>(undefined);
    image = '';

    ngOnInit(): void {
        this.fileControl.valueChanges.subscribe(async (file?: File) => {
            this.formGroup.controls.imageBlobData.setValue(!!file ? Array.from(new Uint8Array(await file.arrayBuffer())) : undefined);
            this.formGroup.controls.imageBlobFileName.setValue(!!file ? file.name : '');
            this.setFileImage(file);
        });
    }

    onItemSet(): void {
        const item = this.item();
        this.formGroup.patchValue(item);
        if (item.imageBlobData && item.imageBlobFileName) {
            const file = new File([new Uint8Array(item.imageBlobData)], item.imageBlobFileName);
            this.fileControl.setValue(file);
            this.setFileImage(file);
        }
    }

    override prepareSaveModel(): Partial<ModuleBundleEditModel> {
        const value = this.formGroup.getRawValue();
        return {
            imageBlobData: value.imageBlobData,
            imageBlobFileName: value.imageBlobFileName
        };
    }

    setFileImage(file?: File): void {
        if (!file) {
            return;
        }

        Utils.blobToBase64(file).subscribe(resp => {
            this.image = 'data:image/jpg;base64,' + resp;
        });
    }
}
