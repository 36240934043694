import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { ModuleBundleListModel } from '../../../models/responses/module-bundle-list.model';
import { ModuleBundleModel } from '../../../models/module-bundle.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { ModuleBundlesStore } from '../../../services/stores/module-bundles.store';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { ModuleBundleBaseDataComponent } from './edit-items/module-bundle-base-data/module-bundle-base-data.component';
import { ModuleBundleDiscountsComponent } from './edit-items/module-bundle-discounts/module-bundle-discounts.component';
import { ModuleBundleImageComponent } from './edit-items/module-bundle-image/module-bundle-image.component';
import { ModuleBundleEditModel } from '../../../models/requests/module-bundle-edit.model';

@Component({
    selector: 'arc-module-bundles',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './module-bundles.component.scss'
})
export class ModuleBundlesComponent extends PageListViewComponent<ModuleBundleListModel, ModuleBundleModel, ModuleBundleEditModel> {
    private readonly moduleBundleStore = inject(ModuleBundlesStore);
    constructor() {
        super();
        this.config = new TableListViewConfigModel<ModuleBundleListModel, ModuleBundleModel, ModuleBundleEditModel>({
            entityName: 'ModuleBundle',
            availableColumns: {
                title: new StringColumnModel({
                    columnTitleKey: 'ModuleBundle.List.Title',
                    propertyName: 'title',
                    widthPixels: 400
                }),
                product: new StringColumnModel({
                    columnTitleKey: 'ModuleBundle.List.Product',
                    propertyName: 'productTitle',
                    widthPixels: 250
                }),
                monthlyPrice: new CurrencyColumnModel({
                    propertyName: 'monthlyPrice',
                    columnTitleKey: 'ModuleBundle.List.MonthlyPrice',
                    currencyIsoCode: item => item.currencyIsoCode,
                    widthPixels: 150
                }),
                price: new CurrencyColumnModel({
                    propertyName: 'price',
                    columnTitleKey: 'ModuleBundle.List.Price',
                    currencyIsoCode: item => item.currencyIsoCode,
                    widthPixels: 150
                })
            },
            defaultColumnOrder: ['title', 'product', 'monthlyPrice', 'price'],
            defaultSort: new ColumnSortModel({
                column: 'title',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'ModuleBundle.Edit.BaseData.Name',
                        component: ModuleBundleBaseDataComponent
                    },
                    {
                        titleKey: 'ModuleBundle.Edit.Discounts.Name',
                        component: ModuleBundleDiscountsComponent
                    },
                    {
                        titleKey: 'ModuleBundle.Edit.Image.Name',
                        component: ModuleBundleImageComponent
                    }
                ]
            },
            store: this.moduleBundleStore
        });
    }
}
