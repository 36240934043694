<ng-container *ngFor="let btn of visibleButtons; let i = index">
    <button mat-icon-button [attr.color]="btn.color || 'secondary'" [color]="btn.color || 'secondary'"
        [disabled]="!btn.isEnabled || btn.isLoading"
        [matTooltip]="btn.isTextTranslated ? btn.text : (btn.text | transloco)" matTooltipPosition="above"
        (click)="onClick($event, btn, data)">
        <i *ngIf="!btn.isLoading" class="material-icons">{{ btn.icon }}</i>
        <mat-spinner *ngIf="btn.isLoading" diameter="22"></mat-spinner>
    </button>
    <ng-container *ngIf="menuButtons.length > 0">
        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
            <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <ng-container *ngFor="let btn of menuButtons">
                <button *ngIf="!_actionButtonsService.isHidden(btn.key, data)" mat-menu-item
                    [disabled]="!btn.isEnabled || btn.isLoading" (click)="onClick($event, btn, data)">
                    <div class="flex items-center">
                        <mat-icon *ngIf="!btn.isLoading">{{ btn.icon }}</mat-icon>
                        <mat-spinner *ngIf="btn.isLoading" class="mr-5" diameter="22"></mat-spinner>
                        <span>{{ btn.isTextTranslated ? btn.text : (btn.text | transloco) }}</span>
                    </div>
                </button>
            </ng-container>
        </mat-menu>
    </ng-container>
</ng-container>