import { Component, viewChild, input, computed, effect, untracked, SimpleChanges, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { StaticTableConfigModel } from './models/static-table-config.model';
import { SortDirectionEnum } from '../../../core/models/enums/sort-direction.enum';
import { ColumnSortModel } from '../../../core/models/column-sort.model';
import { BaseSelectableTable } from '../../../core/abstractions/base-selectable-table';
import { TableSelectionModel } from '../../../core/models/table-selection.model';

@Component({
    selector: 'arc-static-table',
    templateUrl: './static-table.component.html',
    styleUrls: ['./static-table.component.scss']
})
export class StaticTableComponent<T> extends BaseSelectableTable<T> implements OnChanges {
    readonly paginator = viewChild.required(MatPaginator);

    readonly config = input.required<StaticTableConfigModel>();
    readonly staticData = input.required<T[]>();
    readonly shouldUseCompactStyle = input(false);
    /**
     * Whether the row has no stack columns (only one line).
     */
    readonly isSingleLineRow = input(false);

    SortDirectionEnum = SortDirectionEnum;

    readonly columnGap = 4;
    dataSource = new MatTableDataSource<T>([]);
    totalRecords = computed(() => this.staticData().length);
    currentSorting?: ColumnSortModel;
    displayedColumns: string[] = [];
    selectionModel = new TableSelectionModel<T>();

    constructor() {
        super();

        // set paginator
        effect(() => {
            const paginator = this.paginator();
            untracked(() => {
                paginator.pageSize = this.config().defaultPageSize;
                this.dataSource.paginator = paginator;
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['config']) {
            const config = changes['config'].currentValue;
            this.setupDisplayedColumns(config);
            if (!!this.dataSource.paginator && this.dataSource.paginator.pageSize !== config.defaultPageSize) {
                this.dataSource.paginator.pageSize = config.defaultPageSize;
                this.dataSource.paginator.firstPage();
            }
        }

        if (changes['staticData']) {
            const data = changes['staticData'].currentValue;
            this.dataSource.data = data;
            this.data.set(data);
            this.clearSelection();
            if (this.config().shouldStartSelected) {
                this.selectAll();
            }
        }
    }

    private setupDisplayedColumns(config: StaticTableConfigModel): void {
        const extraColumns = [];

        if (config.showCheckboxes) {
            extraColumns.push('checkboxes');
        }

        this.displayedColumns = [...extraColumns, ...config.availableColumns.map(c => c.identifier)];
    }
}
