import { Component, inject, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { LicenseHardwareOrderModel } from '../../../../../models/license-hardware-orders.model';
import { LicenseHardwareOrderEditModel } from '../../../../../models/requests/license-hardware-order-edit.model';
import { HardwareOrderStatesEnum } from '../../../../../models/enums/hardware-order-states.enum';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { GeneralDataStore } from '../../../../../services/stores/general-data.store';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';

@Component({
    selector: 'arc-license-hardware-order-base-data',
    templateUrl: './license-hardware-order-base-data.component.html',
    styleUrl: './license-hardware-order-base-data.component.scss'
})
// eslint-disable-next-line max-len
export class LicenseHardwareOrderBaseDataComponent extends BaseEditSidebarItemComponent<LicenseHardwareOrderModel, LicenseHardwareOrderEditModel> {
    override formGroup = new FormGroup({
        quantity: new ArcFormControl<OptionalType<number>>(undefined, [Validators.required, Validators.min(0)]),
        price: new ArcFormControl<OptionalType<number>>(undefined, [Validators.required, Validators.min(0)]),
        orderedDate: new ArcFormControl<OptionalType<Date>>(undefined, [Validators.required]),
        sentDate: new ArcFormControl<OptionalType<Date>>(undefined),
        sentUserId: new ArcFormControl<OptionalType<number>>(undefined),
        licenseId: new ArcFormControl<OptionalType<number>>(undefined, [Validators.required]),
        hardwareBundleId: new ArcFormControl<OptionalType<number>>(undefined, [Validators.required]),
        serialNumber: new ArcFormControl<OptionalType<string>>(undefined),
        trackingNumber: new ArcFormControl<OptionalType<string>>(undefined),
        orderState: new ArcFormControl(HardwareOrderStatesEnum.Created, [Validators.required]),
        street: new ArcFormControl<OptionalType<string>>(undefined, [Validators.required]),
        city: new ArcFormControl<OptionalType<string>>(undefined, [Validators.required]),
        streetNumber: new ArcFormControl<OptionalType<string>>(undefined),
        zip: new ArcFormControl<OptionalType<string>>(undefined, [Validators.required]),
        countryIsoCode: new ArcFormControl<OptionalType<string>>(undefined, [Validators.required]),
        deliveryAddressName: new ArcFormControl<OptionalType<string>>(undefined, [Validators.required])
    });

    ComplexDataTypesEnum = ComplexDataTypesEnum;

    licenseHardwareBundles = signal<SelectOptionModel<string>[]>([]);

    hardwareOrderOptions = Object.keys(HardwareOrderStatesEnum)
        .filter(key => isNaN(Number(key)))
        .map(key => ({
            value: HardwareOrderStatesEnum[key as keyof typeof HardwareOrderStatesEnum],
            label: key
        }));

    private readonly generalDataStore = inject(GeneralDataStore);

    constructor() {
        super();

        const licenseIdChangesSub = this.formGroup.controls.licenseId.valueChanges.subscribe(licenseId => {
            if (!licenseId) {
                return;
            }
            this.handleLicenseUpdate(licenseId);
        });
        this.addSubscriptions(licenseIdChangesSub);
    }
    onItemSet(): void {
        const item = this.item();
        this.formGroup.patchValue(item);
        if (!!item.licenseId) {
            this.handleLicenseUpdate(item.licenseId);
        }
    }

    override prepareSaveModel(): Partial<LicenseHardwareOrderEditModel> {
        const value = this.formGroup.getRawValue();
        return {
            quantity: value.quantity,
            price: value.price,
            orderedDate: value.orderedDate,
            sentDate: value.sentDate,
            sentUserId: value.sentUserId,
            licenseId: value.licenseId,
            hardwareBundleId: value.hardwareBundleId,
            serialNumber: value.serialNumber,
            trackingNumber: value.trackingNumber,
            orderState: value.orderState,
            street: value.street,
            city: value.city,
            streetNumber: value.streetNumber,
            zip: value.zip,
            countryIsoCode: value.countryIsoCode,
            deliveryAddressName: value.deliveryAddressName
        };
    }

    handleLicenseUpdate(licenseId: number): void {
        this.generalDataStore
            .getLicenseHardwareBundles(licenseId)
            .subscribe(result => {
                this.licenseHardwareBundles.set(result.value?.map(x => ({ label: x.value!, value: x.key! })) ?? []);
            });
    }
}
