import { MatDialog } from '@angular/material/dialog';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import { LicenseInteractionModel } from '../../../../../models/license-interaction.model';
import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { LicenseInteractionTypesEnum } from '../../../../../models/enums/license-interaction-types.enum';
import { CustomerInteractionDialogComponent } from '../../../../../../components/dialogs/customer-interaction-dialog/customer-interaction-dialog.component';

@Component({
    selector: 'arc-license-interaction-action',
    templateUrl: './license-interaction-action.component.html',
    styleUrl: './license-interaction-action.component.scss'
})
export class LicenseInteractionTypeComponent extends BaseCustomColumnComponent<LicenseInteractionModel> {
    private readonly router = inject(Router);
    private readonly matDialog = inject(MatDialog);
    handleActionClicked(): void {
        if (this.item.licenseInteractionType === LicenseInteractionTypesEnum.Chat) {
            this.router.navigate(['support', 'tickets'], { queryParams: { Id: [this.item.id] } }).then();
        } else {
            this.matDialog.open(CustomerInteractionDialogComponent, {
                width: '600px',
                maxHeight: '98svh',
                maxWidth: '98vw',
                data: this.item
            });
        }
    }
}
