import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';

import { TranslationService } from '../services/translation.service';

@Injectable()
export class MatPaginatorIntlTranslated implements MatPaginatorIntl {
    firstPageLabel = '';
    itemsPerPageLabel = '';
    lastPageLabel = '';
    nextPageLabel = '';
    previousPageLabel = '';

    changes = new Subject<void>();

    private isInitialized = false;
    private translationService?: TranslationService;

    async initialize(translationService: TranslationService): Promise<void> {
        this.translationService = translationService;
        this.firstPageLabel = await this.translationService.getTextAsync('Components.Paginator.FirstPage');
        this.itemsPerPageLabel = (await this.translationService.getTextAsync('Components.Paginator.ItemsPerPage')) + ':';
        this.lastPageLabel = await this.translationService.getTextAsync('Components.Paginator.LastPage');
        this.nextPageLabel = await this.translationService.getTextAsync('Components.Paginator.NextPage');
        this.previousPageLabel = await this.translationService.getTextAsync('Components.Paginator.PreviousPage');

        this.isInitialized = true;
        this.changes.next();
    }

    getRangeLabel(page: number, pageSize: number, length: number): string {
        if (!this.translationService || !this.isInitialized) {
            return '';
        }

        if (length === 0) {
            return this.translationService.getText('Components.Paginator.PageXofY', { page: 1, amountPages: 1 });
        }
        const amountPages = Math.ceil(length / pageSize);
        return this.translationService.getText('Components.Paginator.PageXofY', { page: page + 1, amountPages });
    }
}
