export enum DynamicFormControlTypeEnum {
    // Standard HTML inputs
    Text = 0,
    Number = 1,
    Decimal = 2,
    Checkbox = 3,
    Label = 4,

    // Standard html select
    Select = 20,

    // Angular-Material controls
    ButtonToggle = 30,
    Date = 31,
    DateRange = 32,
    DateTime = 33,

    // Arcavis controls
    GeneralData = 50,
    Article = 51,
    ArticleGroup = 52,
    Customers = 53,
    Suppliers = 54,

    // Editors
    Markdown = 60
}
