import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TransactionListModel } from '../../../models/responses/transaction-list.model';
import { TransactionModel } from '../../../models/transaction.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { NumberColumnModel } from '../../../../components/dynamic-table/models/column-types/number-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { TransactionsStore } from '../../../services/stores/transactions.store';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { TransactionTypeComponent } from './components/transaction-type/transaction-type.component';
import { TransactionBaseDataComponent } from './edit-items/transaction-base-data/transaction-base-data.component';
import { TransactionEditModel } from '../../../models/requests/transaction-edit.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';

@Component({
    selector: 'arc-transactions',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './transactions.component.scss'
})
export class TransactionsComponent extends PageListViewComponent<TransactionListModel, TransactionModel, TransactionEditModel> {
    private readonly transactionsStore = inject(TransactionsStore);
    constructor() {
        super();
        this.config = new TableListViewConfigModel<TransactionListModel, TransactionModel, TransactionEditModel>({
            entityName: 'Transactions',
            availableColumns: {
                licenseHolder: new StackedColumnModel({
                    columnTitleKey: 'LicenseModuleBundles.List.LicenseHolder',
                    propertyName: 'licenseHolder',
                    propertyName2: 'id',
                    widthPixels: 300
                }),
                valueDate: new DateColumnModel({
                    columnTitleKey: 'Transactions.List.ValueDate',
                    propertyName: 'valueDate',
                    widthPixels: 120
                }),
                type: new CustomColumnModel({
                    columnTitleKey: 'Transactions.List.Type',
                    customComponent: TransactionTypeComponent,
                    widthPixels: 120
                }),
                amount: new CurrencyColumnModel({
                    columnTitleKey: 'Transactions.List.Amount',
                    propertyName: 'amount',
                    currencyIsoCode: item => item.currencyIsoCode,
                    widthPixels: 100
                }),
                publicRemark: new StringColumnModel({
                    columnTitleKey: 'Transactions.List.PublicRemark',
                    propertyName: 'publicRemark',
                    widthPixels: 300
                }),
                poolTime: new NumberColumnModel({
                    columnTitleKey: 'Transactions.List.PoolTime',
                    propertyName: 'poolTime',
                    widthPixels: 80
                })
            },
            defaultColumnOrder: ['licenseHolder', 'valueDate', 'type', 'amount', 'publicRemark', 'poolTime'],
            defaultSort: new ColumnSortModel({
                column: 'id',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            editSidebarConfig: {
                editComponents: [{ titleKey: 'Transactions.Edit.BaseData.Name', component: TransactionBaseDataComponent }]
            },
            store: this.transactionsStore
        });
    }
}
