import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';

import { AuthService } from '../../../../core/services/auth.service';
import { EnvironmentService } from '../../../../core/services/environment.service';
import { ArcFormControl } from '../../../../core/utils/arc-form-control';

@Component({
    selector: 'arc-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    formGroup = new FormGroup({
        email: new ArcFormControl('', Validators.required),
        password: new ArcFormControl('', Validators.required)
    });
    isCheckingAuth = true;
    showPasswordClearText = false;

    subdomain = '';

    private readonly router = inject(Router);
    private readonly authService = inject(AuthService);
    private readonly environmentService = inject(EnvironmentService);

    ngOnInit(): void {
        this.subdomain = this.environmentService.getSubdomain();
        this.authService
            .isLoggedIn()
            .pipe(finalize(() => (this.isCheckingAuth = false)))
            .subscribe(isLoggedIn => {
                this.isCheckingAuth = false;

                if (isLoggedIn) {
                    this.authService.redirectFromLogin();
                }
            });
    }

    login(): void {
        this.formGroup.markAllAsTouched();

        if (this.formGroup.invalid) {
            return;
        }

        this.isCheckingAuth = true;

        this.authService
            .login({
                email: this.formGroup.getRawValue().email,
                password: this.formGroup.getRawValue().password
            })
            .pipe(finalize(() => (this.isCheckingAuth = false)))
            .subscribe();
    }

    redirectToPasswordRecover(): void {
        this.router.navigate(['request-password-reset']);
    }

    redirectToRegister(): void {
        this.router.navigate(['register']);
    }
}
