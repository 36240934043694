import { Component, computed, input, signal } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';
import { SelectionSliderOptionModel } from './models/selection-slider-option.model';

@Component({
    selector: 'arc-selection-slider',
    templateUrl: './selection-slider.component.html',
    styleUrl: './selection-slider.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SelectionSliderComponent
        }
    ]
})
export class SelectionSliderComponent<T> extends BaseControlValueAccessor<T> {
    readonly options = input.required<SelectionSliderOptionModel<T>[]>();
    readonly sliderClasses = input<string>('green');

    readonly optionCount = computed(() => this.options().length);

    readonly currentIndex = signal<number>(0);

    indexChanged(newIndex: number): void {
        this.currentIndex.set(newIndex);
        const newValue = this.options()[newIndex].value;
        this.valueChanged(newValue);
    }

    override writeValue(value?: T | undefined): void {
        if (!value) {
            // undefined not possible for this control
            this.indexChanged(0);
            return;
        }

        const index = this.options().findIndex(option => option.value === value);
        this.currentIndex.set(index);
    }
}
