import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { FeatureEditModel } from '../../../../../models/requests/feature-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { ProductAreasEnum } from '../../../../../models/enums/product-areas.enum';
import { FeatureTypeEnum } from '../../../../../models/enums/feature-type.enum';
import { FeatureStatusEnum } from '../../../../../models/enums/feature-status.enum';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { FeatureModel } from '../../../../../models/feature.model';

@Component({
    selector: 'arc-features-edit',
    templateUrl: './features-edit.component.html',
    styleUrl: './features-edit.component.scss'
})
export class FeaturesEditComponent extends BaseEditSidebarItemComponent<FeatureModel, FeatureEditModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    language = new FormControl<'en' | 'fr' | 'de' | 'it'>('de');

    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        description_de: new ArcFormControl('', Validators.required),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        description_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        description_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        description_it: new ArcFormControl<OptionalType<string>>(undefined),
        area: new ArcFormControl(ProductAreasEnum.Articles, Validators.required),
        type: new ArcFormControl(FeatureTypeEnum.Bug, Validators.required),
        status: new ArcFormControl(FeatureStatusEnum.Idea, Validators.required),
        isFeatured: new ArcFormControl(false, Validators.required),
        expectedDeliveryTime: new ArcFormControl<OptionalType<Date>>(undefined)
    });

    get title(): FormControl {
        switch (this.language.value) {
            case 'en':
                return this.formGroup.controls.title_en;
            case 'fr':
                return this.formGroup.controls.title_fr;
            case 'de':
                return this.formGroup.controls.title_de;
            case 'it':
                return this.formGroup.controls.title_it;
            default:
                return this.formGroup.controls.title_de;
        }
    }

    get description(): FormControl {
        switch (this.language.value) {
            case 'en':
                return this.formGroup.controls.description_en;
            case 'fr':
                return this.formGroup.controls.description_fr;
            case 'de':
                return this.formGroup.controls.description_de;
            case 'it':
                return this.formGroup.controls.description_it;
            default:
                return this.formGroup.controls.description_de;
        }
    }

    readonly translationService = inject(TranslationService);

    languageItems = [
        { value: 'de', label: this.translationService.getText('General.LanguagesShort.De') },
        { value: 'en', label: this.translationService.getText('General.LanguagesShort.En') },
        { value: 'fr', label: this.translationService.getText('General.LanguagesShort.Fr') },
        { value: 'it', label: this.translationService.getText('General.LanguagesShort.It') }
    ];

    onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }


    override prepareSaveModel(): Partial<FeatureEditModel> {
        const value = this.formGroup.getRawValue();
        return {
            title_de: value.title_de,
            description_de: value.description_de,
            title_en: value.title_en,
            description_en: value.description_en,
            title_fr: value.title_fr,
            description_fr: value.description_fr,
            title_it: value.title_it,
            description_it: value.description_it,
            area: value.area,
            type: value.type,
            status: value.status,
            isFeatured: value.isFeatured,
            expectedDeliveryTime: value.expectedDeliveryTime
        };
    }

    translate(): void {
        const controlsToTranslate = ['title', 'description'];
        this.translationService.translateFormControls(this.formGroup, controlsToTranslate).pipe(first()).subscribe();
    }
}
