export enum ColumnTypeEnum {
    Text,
    Stacked,
    Icon,
    Checkbox,
    Number,
    Currency,
    ColoredStack,
    Custom,
    Button,
    ButtonToggle,
    GeneralData,
    QuickSearch,
    Date,
    GenericStatus,
    TranslationText
}
