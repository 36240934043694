<h1 class="font-semibold text-lg">{{ 'License.Details.AssetsWidget.ApiIntegrations' | transloco }}</h1>
<table class="min-w-full mt-2">
    <thead>
        <tr class="text-left font-normal text-sm">
            <th> {{'License.Details.AssetsWidget.Title' | transloco}}</th>
            <th> {{'License.Details.AssetsWidget.Requests' | transloco}}</th>
            <th> {{'License.Details.AssetsWidget.Active' | transloco}}</th>
            <th> {{'License.Details.AssetsWidget.LastReset' | transloco}}</th>
        </tr>
    </thead>
    <tbody>
        @for (apiIntegration of apiIntegrations(); track $index) {
            <tr class="hover:cursor-pointer" (click)="handleApiIntegrationClick(apiIntegration.apiIntegrationId)">
                <td>{{ apiIntegration.title }}</td>
                <td>{{ apiIntegration.totalRequests }}</td>
                <td>
                    @if(apiIntegration.isActive) {
                        <mat-icon>check</mat-icon>
                    }
                </td>
                <td>{{ apiIntegration.lastRequestReset | arcDate }}</td>
            </tr>
        }
    </tbody>
</table>
<h1 class="font-semibold text-lg mt-2">{{ 'License.Details.AssetsWidget.HelpArticles' | transloco}} </h1>
<div class="flex flex-col gap-2">
    @for (helpArticle of helpArticles(); track helpArticle.id) {
        <a href='/support/help?Id={{helpArticle.id}}'> {{ helpArticle.title}}</a>
    }
</div>