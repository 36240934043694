<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="internalControl" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    @if(!!label){
        <mat-label>{{ isLabelTranslated ? label : (label | transloco) }}</mat-label>
    }
    <input matInput [formControl]="internalControl" (blur)="onBlur()" type="text"
        [placeholder]="isPlaceholderTranslated ? placeholder : (placeholder | transloco)"
        [matAutocomplete]="autocomplete" (input)="input()" />
    <mat-autocomplete autoActiveFirstOption #autocomplete="matAutocomplete" [displayWith]="config.displayFn.bind(this)"
        (optionSelected)="onOptionSelected($event)">
        @for (option of options; track option) {
            <mat-option [value]="option">
                {{ config.displayFn(option) }}
            </mat-option>
        }
    </mat-autocomplete>
    <div matSuffix class="flex gap-2">
        @if(!config.shouldHideDataSelectDialogButton && !!config.dataSelectionDialogConfig && !isLoading && !isDisabled){
            <button type="button" mat-icon-button (click)="openDataSelectionDialog($event)">
                <mat-icon>search</mat-icon>
            </button>
        }
        @if(isLoading){
            <div class="w-[40px] h-[40px] grid place-items-center">
                <mat-spinner diameter="22"></mat-spinner>
            </div>
        }
    </div>
</mat-form-field>