import { Component, Input } from '@angular/core';

import { BaseControlComponent } from '../base-control/base-control.component';
import { SelectOptionModel } from '../../../core/models/select-option.model';

@Component({
    selector: 'arc-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent<T> extends BaseControlComponent {
    @Input() options!: SelectOptionModel<T>[];
    @Input() isMultiselect = false;

    /**
     * Custom comparator which tries to cast the option value to a number so that it can be compared to the value
     * when it is a number. This is necessary, because when the values in the options and the value in the form
     * are not the  same type the comparison fails. It also handles empty string as undefined.
     */
    customComparator(optionValue: any, formValue: any): boolean {
        const isNumber = !isNaN(Number(optionValue));
        const optionValueCasted = isNumber ? Number(optionValue) : optionValue;
        const formValueCasted = isNumber ? Number(formValue) : formValue;

        return optionValueCasted === formValueCasted || (optionValueCasted === '' && formValueCasted === undefined);
    }
}
