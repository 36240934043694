import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { HardwareBundlesStore } from '../../../services/stores/hardware-bundles.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { HardwareBundleListModel } from '../../../models/responses/hardware-bundle-list.model';
import { HardwareBundleModel } from '../../../models/hardware-bundle.model';
import { HardwareBundleEditModel } from '../../../models/requests/hardware-bundle-edit.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { HardwareBundleBaseDataComponent } from './edit-items/hardware-bundle-base-data/hardware-bundle-base-data.component';
import { HardwareBundleImageComponent } from './edit-items/hardware-bundle-image/hardware-bundle-image.component';

@Component({
    selector: 'arc-hardware-bundles',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './hardware-bundles.component.scss'
})
export class HardwareBundlesComponent extends PageListViewComponent<HardwareBundleListModel, HardwareBundleModel, HardwareBundleEditModel> {
    private readonly hardwareBundleStore = inject(HardwareBundlesStore);
    constructor() {
        super();
        this.config = new TableListViewConfigModel<HardwareBundleListModel, HardwareBundleModel, HardwareBundleEditModel>({
            entityName: 'HardwareBundle',
            availableColumns: {
                title: new StringColumnModel({
                    columnTitleKey: 'ModuleBundle.List.Title',
                    propertyName: 'title',
                    widthPixels: 400
                }),
                product: new StringColumnModel({
                    columnTitleKey: 'ModuleBundle.List.Product',
                    propertyName: 'productTitle',
                    widthPixels: 250
                }),
                price: new CurrencyColumnModel({
                    propertyName: 'price',
                    currencyIsoCode: item => item.currencyIsoCode,
                    columnTitleKey: 'ModuleBundle.List.Price',
                    widthPixels: 150
                })
            },
            defaultColumnOrder: ['title', 'product', 'price'],
            defaultSort: new ColumnSortModel({
                column: 'title',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'HardwareBundle.Edit.BaseData.Name',
                        component: HardwareBundleBaseDataComponent
                    },
                    {
                        titleKey: 'HardwareBundle.Edit.Image.Name',
                        component: HardwareBundleImageComponent
                    }
                ]
            },
            store: this.hardwareBundleStore
        });
    }
}
