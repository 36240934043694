import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { BenefitListModel } from '../../../models/responses/benefit-list.model';
import { BenefitModel } from '../../../models/benefit.model';
import { BenefitEditModel } from '../../../models/requests/benefit-edit.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { BenefitDiscountComponent } from './components/benefit-discount/benefit-discount.component';
import { BenefitTypeComponent } from './components/benefit-type/benefit-type.component';
import { BenefitPaymentTypeComponent } from './components/benefit-payment-type/benefit-payment-type.component';
import { BenefitPaymentPeriodComponent } from './components/benefit-payment-period/benefit-payment-period.component';
import { BenefitsStore } from '../../../services/stores/benefits.store';
import { BenefitBasicDataComponent } from './edit-items/benefit-basic-data/benefit-basic-data.component';

@Component({
    selector: 'arc-benefits',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './benefits.component.scss'
})
export class BenefitsComponent extends PageListViewComponent<BenefitListModel, BenefitModel, BenefitEditModel> {
    private readonly benefitsStore = inject(BenefitsStore);

    constructor() {
        super();
        this.config = new TableListViewConfigModel<BenefitListModel, BenefitModel, BenefitEditModel>({
            entityName: 'Benefits',
            availableColumns: {
                product: new StringColumnModel({
                    columnTitleKey: 'Benefits.List.Product',
                    propertyName: 'productTitle',
                    widthPixels: 200
                }),
                title: new StringColumnModel({
                    columnTitleKey: 'Benefits.List.Title',
                    propertyName: 'title',
                    widthPixels: 160
                }),
                code: new StringColumnModel({
                    columnTitleKey: 'Benefits.List.Code',
                    propertyName: 'code',
                    widthPixels: 150
                }),
                discount: new CustomColumnModel({
                    columnTitleKey: 'Benefits.List.Discount',
                    customComponent: BenefitDiscountComponent,
                    widthPixels: 140
                }),
                type: new CustomColumnModel({
                    columnTitleKey: 'Benefits.List.Type',
                    customComponent: BenefitTypeComponent,
                    widthPixels: 200
                }),
                paymentType: new CustomColumnModel({
                    columnTitleKey: 'Benefits.List.PaymentType',
                    customComponent: BenefitPaymentTypeComponent,
                    widthPixels: 160
                }),
                paymentPeriod: new CustomColumnModel({
                    columnTitleKey: 'Benefits.List.PaymentPeriod',
                    customComponent: BenefitPaymentPeriodComponent,
                    widthPixels: 160
                })
            },
            defaultColumnOrder: ['product', 'title', 'code', 'discount', 'type', 'paymentType', 'paymentPeriod'],
            defaultSort: new ColumnSortModel({
                column: 'id',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            editSidebarConfig: {
                editComponents: [{ titleKey: 'Benefits.Edit.BaseData.Name', component: BenefitBasicDataComponent }]
            },
            store: this.benefitsStore
        });
    }
}
