/* eslint-disable max-len */
import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { NewsletterSubscriberListModel } from '../../../models/responses/newsletter-subscriber-list.model';
import { NewsletterSubscriberModel } from '../../../models/newsletter-subscriber.model';
import { NewsletterSubscriberEditModel } from '../../../models/requests/newsletter-subscriber-edit.model';
import { NewsletterSubscribersStore } from '../../../services/stores/newsletter-subscribers.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { SubscribersEditComponent } from './subscribers-edit-items/subscribers-edit/subscribers-edit.component';

@Component({
    selector: 'arc-subscribers',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './subscribers.component.scss'
})
export class SubscribersComponent extends PageListViewComponent<NewsletterSubscriberListModel, NewsletterSubscriberModel, NewsletterSubscriberEditModel
> {
    private readonly newsletterSubscribersStore = inject(NewsletterSubscribersStore);
    constructor() {
        super();
        this.config = new TableListViewConfigModel<NewsletterSubscriberListModel, NewsletterSubscriberModel, NewsletterSubscriberEditModel>(
            {
                entityName: 'NewsletterSubscriber',
                availableColumns: {
                    email: new StackedColumnModel({
                        columnTitleKey: 'NewsletterSubscriber.List.Email',
                        propertyName: 'email',
                        propertyName2: 'subscriptionType',
                        widthPixels: 450
                    }),
                    name: new StringColumnModel({
                        columnTitleKey: 'NewsletterSubscriber.List.Name',
                        propertyName: 'name',
                        widthPixels: 200
                    }),
                    licenseHolder: new StackedColumnModel({
                        columnTitleKey: 'NewsletterSubscriber.List.LicenseHolder',
                        propertyName: 'licenseHolderName',
                        propertyName2: 'licenseId',
                        widthPixels: 350
                    }),
                    languageIsoCode: new StringColumnModel({
                        columnTitleKey: 'NewsletterSubscriber.List.LanguageIsoCode',
                        propertyName: 'languageIsoCode',
                        widthPixels: 150
                    })
                },
                defaultColumnOrder: ['email', 'name', 'licenseHolder', 'languageIsoCode'],
                defaultSort: new ColumnSortModel({
                    column: 'id',
                    direction: SortDirectionEnum.Desc
                }),
                availableDetailWidgets: {},
                defaultDetailWidgetOrder: [],
                editSidebarConfig: {
                    editComponents: [
                        {
                            titleKey: 'NewsletterSubscriber.Edit.Name',
                            component: SubscribersEditComponent
                        }
                    ]
                },
                store: this.newsletterSubscribersStore
            }
        );
    }
}
