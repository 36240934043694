<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="internalControl" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    @if (!!label) {
        <mat-label>{{ isLabelTranslated ? label : (label | transloco) }}</mat-label>
    }
    <mat-select [formControl]="internalControl" (selectionChange)="handleSelect($event)"
        [multiple]="isMultiselect() ? true : null"
        [placeholder]="isPlaceholderTranslated ? placeholder : (placeholder | transloco)">
        <mat-option *ngFor="let option of data" [value]="option.value">
            {{ option.label }}
        </mat-option>
    </mat-select>
    @if (isLoading()) {
        <mat-spinner matSuffix diameter="22" />
    }
</mat-form-field>