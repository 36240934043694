<div class="h-full flex flex-col w-full">
    <arc-search-filter [store]="config.store" />

    <div class="relative pl-[9px] py-1 flex items-center overflow-hidden" #tableHeader matSort (matSortChange)="sortData($event)">
        @if (config.showCheckboxes && visibleColumns.length > 0) {
            <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selectionModel.hasValue() && isEverythingSelected()"
                [indeterminate]="selectionModel.hasValue() && !isEverythingSelected()">
            </mat-checkbox>
        }
        @for (column of visibleColumns; track column.identifier; let i = $index) {
            <span class="pr-2 flex-none flex justify-{{column.columnTitleAlignment}}" [attr.data-index]="i"
                [ngStyle]="{ 'width.px': column.widthPixels }">
                @if (column.allowSort && !!column.propertyName && !column.shouldHideColumnTitle) {
                    <span class="text-sm" [mat-sort-header]="column.sortColumn || column.propertyName">
                        @if (!column.titleSignal) {
                            {{ column.columnTitleKey | transloco }}
                            {{ !!column.columnTitleSuffix ? ' ' + column.columnTitleSuffix : '' }}
                        } @else {
                            {{ column.titleSignal() }}
                        }
                    </span>
                } @else {
                    <span class="text-sm overflow-hidden overflow-ellipsis">
                        {{ column.shouldHideColumnTitle ? '' : (column.columnTitleKey | transloco) }}
                    </span>
                }
            </span>
        }
        @if (!isInitializing && visibleColumns.length > 0) {
            <button mat-icon-button class="!absolute right-0" (click)="openTableSettingsDialog()" [matTooltipShowDelay]="400"
                [matTooltip]="'Components.TableSettingsDialog.Title' | transloco">
                <mat-icon class="!text-icon-light">settings</mat-icon>
            </button>
        }
    </div>
    <mat-accordion class="pb-1 relative min-h-[128px]" multi #accordion>
        @for (listModel of data(); track listModel.id) {
            <mat-expansion-panel class="item-row" (opened)="handlePanelOpened(listModel)" (closed)="handlePanelClosed(listModel)"
                [expanded]="data().length === 1 || openedPanels[getItemId(listModel.id)]" hideToggle>
                <mat-expansion-panel-header class="!pl-2 !pr-0">
                    <mat-panel-title class="!m-0">
                        @if (config.showCheckboxes) {
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="itemSelectionChanged(listModel)"
                                [checked]="isSelected(listModel)">
                            </mat-checkbox>
                        }

                        <!-- dynamic columns -->
                        @for(column of visibleColumns; track $index; let i = $index) {
                            <div class="h-full flex items-center pr-[8px]" [style.min-width.px]="column.widthPixels" [attr.data-index]="i"
                                [style.max-width.px]="column.widthPixels">
                                <ng-container arcDynamicColumn [columnModel]="column" [item]="listModel"></ng-container>
                            </div>
                        }

                        <arc-buttons-bar class="buttons-bar absolute flex flex-nowrap right-2 top-1/2 -translate-y-1/2" [data]="listModel"
                            [ngClass]="{ 'smd:hidden': !openedPanels[getItemId(listModel.id)] }" [shouldStopPropagation]="true"
                            [items]="singleRecordActionButtons()">
                        </arc-buttons-bar>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex flex-wrap gap-4 smd:flex-col smd:items-center">
                    @if (entityModels[getItemId(listModel.id)]) {
                        @if (tableSettings.detailWidgetOrder.length > 0) {
                            @for (detailWidgetName of tableSettings.detailWidgetOrder; track detailWidgetName) {
                                <mat-card class="smd:w-full !border-2 !border-app !border-solid"
                                    [ngClass]="getSizeClasses(detailWidgetName)">
                                    <mat-card-header>
                                        <mat-card-subtitle class="text-sm">
                                            {{ getSubtitleKey(detailWidgetName) | transloco }}
                                        </mat-card-subtitle>
                                    </mat-card-header>

                                    <mat-card-content class="h-full overflow-x-auto mt-2"
                                        [class.!p-0]="shouldRemovePadding(detailWidgetName)">
                                        <ng-container arcTableDetailWidget [listDetailWidget]="getDetailWidgetByName(detailWidgetName)"
                                            [listModel]="listModel" [entityModel]="entityModels[getItemId(listModel.id)]!">
                                        </ng-container>
                                    </mat-card-content>
                                </mat-card>
                            }
                        } @else {
                            <mat-card class='empty-widget'>
                                <mat-card-header>
                                    <mat-card-title class="text-xl font-bold text-center">
                                        @if ((config.availableDetailWidgets | keyvalue).length) {
                                            <span>{{ "Widgets.NoWidgets.NotConfigured.Title" | transloco }}</span>
                                        } @else {
                                            <span>{{ "Widgets.NoWidgets.NotAvailable.Title" | transloco }}</span>
                                        }
                                    </mat-card-title>
                                </mat-card-header>
                                <mat-card-content class="h-full mt-2 overflow-x-auto">
                                    @if ((config.availableDetailWidgets | keyvalue).length) {
                                        <p> {{ "Widgets.NoWidgets.NotConfigured.Text" | transloco }} </p>
                                        <button mat-stroked-button (click)="openTableSettingsDialog(1)">
                                            <mat-icon>settings</mat-icon>
                                            {{ "Widgets.NoWidgets.NotConfigured.Button" | transloco }}
                                        </button>
                                    } @else {
                                        <p>{{ "Widgets.NoWidgets.NotAvailable.Text" | transloco }}</p>
                                    }
                                </mat-card-content>
                            </mat-card>
                        }
                    } @else {
                        <mat-spinner [diameter]="32"></mat-spinner>
                    }
                </div>
            </mat-expansion-panel>
        }

        @if (data().length === 0 && !isLoading && !isInitializing) {
            <div class="absolute inset-0 grid place-items-center">
                {{ 'General.NoRecords' | transloco }}
            </div>
        }

        @if (isLoading || isInitializing) {
            <div class="absolute inset-0 bg-overlay grid place-items-center">
                <mat-spinner diameter="64"></mat-spinner>
            </div>
        }
    </mat-accordion>

    <mat-paginator class="mt-2 mb-44 !bg-transparent" [length]="totalRecords" [pageSizeOptions]="config.paginatorConfig.pageSizeOptions"
        [showFirstLastButtons]="true" (page)="handlePageEvent($event)" aria-label="Select page">
    </mat-paginator>
</div>
