<mat-sidenav-container class="h-full" autosize [hasBackdrop]="isMobile()" (backdropClick)="handleLeftBackdropClick()">
    <mat-sidenav-content>
        <ng-content />
    </mat-sidenav-content>

    <mat-sidenav [mode]="isMobile() ? 'over' : 'side'" [opened]="sidebarService.leftOpenSubject | async"
        class="!border-r-0">
        <div class="h-full flex flex-col" [style.width.px]="sidebarWidthPx">
            <div class="grow overflow-y-auto">
                <mat-nav-list class="h-full !flex flex-col !p-0">
                    @if (!isEmptyFavouritesList()) {
                        @for (menuItem of currentSubMenu(); track $index) {
                            @if (!menuItem.isDisabled) {
                                <a mat-list-item class="!h-[45px] shrink-0 hover:bg-element-hover"
                                    routerLinkActive="!bg-element-selected" [routerLink]="menuItem.path"
                                    [queryParams]="menuItem.startingQueryParams">
                                    <span matListItemTitle
                                        class="ml-2 !text-inherit">{{ menuItem.labelKey | transloco }}</span>
                                </a>
                            } @else {
                                <mat-list-item class="!h-[45px]" [matTooltip]="'General.ComingSoon' | transloco"
                                    disabled>
                                    <span matListItemTitle
                                        class="ml-2 !text-inherit">{{ menuItem.labelKey | transloco }}</span>
                                </mat-list-item>
                            }
                        }
                    } @else {
                        <p class="mt-10 mx-4 break-words text-center text-base text-on-app-light">
                            {{ 'Layout.Menu.Favourites.NoFavourites' | transloco }}
                        </p>
                    }
                </mat-nav-list>
            </div>

            <!-- footer -->
            <div class="px-4 py-4 text-on-app-light text-xs">
                <div class="line-clamp-1">&copy; {{ portalName }}</div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>