import { Component, HostBinding, Input, OnInit, OnChanges, SimpleChanges, inject, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';
import { UnitsEnum, UnitsEnumHepers } from '../../../core/models/enums/units.enum';
import { OptionalType } from '../../../core/models/types/optional.type';
import { TranslationService } from '../../../core/services/translation.service';

@Component({
    selector: 'arc-number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: NumberInputComponent
        }
    ]
})
export class NumberInputComponent extends BaseControlValueAccessor<number> implements OnInit, OnChanges {
    @HostBinding('class') classes = 'grow';

    @Input() unit?: string | UnitsEnum;
    @Input() isUnitTranslated = false;
    @Input() decimalPlaces?: number;
    @Input() maxSuffixWidth?: number;
    @Input() min?: number;
    @Input() max?: number;

    @Output() readonly focusChanged = new EventEmitter<Event>();

    unitText? = '';
    inputStep = 1;
    decimalSign = '.';

    private readonly translationService = inject(TranslationService);

    override ngOnInit(): void {
        super.ngOnInit();

        this.updateUnitText();

        this.inputStep = Math.pow(10, -(this.decimalPlaces ?? 2));

        // get decimal sign
        const localString = (0.1).toLocaleString(this.translationService.current.culture, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        });
        this.decimalSign = localString.replace(/[01]/g, '');
    }

    override writeValue(value?: number): void {
        const [roundedString, roundedNr] = this.returnInput(value);

        super.writeValue(roundedNr);
        this.internalControl.setValue(roundedString);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['unit']) {
            this.updateUnitText();
        }
    }

    onInputChanged(): void {
        const [roundedString, roundedNr] = this.returnInput(this.internalControl.value);
        this.valueChanged(roundedNr);
        this.internalControl.setValue(roundedString);
    }

    onBlur(): void {
        const [roundedString, roundedNr] = this.returnInput(this.internalControl.value);
        if (this.internalControl.value !== roundedString) {
            this.valueChanged(roundedNr);
            this.internalControl.setValue(roundedString);
        }
    }

    private returnInput(value?: string | number): [OptionalType<string>, OptionalType<number>] {
        // eslint-disable-next-line no-null/no-null
        if (value === undefined || value === null) {
            return [undefined, undefined];
        }

        let valueStr = value.toString();
        if (typeof value === 'number') {
            // for correct parsing, replace '.' with local decimal sign
            valueStr = valueStr.replace(/\./g, this.decimalSign);
        }
        let valueNumber = this.parseLocalizedNumber(valueStr);

        if (valueNumber === undefined || Number.isNaN(valueNumber)) {
            return [undefined, undefined];
        }

        if (this.min !== undefined && valueNumber < this.min) {
            valueNumber = this.min;
        } else if (this.max !== undefined && valueNumber > this.max) {
            valueNumber = this.max;
        }

        const localizedValueNumber = valueNumber.toLocaleString(this.translationService.current.culture, {
            minimumFractionDigits: this.decimalPlaces,
            maximumFractionDigits: this.decimalPlaces
        });
        return [localizedValueNumber, this.parseLocalizedNumber(localizedValueNumber)];
    }

    private parseLocalizedNumber(value: string): number {
        // remove all non-numerical characters
        value = value.replace(new RegExp(`[^-${this.decimalSign}\\d]`, 'g'), '');

        if (value === '') {
            return NaN;
        }

        // replace decimal sign
        value = value.replace(this.decimalSign, '.');

        return Number(value);
    }

    private updateUnitText(): void {
        if (this.unit === UnitsEnum.SpacedPercentSymbol) {
            this.unit = ' % ';
        } else if (!!this.unit && this.unit in UnitsEnum) {
            this.isUnitTranslated = false;
            this.unitText = UnitsEnumHepers.getUnitShortTextKey(this.unit as UnitsEnum);
        } else {
            this.unitText = this.unit;
        }
    }
}
