import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { FeatureListModel } from '../../../../../models/responses/feature-list.model';
import { FeatureModel } from '../../../../../models/feature.model';

@Component({
    selector: 'arc-feature-description',
    templateUrl: './feature-description.component.html',
    styleUrl: './feature-description.component.scss'
})
export class FeatureDescriptionComponent extends BaseDetailWidget<FeatureListModel, FeatureModel> {}
