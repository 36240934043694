<div class="h-full">
    <div class="h-full w-full">
        @if (isLoading()) {
            <div class="w-full h-full grid place-items-center">
                <mat-spinner [diameter]="32" />
            </div>
        } @else {
            <arc-static-table [config]="tableConfig" [staticData]="items()" [shouldUseCompactStyle]="true" />
        }
    </div>
</div>