import { Component, EventEmitter, OnInit, Output, inject, computed, signal } from '@angular/core';

import { BaseComponent } from '../../../../../../../components/abstractions/base.component';
import { TicketActionButtonModel } from '../../../../../../../core/models/ticket-action-button.model';
import { CreateTicketService } from '../../../../../../../core/services/create-ticket.service';
import { SidebarHeaderService } from '../../../../../../../core/services/sidebar-header.service';
import { TranslationService } from '../../../../../../../core/services/translation.service';
import { CreateTicketStepEnum } from '../../../../../../models/enums/create-ticket-step.enum';
import { TicketsStore } from '../../../../../../services/stores/tickets.store';
import { HelpArticlesStore } from '../../../../../../services/stores/help-articles.store';
import { SidebarService } from '../../../../../../../core/services/sidebar.service';
import { SearchRequestService } from '../../../../../../../core/services/search-request.service';

@Component({
    selector: 'arc-create-ticket-response',
    templateUrl: './create-ticket-response.component.html',
    styleUrl: './create-ticket-response.component.scss'
})
export class CreateTicketResponseComponent extends BaseComponent implements OnInit {
    @Output() readonly solutionAccepted = new EventEmitter<void>();

    readonly isLoading = signal(false);

    createTicketResponse = computed(() => this.createTicketService.createTicketResponse());
    recommendedArticles = computed(() => this.createTicketService.createTicketResponse()?.recommendedHelpTopics || []);
    selectedArticle = computed(() => this.createTicketService.selectedArticle());

    private readonly ticketStore = inject(TicketsStore);
    private readonly createTicketService = inject(CreateTicketService);
    private readonly translationService = inject(TranslationService);
    private readonly helpArticlesStore = inject(HelpArticlesStore);
    private readonly headerService = inject(SidebarHeaderService);
    private readonly sidebarService = inject(SidebarService);
    private readonly searchRequestService = inject(SearchRequestService);

    ngOnInit(): void {
        const createTicket = new TicketActionButtonModel({
            id: 'create-ticket',
            text: this.translationService.getText('Tickets.Response.CreateTicket'),
            step: CreateTicketStepEnum.RequestCreated,
            color: undefined,
            type: 'stroked',
            clickFn: () => this.createTicket()
        });
        const problemSolved = new TicketActionButtonModel({
            id: 'problem-solved',
            text: this.translationService.getText('Tickets.Response.ProblemSolved'),
            step: CreateTicketStepEnum.RequestCreated,
            clickFn: () => this.problemSolved()
        });
        this.createTicketService.addButtons(createTicket, problemSolved);
        this.addSubscriptions(this.headerService.backButtonClicked.subscribe(() => this.handleArticleBack()));
    }

    problemSolved(): void {
        const createTicketResponse = this.createTicketService.createTicketResponse();
        if (!createTicketResponse) {
            return;
        }

        this.ticketStore.resolveTicket(createTicketResponse.requestId).subscribe(() => {
            this.createTicketService.acceptSolution();
            this.sidebarService.closeRight();
            this.searchRequestService.forceReload();
        });
    }

    createTicket(): void {
        this.createTicketService.createTicket();
    }

    selectArticle(articleId: number): void {
        this.createTicketService.selectedArticle.set(undefined);
        this.isLoading.set(true);
        this.helpArticlesStore.get(articleId).subscribe(r => {
            this.createTicketService.selectedArticle.set(r.value);
            this.isLoading.set(false);
        });
    }

    handleArticleBack(): void {
        this.createTicketService.selectedArticle.set(undefined);
    }
}
