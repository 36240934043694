import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { InvoiceListModel } from '../../../models/responses/invoice-list.model';
import { InvoiceModel } from '../../../models/invoice.model';
import { InvoiceEditModel } from '../../../models/requests/invoice-edit.model';
import { InvoicesStore } from '../../../services/stores/invoices.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { GenericStatusColumnModel } from '../../../../components/dynamic-table/models/column-types/generic-status-column.model';
import { InvoiceStatusEnumExtensions } from '../../../models/enums/invoice-status.enum';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { InvoiceBaseDataComponent } from './edit-items/invoice-base-data/invoice-base-data.component';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { PaymentTypesEnumExtensions } from '../../../models/enums/payment-types.enum';

@Component({
    selector: 'arc-invoices',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './invoices.component.scss'
})
export class InvoicesComponent extends PageListViewComponent<InvoiceListModel, InvoiceModel, InvoiceEditModel> {
    private readonly invoicesStore = inject(InvoicesStore);

    constructor() {
        super();
        this.config = new TableListViewConfigModel<InvoiceListModel, InvoiceModel, InvoiceEditModel>({
            entityName: 'Invoice',
            availableColumns: {
                id: new StringColumnModel({
                    columnTitleKey: 'Invoice.List.Id',
                    propertyName: 'id',
                    widthPixels: 100
                }),
                perDate: new DateColumnModel({
                    columnTitleKey: 'Invoice.List.PerDate',
                    propertyName: 'perDate',
                    widthPixels: 120
                }),
                sentDate: new DateColumnModel({
                    columnTitleKey: 'Invoice.List.SentDate',
                    propertyName: 'sentDate',
                    widthPixels: 120
                }),
                recipient: new StringColumnModel({
                    columnTitleKey: 'Invoice.List.Recipient',
                    propertyName: 'recipient',
                    widthPixels: 250
                }),
                totalAmount: new CurrencyColumnModel({
                    columnTitleKey: 'Invoice.List.TotalAmount',
                    propertyName: 'totalAmountInclusiveVat',
                    currencyIsoCode: item => item.currencyIsoCode,
                    widthPixels: 160
                }),
                amountOpen: new CurrencyColumnModel({
                    columnTitleKey: 'Invoice.List.AmountOpen',
                    propertyName: 'amountOpen',
                    currencyIsoCode: item => item.currencyIsoCode,
                    widthPixels: 160
                }),
                status: new GenericStatusColumnModel({
                    columnTitleKey: 'General.Status',
                    propertyName: 'status',
                    descriptionColumn: 'statusDescription',
                    widthPixels: 160,
                    bgColor: status => InvoiceStatusEnumExtensions.getColor(status)
                }),
                paymentType: new GenericStatusColumnModel({
                    columnTitleKey: 'Invoice.List.PaymentType',
                    propertyName: 'paymentType',
                    statusColumn: 'paymentType',
                    descriptionColumn: 'paymentTypeDescription',
                    widthPixels: 160,
                    bgColor: paymentType => PaymentTypesEnumExtensions.getColor(paymentType)
                })
            },
            defaultColumnOrder: ['id', 'perDate', 'recipient', 'totalAmount', 'amountOpen', 'status', 'sentDate', 'paymentType'],
            defaultSort: new ColumnSortModel({
                column: 'id',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'Invoice.Edit.BaseData.Name',
                        component: InvoiceBaseDataComponent
                    }
                ]
            },
            store: this.invoicesStore,
            buttonsVisibility: new ButtonsVisibilityModel({ hasCreate: false })
        });
    }
}
