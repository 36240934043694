import { Component, inject, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { LicenseEditModel } from '../../../../../models/requests/license-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { LicenseModel } from '../../../../../models/license.model';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { TranslationService } from '../../../../../../core/services/translation.service';

@Component({
    selector: 'arc-license-edit-users-reseller',
    templateUrl: './license-edit-users-reseller.component.html',
    styleUrl: './license-edit-users-reseller.component.scss'
})
export class LicenseEditUsersResellerComponent extends BaseEditSidebarItemComponent<LicenseModel, LicenseEditModel> {
    override formGroup = new FormGroup({
        email: new ArcFormControl('', Validators.required),
        languageCode: new ArcFormControl('de', Validators.required),
        firstName: new ArcFormControl<OptionalType<string>>(undefined),
        lastName: new ArcFormControl<OptionalType<string>>(undefined)
    });

    allowedLanguages = signal<SelectOptionModel<string>[]>([]);

    private readonly translationService = inject(TranslationService);

    constructor() {
        super();
        this.allowedLanguages.set(this.translationService.allowedLanguages.map(l => ({ label: l.name, value: l.code })));
    }

    onItemSet(): void {
        this.formGroup.patchValue(this.item().user);
    }

    override prepareSaveModel(): Partial<LicenseEditModel> {
        const value = this.formGroup.getRawValue();
        return {
            userId: undefined,
            user: {
                id: this.item().user?.id,
                email: value.email || '',
                firstName: value.firstName || '',
                lastName: value.lastName || '',
                languageCode: value.languageCode || ''
            }
        };
    }
}
