<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-input labelKey="License.Edit.BaseData.Domain" [control]="formGroup.controls.subDomain" [suffix]="formGroup.controls.productionHostUrl.value"></arc-input>
    <arc-input labelKey="License.Edit.BaseData.Company" [control]="formGroup.controls.licenseHolder"></arc-input>
    <arc-address-picker class="col-span-12"
        [streetControl]="formGroup.controls.street"
        [streetNumberControl]="formGroup.controls.streetNumber"
        [zipControl]="formGroup.controls.zip"
        [cityControl]="formGroup.controls.city"
        [countryIsoCodeControl]="formGroup.controls.countryIsoCode">
    </arc-address-picker>
    <div class="flex gap-2">
        <arc-number-input label="License.Edit.BaseData.PointOfSales" [decimalPlaces]="0" [formControl]="formGroup.controls.numberOfPos"></arc-number-input>
    </div>
    <arc-general-data-select
        [generalDataType]="GeneralDataTypeEnum.Products"
        [formControl]="formGroup.controls.productId"
        [shouldAutoSelectFirstOption]="isCreate()"
        label="License.Edit.BaseData.Product"></arc-general-data-select>
    <arc-checkbox labelKey="License.Edit.BaseData.IsLocked" [control]="formGroup.controls.isLocked"></arc-checkbox>
</form>
