import { Component, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { CustomerInteractionListModel } from '../../../models/responses/customer-interaction-list.model';
import { CustomerInteractionModel } from '../../../models/customer-interaction.model';
import { CustomerInteractionsStore } from '../../../services/stores/customer-interactions.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { CustomerInteractionTypesEnum } from '../../../models/enums/customer-interaction-types.enum';
import { CustomerInteractionMoodsEnum } from '../../../models/enums/customer-interaction-moods.enum';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { CustomerInteractionDialogComponent } from '../../../../components/dialogs/customer-interaction-dialog/customer-interaction-dialog.component';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { CustomerInteractionsNoteDetailWidget } from './customer-interactions-widgets/customer-interactions-note-widget/customer-interactions-note.detail-widget';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-customer-interactions',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './customer-interactions.component.scss'
})
export class CustomerInteractionsComponent extends PageListViewComponent<CustomerInteractionListModel, CustomerInteractionModel> {
    private readonly customerInteractionsStore = inject(CustomerInteractionsStore);
    private readonly matDialog = inject(MatDialog);
    constructor() {
        super();
        this.config = new TableListViewConfigModel<CustomerInteractionListModel, CustomerInteractionModel>({
            entityName: 'CustomerInteraction',
            availableColumns: {
                license: new StackedColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.License',
                    propertyName: 'licenseHolder',
                    propertyName2: 'licenseId',
                    widthPixels: 180
                }),
                userName: new StringColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.UserName',
                    propertyName: 'userName',
                    widthPixels: 250
                }),
                userEmail: new StringColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.UserEmail',
                    propertyName: 'userEmail',
                    widthPixels: 250
                }),
                userPhone: new StringColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.UserPhone',
                    propertyName: 'userPhone',
                    widthPixels: 150
                }),
                type: new StringColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.Type',
                    propertyName: 'type',
                    customFormatter: (record, value) => CustomerInteractionTypesEnum[value],
                    widthPixels: 100
                }),
                mood: new StringColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.Mood',
                    propertyName: 'mood',
                    customFormatter: (record, value) => CustomerInteractionMoodsEnum[value],
                    widthPixels: 100
                }),
                createdByUser: new StringColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.CreatedByUser',
                    propertyName: 'createdByUser',
                    widthPixels: 200
                }),
                message: new StringColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.Message',
                    propertyName: 'message'
                })
            },
            defaultColumnOrder: ['license', 'userName', 'userEmail', 'userPhone', 'type', 'mood', 'createdByUser', 'message'],
            defaultSort: new ColumnSortModel({
                column: 'id',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {
                message: new TableListDetailWidgetModel({
                    component: CustomerInteractionsNoteDetailWidget,
                    shouldHideHeader: true,
                    takeFullSizeIfOnly: true
                })
            },
            defaultDetailWidgetOrder: ['message'],
            editSidebarConfig: {
                editComponents: []
            },
            handleCreateButtonClick: this.createCustomerInteraction.bind(this),
            buttonsVisibility: new ButtonsVisibilityModel({ hasEdit: false }),
            store: this.customerInteractionsStore
        });
    }

    createCustomerInteraction(): Observable<boolean> {
        return this.matDialog
            .open(CustomerInteractionDialogComponent, {
                width: '600px',
                maxHeight: '98svh',
                maxWidth: '98vw'
            })
            .afterClosed()
            .pipe(map(() => true));
    }
}
