import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { HardwareBundleEditModel } from '../../../../../models/requests/hardware-bundle-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { HardwareBundleModel } from '../../../../../models/hardware-bundle.model';

@Component({
    selector: 'arc-hardware-bundle-base-data',
    templateUrl: './hardware-bundle-base-data.component.html',
    styleUrl: './hardware-bundle-base-data.component.scss'
})
export class HardwareBundleBaseDataComponent extends BaseEditSidebarItemComponent<HardwareBundleModel, HardwareBundleEditModel> {
    override formGroup = new FormGroup({
        productId: new ArcFormControl(0, Validators.required),
        title_de: new ArcFormControl('', Validators.required),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        minQuantity: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0, isInteger: true })]),
        maxQuantity: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0, isInteger: true })]),
        price: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0.0 })]),
        noBenefit: new ArcFormControl(false, Validators.required),
        usePosQuantity: new ArcFormControl(false, Validators.required)
    });

    GeneralDataTypeEnum = GeneralDataTypeEnum;

    onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<HardwareBundleEditModel> {
        const value = this.formGroup.getRawValue();
        return {
            productId: value.productId,
            title_de: value.title_de,
            title_en: value.title_en,
            title_fr: value.title_fr,
            title_it: value.title_it,
            minQuantity: value.minQuantity,
            maxQuantity: value.maxQuantity,
            price: value.price,
            noBenefit: value.noBenefit,
            usePosQuantity: value.usePosQuantity
        };
    }
}
