export class ButtonsVisibilityModel {
    hasCreate = true;
    hasEdit = true;
    hasDelete = true;
    hasExport = true;

    constructor(init?: Partial<ButtonsVisibilityModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
