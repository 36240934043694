<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="controlDe" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    @if (!!label || !!labelKey) {
        <mat-label>{{ label || (labelKey | transloco)}}</mat-label>
    }
    <input class="mr-[40px]" matInput [id]="tagId" (blur)="onBlur()" (input)="onChange($event)"
        [placeholder]="placeholder || (placeholderKey | transloco)" [formControl]="control" />
    <button type="button" mat-icon-button matSuffix (click)="openTranslationDialog()">
        <mat-icon>translate</mat-icon>
    </button>
</mat-form-field>