<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-input labelKey="ReportTemplates.EditFields.Title_de" [control]="formGroup.controls.title_de"></arc-input>
    <arc-input labelKey="ReportTemplates.EditFields.Title_en" [control]="formGroup.controls.title_en"></arc-input>
    <arc-input labelKey="ReportTemplates.EditFields.Title_fr" [control]="formGroup.controls.title_fr"></arc-input>
    <arc-input labelKey="ReportTemplates.EditFields.Title_it" [control]="formGroup.controls.title_it"></arc-input>

    <arc-input labelKey="ReportTemplates.EditFields.Description_de" [control]="formGroup.controls.description_de"></arc-input>
    <arc-input labelKey="ReportTemplates.EditFields.Description_en" [control]="formGroup.controls.description_en"></arc-input>
    <arc-input labelKey="ReportTemplates.EditFields.Description_fr" [control]="formGroup.controls.description_fr"></arc-input>
    <arc-input labelKey="ReportTemplates.EditFields.Description_it" [control]="formGroup.controls.description_it"></arc-input>

    <arc-input labelKey="ReportTemplates.EditFields.Key" [control]="formGroup.controls.key"></arc-input>

    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.Modules"
        [formControl]="formGroup.controls.requiredModuleId" label="ReportTemplates.EditFields.RequiredModuleId">
    </arc-general-data-select>

    <arc-input labelKey="ReportTemplates.EditFields.Version" [control]="formGroup.controls.version" type="number"
        [step]="0.01"></arc-input>
    <arc-file-upload [formControl]="formGroup.controls.reportFile" fileTypes=".rdlx">
    </arc-file-upload>
</form>