<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="_formControl" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    @if (!!label) {
        <mat-label>{{ isLabelTranslated ? label : (label | transloco) }}</mat-label>
    }
    <input matInput (blur)="onBlur()" (change)="onInputChanged()" [attr.step]="inputStep"
        [placeholder]="(!placeholder || isPlaceholderTranslated) ? placeholder : (placeholder | transloco)"
        [formControl]="internalControl" [attr.min]="min" [attr.max]="max"
        (focus)="focusChanged.emit($event)" />
    @if (!!unit) {
        <div matTextSuffix class="text-on-app-light truncate" [style.max-width.px]="maxSuffixWidth">
            {{ isUnitTranslated ? unitText : (unitText | transloco) }}
        </div>
    }
</mat-form-field>