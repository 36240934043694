import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { DynamicFormField } from '../../../components/dynamic-form/models/dynamic-form-field';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { ResponseTypeEnum } from '../../../core/models/enums/response-type.enum';
import { PosReportTemplateModel } from '../../models/pos-report-template.model';
import { PosReportTemplateListModel } from '../../models/responses/pos-report-template-list.model';

@Injectable({
    providedIn: 'root'
})
export class PosReportTemplatesStore extends BaseCrudStore<PosReportTemplateModel, PosReportTemplateListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'pos-report-templates' }));
    }

    getReportParameters(id: string): Observable<ApiResponseModel<DynamicFormField[]>> {
        return this._requestService.makeGet<ApiResponseModel<DynamicFormField[]>>(this.getUrl(`${id}/parameters`));
    }

    installReport(templateKey: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost(this.getUrl(`${templateKey}/install`));
    }

    duplicateReport(id: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost(this.getUrl(`${id}/duplicate`));
    }

    downloadReport(id: number): Observable<any> {
        return this._requestService.makeGet(this.getUrl(`${id}/file`), undefined, undefined, undefined, undefined, ResponseTypeEnum.Blob);
    }
}
