import { Component, inject } from '@angular/core';

import { NumberColumnModel } from '../../../../components/dynamic-table/models/column-types/number-column.model';
import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { LicenseListModel } from '../../../models/responses/license-list.model';
import { LicensesStore } from '../../../services/stores/licenses.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { LicenseModel } from '../../../models/license.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { LicenseValidThruComponent } from './components/license-valid-thru/license-valid-thru.component';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { LicenseBaseDataComponent } from './licenses-edit-items/license-base-data/license-base-data.component';
import { LicenseEditModel } from '../../../models/requests/license-edit.model';
import { LicensePaymentComponent } from './licenses-edit-items/license-payment/license-payment.component';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { LicenseDbInfoWidgetComponent } from './licenses-details-widgets/license-db-info-widget/license-db-info-widget.component';
import { UserRoles } from '../../../models/enums/user-roles.enum';
import { LicenseEditUsersComponent } from './licenses-edit-items/license-edit-users/license-edit-users.component';
import { LicenseEditUsersResellerComponent } from './licenses-edit-items/license-edit-users-reseller/license-edit-users-reseller.component';
import { LicenseDetailsWidgetComponent } from './licenses-details-widgets/license-details-widget/license-details-widget.component';
import { LicenseModulesWidgetComponent } from './licenses-details-widgets/license-modules-widget/license-modules-widget.component';
import { LicenseTransactionsWidgetComponent } from './licenses-details-widgets/license-transactions-widget/license-transactions-widget.component';
import { LicenseHardwareOrdersDetailWidget } from './licenses-details-widgets/license-hardware-orders-widget/license-hardware-orders.detail-widget';
import { LicenseAssetsWidgetComponent as LicenseAssetsDetailWidget } from './licenses-details-widgets/license-assets-widget/license-assets.detail-widget';
import { LicenseInteractionsDetailWidget } from './licenses-details-widgets/license-interactions-widget/license-interactions-detail-widget';


@Component({
    selector: 'arc-licenses',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './licenses.component.scss'
})
export class LicensesComponent extends PageListViewComponent<LicenseListModel, LicenseModel, LicenseEditModel> {
    private readonly licensesStore = inject(LicensesStore);

    constructor() {
        super();
        this.config = new TableListViewConfigModel<LicenseListModel, LicenseModel, LicenseEditModel>({
            entityName: 'License',
            availableColumns: {
                licenseHolder: new StackedColumnModel({
                    columnTitleKey: 'License.List.LicenseHolder',
                    propertyName: 'licenseHolder',
                    propertyName2: 'id',
                    widthPixels: 300
                }),
                numberOfPos: new StringColumnModel({
                    columnTitleKey: 'License.List.NumberOfPos',
                    propertyName: 'numberOfPos',
                    widthPixels: 120
                }),
                balance: new CurrencyColumnModel({
                    columnTitleKey: 'License.List.Balance',
                    propertyName: 'balance',
                    currencyIsoCode: item => item.currencyIsoCode,
                    widthPixels: 140
                }),
                monthlyCost: new CurrencyColumnModel({
                    columnTitleKey: 'License.List.MonthlyCost',
                    propertyName: 'monthlyCost',
                    currencyIsoCode: item => item.currencyIsoCode,
                    widthPixels: 140
                }),
                validThru: new CustomColumnModel({
                    columnTitleKey: 'License.List.ValidThru',
                    propertyName: 'validThru',
                    customComponent: LicenseValidThruComponent,
                    widthPixels: 120
                }),
                validFrom: new DateColumnModel({
                    columnTitleKey: 'License.List.ValidFrom',
                    propertyName: 'validFrom',
                    format: 'shortDate',
                    widthPixels: 120
                }),
                productTitle: new StringColumnModel({
                    columnTitleKey: 'License.List.Product',
                    propertyName: 'productTitle',
                    widthPixels: 150
                }),
                envServicePassword: new StringColumnModel({
                    columnTitleKey: 'License.List.EnvServicePassword',
                    propertyName: 'envServicePassword',
                    widthPixels: 150
                }),
                dbVersion: new StringColumnModel({
                    columnTitleKey: 'License.List.DbVersion',
                    propertyName: 'dbVersion',
                    widthPixels: 80
                }),
                resellerCompany: new StringColumnModel({
                    columnTitleKey: 'License.List.Reseller',
                    propertyName: 'resellerCompany',
                    widthPixels: 250
                }),
                billedUntilDate: new DateColumnModel({
                    columnTitleKey: 'License.List.BilledUntilDate',
                    propertyName: 'billedUntilDate',
                    format: 'shortDate',
                    widthPixels: 120
                }),
                userName: new StringColumnModel({
                    columnTitleKey: 'License.List.UserName',
                    propertyName: 'userName',
                    widthPixels: 250
                }),
                poolTime: new NumberColumnModel({
                    columnTitleKey: 'License.List.PoolTime',
                    propertyName: 'poolTime',
                    widthPixels: 80
                })
            },
            defaultColumnOrder: ['licenseHolder', 'numberOfPos', 'balance', 'monthlyCost', 'billedUntilDate', 'validThru', 'userName'],
            defaultSort: new ColumnSortModel({
                column: 'id',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {
                dbInfo: new TableListDetailWidgetModel({
                    component: LicenseDbInfoWidgetComponent,
                    name: 'License.Details.DbInfoWidget',
                    shouldHideHeader: true
                }),
                modules: new TableListDetailWidgetModel({
                    component: LicenseDetailsWidgetComponent,
                    name: 'License.Details.DetailsWidget',
                    shouldHideHeader: true
                }),
                allModules: new TableListDetailWidgetModel({
                    component: LicenseModulesWidgetComponent,
                    name: 'License.Details.ModulesWidget',
                    shouldHideHeader: true
                }),
                transactions: new TableListDetailWidgetModel({
                    component: LicenseTransactionsWidgetComponent,
                    name: 'License.Details.TransactionsWidget.Name',
                    shouldHideHeader: true
                }),
                hardwareOrders: new TableListDetailWidgetModel({
                    component: LicenseHardwareOrdersDetailWidget,
                    name: 'License.Details.HardwareOrdersWidget.Name',
                    shouldHideHeader: true
                }),
                assets: new TableListDetailWidgetModel({
                    component: LicenseAssetsDetailWidget,
                    name: 'License.Details.AssetsWidget.Name',
                    shouldHideHeader: true
                }),
                interactions: new TableListDetailWidgetModel({
                    component: LicenseInteractionsDetailWidget,
                    name: 'License.Details.InteractionsWidget.Name',
                    shouldHideHeader: true
                })
            },
            defaultDetailWidgetOrder: ['dbInfo', 'allModules', 'modules', 'transactions', 'hardwareOrders', 'assets', 'interactions'],
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'License.Edit.BaseData.Name',
                        component: LicenseBaseDataComponent
                    },
                    {
                        titleKey: 'License.Edit.Payment.Name',
                        component: LicensePaymentComponent
                    }
                ]
            },
            store: this.licensesStore,
            buttonsVisibility: {
                hasEdit: true,
                hasDelete: false,
                hasCreate: true,
                hasExport: true
            }
        });

        const canSearchUsers = this._authService.hasRole(UserRoles.Admin);
        if (canSearchUsers) {
            this.config.editSidebarConfig?.editComponents.push({
                titleKey: 'License.Edit.Users.Name',
                component: LicenseEditUsersComponent
            });
        } else {
            this.config.editSidebarConfig?.editComponents.push({
                titleKey: 'License.Edit.Users.LicenseHolder',
                component: LicenseEditUsersResellerComponent
            });
        }
    }
}
