import { Component, EventEmitter, Input, Output, inject, OnChanges } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { ButtonType } from './models/button.type';
import { ActionButtonsService } from '../../core/services/action-buttons.service';
import { TranslationService } from '../../core/services/translation.service';
import { BaseComponent } from '../abstractions/base.component';
import { ActionButtonModel } from '../../core/models/action-button.model';
import { BaseSearchStore } from '../../core/abstractions/base-search.store';

@Component({
    selector: 'arc-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent extends BaseComponent implements OnChanges {
    @Input() type: ButtonType = '';
    @Input() color?: ThemePalette;
    @Input() spinnerColor: ThemePalette = 'primary';
    @Input() contextActionsStore?: BaseSearchStore<any>;
    @Input() customClasses = '';
    @Input() isDisabled = false;
    @Input() isLoading = false;
    @Input() actionButtonKey?: string;
    @Input() actionButtonTitleKey = '';
    @Input() currentData?: any;
    @Input() tooltipText = '';

    @Output() readonly clicked = new EventEmitter<void>();

    title?: string;
    icon?: string;

    private readonly actionButtonsService = inject(ActionButtonsService);
    private readonly translationService = inject(TranslationService);

    private buttons = this.actionButtonsService.buttons;

    ngOnChanges(): void {
        if (!!this.actionButtonKey) {
            const btn = this.actionButtonsService.getButtonByKey(this.actionButtonKey);

            if (!btn && !!this.contextActionsStore) {
                this.contextActionsStore.getContextActions().subscribe(resp => {
                    const contextAction = (resp.value || []).find(cta => cta.key === this.actionButtonKey);

                    if (!contextAction) {
                        this.setupActionButton(undefined);
                        return;
                    }

                    const buttonModels = this.actionButtonsService.convertOutOfContextActions([contextAction], this.contextActionsStore!);

                    this.setupActionButton(buttonModels[0], true);
                });
            } else {
                this.setupActionButton(btn);
            }
        }
    }

    handleClick(): void {
        this.isLoading = true;

        if (!!this.actionButtonKey) {
            const sub = this.actionButtonsService.checkButtonLoadingStatusSub.subscribe(() => {
                const btn = this.buttons().find(b => b.key === this.actionButtonKey);
                this.performActionButtonClick(btn);
            });

            this.addSubscriptions(sub);
            this.actionButtonsService.handleClick(this.actionButtonKey, this.currentData);

            return;
        }

        this.performClick();
    }

    private setupActionButton(btn?: ActionButtonModel, isTranslated = false): void {
        this.title = btn?.text;
        this.icon = btn?.icon;
        this.tooltipText = this.isDisabled ? btn?.failedConditionMessage || '' : '';
        this.isDisabled = !btn || this.actionButtonsService.isHidden(btn.key, this.currentData);

        if (!isTranslated) {
            this.translationService.getTextAsync(this.actionButtonTitleKey).then(t => this.title = t);
        }
    }

    private performActionButtonClick(btn?: ActionButtonModel): void {
        if (!!btn && !btn.isLoading) {
            this.performClick();
        }
    }

    private performClick(): void {
        // We trigger the event even if an action button is referenced.
        // That way we allow for custom post-processing.
        this.clicked.emit();
        this.isLoading = false;
    }
}
