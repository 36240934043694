import { Component, OnInit, inject, signal } from '@angular/core';

import { FeatureListModel } from '../../../../../models/responses/feature-list.model';
import { FeatureModel } from '../../../../../models/feature.model';
import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { StaticTableConfigModel } from '../../../../../../components/list-views/static-table/models/static-table-config.model';
import { DateColumnModel } from '../../../../../../components/dynamic-table/models/column-types/date-column.model';
import { FeatureVoteListModel } from '../../../../../models/responses/feeature-vote.list.model';
import { FeaturesStore } from '../../../../../services/stores/features.store';
import { IconColumnModel } from '../../../../../../components/dynamic-table/models/column-types/icon-column.model';
import { IconModel } from '../../../../../../core/models/icon.model';
import { StackedColumnModel } from '../../../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { FeedbackVoteTextColumnComponent } from '../../components/feedback-vote-text/feedback-vote-text-column.component';
import { CustomColumnModel } from '../../../../../../components/dynamic-table/models/column-types/custom-column.model';

@Component({
    selector: 'arc-feature-vote',
    templateUrl: './feature-vote.component.html',
    styleUrl: './feature-vote.component.scss'
})
export class FeatureVoteComponent extends BaseDetailWidget<FeatureListModel, FeatureModel> implements OnInit {
    readonly tableConfig: StaticTableConfigModel = {
        defaultPageSize: 4,
        availableColumns: [
            new DateColumnModel({
                columnTitleKey: 'Feature.List.CreatedDate',
                propertyName: 'createdDate',
                format: 'short',
                widthPixels: 145
            }),
            new StackedColumnModel({
                columnTitleKey: 'Feature.List.UserDisplayName',
                propertyName: 'createdByEmail',
                propertyName2: 'licenseHolderName',
                widthPixels: 215
            }),
            new IconColumnModel({
                columnTitleKey: 'Feature.List.Vote',
                propertyName: 'vote',
                widthPixels: 25,
                iconMapping: new Map([
                    // eslint-disable-next-line no-null/no-null
                    [null, new IconModel({ name: 'clear' })],
                    [0, new IconModel({ name: 'sentiment_neutral' })],
                    [-1, new IconModel({ name: 'sentiment_dissatisfied' })],
                    [1, new IconModel({ name: 'sentiment_satisfied' })]
                ])
            }),
            new CustomColumnModel({
                propertyName: 'actions',
                customComponent: FeedbackVoteTextColumnComponent,
                inputs: {
                    refreshFn: () => this.setupVotes()
                }
            })
        ]
    };
    readonly isLoading = signal(true);
    readonly items = signal<FeatureVoteListModel[]>([]);

    private readonly featuresStore = inject(FeaturesStore);

    ngOnInit(): void {
        this.setupVotes();
    }

    setupVotes(): void {
        this.isLoading.set(true);
        this.items.set([]);
        this.featuresStore.getVotes(this.entityModel().id).subscribe(response => {
            this.items.set(response?.value || []);
            this.isLoading.set(false);
        });
    }
}
