import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ArcFormControl, ArcFormControlInterface } from '../../core/utils/arc-form-control';
import { DynamicFormField } from './models/dynamic-form-field';
import { DynamicFormControlTypeEnum } from './models/dynamic-form-control-type-enum';
import { ComplexDataTypesEnum } from '../../core/models/complex-data-types.enum';
import { OptionalType } from '../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../core/models/enums/general-data-type.enum';

@Component({
    selector: 'arc-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnChanges {
    @Input() formFields: DynamicFormField[] = [];
    @Input() classLayout = 'flex flex-col';
    @Output() readonly formSubmitted = new EventEmitter<any>();

    DynamicFormControlTypeEnum = DynamicFormControlTypeEnum;
    ComplexDataTypesEnum = ComplexDataTypesEnum;

    formGroup!: FormGroup;
    items: { key: string; field: DynamicFormField; control: AbstractControl }[] = [];

    private readonly formBuilder = inject(FormBuilder);
    private dateRangeFormFieldKey = 'DateRange';


    asFormGroup(control: AbstractControl): FormGroup {
        return control as FormGroup;
    }

    isDefinedGeneralDataType(value: OptionalType<string>): boolean{
        return Object.values(GeneralDataTypeEnum).includes(value as GeneralDataTypeEnum);
    }

    ngOnInit(): void {
        this.formGroup = this.setupFormFields();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges['formFields'] && !simpleChanges['formFields'].isFirstChange()) {
            this.formGroup = this.setupFormFields();
        }
    }

    submitForm(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();

        if (this.formGroup.valid) {
            const data: { [key: string]: any } = {};

            this.formFields.forEach(formField => {
                const key = formField.key;
                data[key] = this.getControlValue(formField);
            });

            this.formSubmitted.emit(data);
        }
    }

    private setupFormFields(): FormGroup {
        const formGroupConfig: any = {};
        if (this.formFields) {
            let fromDateControl: OptionalType<ArcFormControlInterface>;
            let fromDateDynamicFormField: DynamicFormField;

            for (let formField of this.formFields) {
                formField = new DynamicFormField(formField);
                const validators = !formField.isNullable ? Validators.required : undefined;
                const formControl = new ArcFormControl(formField.value, validators);

                // API is not sending any DynamicFormControlTypeEnum.DateRange
                if (this.isPartOfDateRange(formField)) {
                    if (formField.key === 'FromDate') {
                        // save the fromDate to be used when the toDate is found.
                        fromDateControl = formControl;
                        fromDateDynamicFormField = formField;
                    } else {
                        const toDateControl = formControl;
                        const formFieldKey = this.dateRangeFormFieldKey;
                        const formGroup = new FormGroup({ FromDate: fromDateControl!, ToDate: toDateControl });
                        formGroupConfig[formFieldKey] = formGroup;
                        formField.key = formFieldKey;
                        formField.label = `${fromDateDynamicFormField!.label} - ${formField.label}`;
                        formField.type = DynamicFormControlTypeEnum.DateRange;

                        this.items.push({ key: formFieldKey, field: formField, control: formGroup });
                    }
                } else {
                    const formFieldKey = formField.key;
                    formGroupConfig[formFieldKey] = formControl;
                    this.items.push({ key: formFieldKey, field: formField, control: formControl });
                }
            }
        }

        return this.formBuilder.group(formGroupConfig);
    }

    private getControlValue(formField: DynamicFormField): OptionalType<any> {
        let value;
        if (this.isPartOfDateRange(formField)) {
            value = (this.formGroup.controls[this.dateRangeFormFieldKey] as FormGroup).controls[formField.key].value;
        } else {
            value = this.formGroup.controls[formField.key].value;
        }

        if (formField.type === DynamicFormControlTypeEnum.Checkbox) {
            return value === 'true' || value === true;
        } else if (formField.type === DynamicFormControlTypeEnum.Date || formField.type === DynamicFormControlTypeEnum.DateTime) {
            return this.dateToISOString(value);
        } else if (formField.type === DynamicFormControlTypeEnum.Number || formField.type === DynamicFormControlTypeEnum.Decimal) {
            return Number(value);
        } else {
            return value;
        }
    }

    private dateToISOString(value: any): string {
        const date = new Date(value);
        const formattedDate = date.toISOString();
        return formattedDate;
    }

    private isPartOfDateRange(formField: DynamicFormField): boolean {
        return (
            (formField.type === DynamicFormControlTypeEnum.Date || formField.type === DynamicFormControlTypeEnum.DateRange) &&
            (((formField.key === 'FromDate' && this.formFields.find(f => f.key === 'ToDate')) ||
                (formField.key === 'ToDate' && this.formFields.find(f => f.key === 'FromDate'))) as boolean)
        );
    }
}
