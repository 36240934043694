import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { ArcFormControl } from '../../../../../core/utils/arc-form-control';
import { GeneralDataTypeEnum } from '../../../../../core/models/enums/general-data-type.enum';
import { NewsletterStore } from '../../../../services/stores/newsletter.store';
import { ToasterService } from '../../../../../core/services/toaster.service';
import { NewsletterCreateModel } from '../../../../models/requests/newsletter-create.model';

@Component({
    selector: 'arc-newsletter-create-dialog',
    templateUrl: './newsletter-create-dialog.component.html',
    styleUrl: './newsletter-create-dialog.component.scss'
})
export class NewsletterCreateDialogComponent {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    isLoading = false;
    isCreating = false;

    formGroup = new FormGroup({
        title: new ArcFormControl('', Validators.required),
        messageType: new ArcFormControl<string>(undefined, Validators.required)
    });

    private readonly newsletterStore = inject(NewsletterStore);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly toasterService = inject(ToasterService);

    create(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.invalid) {
            return;
        }

        this.isCreating = true;
        const createModel: NewsletterCreateModel = {
            id: 0,
            messageType: this.formGroup.getRawValue().messageType,
            title: this.formGroup.getRawValue().title
        };
        this.newsletterStore.add(createModel).subscribe(response => {
            if (response.value) {
                this.matDialogRef.close({ shouldReload: true, id: response.value });
            } else {
                this.toasterService.showError('General.Alert.UnexpectedError');
                this.isCreating = false;
            }
        });
    }
}
