import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { NewsletterEditModel } from '../../../../../models/requests/newsletter-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { NewsletterModel } from '../../../../../models/newsletter.model';

@Component({
    selector: 'arc-newsletter-edit',
    templateUrl: './newsletter-edit.component.html',
    styleUrl: './newsletter-edit.component.scss'
})
export class NewsletterEditComponent extends BaseEditSidebarItemComponent<NewsletterModel, NewsletterEditModel> {
    language = new FormControl<'en' | 'fr' | 'de' | 'it'>('de');

    override formGroup = new FormGroup({
        title: new ArcFormControl('', Validators.required),
        emailMessageEn: new FormGroup({
            title: new ArcFormControl('', Validators.required),
            text: new ArcFormControl('', Validators.required),
            buttonText: new ArcFormControl(''),
            buttonUrl: new ArcFormControl(''),
            textBelowButton: new ArcFormControl('')
        }),
        emailMessageDe: new FormGroup({
            title: new ArcFormControl('', Validators.required),
            text: new ArcFormControl('', Validators.required),
            buttonText: new ArcFormControl(''),
            buttonUrl: new ArcFormControl(''),
            textBelowButton: new ArcFormControl('')
        }),
        emailMessageFr: new FormGroup({
            title: new ArcFormControl('', Validators.required),
            text: new ArcFormControl('', Validators.required),
            buttonText: new ArcFormControl(''),
            buttonUrl: new ArcFormControl(''),
            textBelowButton: new ArcFormControl('')
        }),
        emailMessageIt: new FormGroup({
            title: new ArcFormControl('', Validators.required),
            text: new ArcFormControl('', Validators.required),
            buttonText: new ArcFormControl(''),
            buttonUrl: new ArcFormControl(''),
            textBelowButton: new ArcFormControl('')
        })
    });

    readonly translationService = inject(TranslationService);

    languages = [
        { value: 'de', label: this.translationService.getText('General.LanguagesShort.De') },
        { value: 'en', label: this.translationService.getText('General.LanguagesShort.En') },
        { value: 'fr', label: this.translationService.getText('General.LanguagesShort.Fr') },
        { value: 'it', label: this.translationService.getText('General.LanguagesShort.It') }
    ];

    onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<NewsletterEditModel> {
        const value = this.formGroup.getRawValue();
        return {
            title: value.title,
            emailMessageEn: value.emailMessageEn,
            emailMessageDe: value.emailMessageDe,
            emailMessageFr: value.emailMessageFr,
            emailMessageIt: value.emailMessageIt
        };
    }

    translate(): void {
        // This is needed to take advantage of translation logic where expected control name is "title_en"
        const translateForm = new FormGroup({
            title_en: new FormControl(this.formGroup.controls.emailMessageEn.controls['title'].value),
            text_en: new FormControl(this.formGroup.controls.emailMessageEn.controls['text'].value),
            buttonText_en: new FormControl(this.formGroup.controls.emailMessageEn.controls['buttonText'].value),
            textBelowButton_en: new FormControl(this.formGroup.controls.emailMessageEn.controls['textBelowButton'].value),
            title_de: new FormControl(this.formGroup.controls.emailMessageDe.controls['title'].value),
            text_de: new FormControl(this.formGroup.controls.emailMessageDe.controls['text'].value),
            buttonText_de: new FormControl(this.formGroup.controls.emailMessageDe.controls['buttonText'].value),
            textBelowButton_de: new FormControl(this.formGroup.controls.emailMessageDe.controls['textBelowButton'].value),
            title_fr: new FormControl(this.formGroup.controls.emailMessageFr.controls['title'].value),
            text_fr: new FormControl(this.formGroup.controls.emailMessageFr.controls['text'].value),
            buttonText_fr: new FormControl(this.formGroup.controls.emailMessageFr.controls['buttonText'].value),
            textBelowButton_fr: new FormControl(this.formGroup.controls.emailMessageFr.controls['textBelowButton'].value),
            title_it: new FormControl(this.formGroup.controls.emailMessageIt.controls['title'].value),
            text_it: new FormControl(this.formGroup.controls.emailMessageIt.controls['text'].value),
            buttonText_it: new FormControl(this.formGroup.controls.emailMessageIt.controls['buttonText'].value),
            textBelowButton_it: new FormControl(this.formGroup.controls.emailMessageIt.controls['textBelowButton'].value)
        });
        const controlsToTranslate = ['title', 'text', 'buttonText', 'textBelowButton'];

        this.translationService
            .translateFormControls(translateForm, controlsToTranslate)
            .pipe(first())
            .subscribe(() => {
                // Apply translated values
                // For "en"
                this.formGroup.controls.emailMessageEn.controls['title'].setValue(translateForm.controls.title_en.value ?? '');
                this.formGroup.controls.emailMessageEn.controls['text'].setValue(translateForm.controls.text_en.value ?? '');
                this.formGroup.controls.emailMessageEn.controls['buttonText'].setValue(translateForm.controls.buttonText_en.value ?? '');
                this.formGroup.controls.emailMessageEn.controls['textBelowButton'].setValue(
                    translateForm.controls.textBelowButton_en.value ?? ''
                );
                // For "de"
                this.formGroup.controls.emailMessageDe.controls['title'].setValue(translateForm.controls.title_de.value ?? '');
                this.formGroup.controls.emailMessageDe.controls['text'].setValue(translateForm.controls.text_de.value ?? '');
                this.formGroup.controls.emailMessageDe.controls['buttonText'].setValue(translateForm.controls.buttonText_de.value ?? '');
                this.formGroup.controls.emailMessageDe.controls['textBelowButton'].setValue(
                    translateForm.controls.textBelowButton_de.value ?? ''
                );

                // For "fr"
                this.formGroup.controls.emailMessageFr.controls['title'].setValue(translateForm.controls.title_fr.value ?? '');
                this.formGroup.controls.emailMessageFr.controls['text'].setValue(translateForm.controls.text_fr.value ?? '');
                this.formGroup.controls.emailMessageFr.controls['buttonText'].setValue(translateForm.controls.buttonText_fr.value ?? '');
                this.formGroup.controls.emailMessageFr.controls['textBelowButton'].setValue(
                    translateForm.controls.textBelowButton_fr.value ?? ''
                );

                // For "it"
                this.formGroup.controls.emailMessageIt.controls['title'].setValue(translateForm.controls.title_it.value ?? '');
                this.formGroup.controls.emailMessageIt.controls['text'].setValue(translateForm.controls.text_it.value ?? '');
                this.formGroup.controls.emailMessageIt.controls['buttonText'].setValue(translateForm.controls.buttonText_it.value ?? '');
                this.formGroup.controls.emailMessageIt.controls['textBelowButton'].setValue(
                    translateForm.controls.textBelowButton_it.value ?? ''
                );
            });
    }

    get formGroupForSelectedLanguage(): FormGroup {
        switch (this.language.value) {
            case 'en':
                return this.formGroup.controls.emailMessageEn;
            case 'fr':
                return this.formGroup.controls.emailMessageFr;
            case 'de':
                return this.formGroup.controls.emailMessageDe;
            case 'it':
                return this.formGroup.controls.emailMessageIt;
            default:
                return this.formGroup.controls.emailMessageDe;
        }
    }
}
