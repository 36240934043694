<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="control" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    @if (!!label || !!labelKey) {
        <mat-label>{{ label || (labelKey | transloco)}}</mat-label>
    }
    <input matInput [id]="tagId" [type]="type" (blur)="onBlur()" (input)="onChange($event)" [attr.step]="step"
        [placeholder]="placeholder || (placeholderKey | transloco)" [formControl]="castedControl"
        [attr.maxlength]="maxLength" [attr.minlength]="minLength" [attr.size]="inputSize" />
    @if(suffix) {
        <div matSuffix class="text-on-app-light whitespace-pre">
            {{ suffix }}
        </div>
    }
    @if(showTextCount && !!maxLength) {
        <mat-hint align="end">{{ castedControl.value.length }} / {{maxLength}}</mat-hint>
    }
    <div matSuffix class="flex gap-2">
        @if (!isActionLoading && !!action) {
            <button type="button" mat-icon-button (click)="executeAction()">
                <mat-icon>{{actionIcon}}</mat-icon>
            </button>
        }
        @if(!!icon) {
            <mat-icon class="mr-2">{{ icon }}</mat-icon>
        }
        @if (isActionLoading) {
            <div class="w-[40px] h-[40px] grid place-items-center">
                <mat-spinner diameter="22"></mat-spinner>
            </div>
        }
        <ng-content select="[suffix]"></ng-content>
    </div>
</mat-form-field>