import { Directive, input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BrokenRuleModel } from '../../../../app/models/broken-rule.model';
import { DictionaryType } from '../../../../core/models/types/dictionary.type';
import { Identifyable } from '../../../../core/abstractions/identifyable';
import { BaseComponent } from '../../../abstractions/base.component';
import { ArcFormControl } from '../../../../core/utils/arc-form-control';

@Directive()
export abstract class BaseEditSidebarItemComponent<
    T extends Identifyable<TId>,
    TUpdate extends Identifyable<TId> = T,
    TId = number
> extends BaseComponent {
    readonly item = input.required<T>();
    readonly isCreate = input.required<boolean>();

    readonly formGroup?: FormGroup;
    brokenRules: DictionaryType<BrokenRuleModel> = {};

    updateAndCheckValidity(): boolean {
        this.formGroup?.markAllAsTouched();
        this.formGroup?.updateValueAndValidity();

        return this.formGroup?.valid ?? true;
    }

    setBusinessErrors(brokenRules: BrokenRuleModel[]): string[] {
        const newBrokenRulesObj: DictionaryType<BrokenRuleModel> = {};

        if (!this.formGroup) {
            return [];
        }

        const mappedBrokenRuleIds: string[] = [];

        for (const brokenRule of brokenRules) {
            if (!brokenRule.property) {
                newBrokenRulesObj['GLOBAL'] = brokenRule;
                continue;
            }

            const controlName = brokenRule.property.charAt(0).toLowerCase() + brokenRule.property.slice(1);
            newBrokenRulesObj[controlName] = brokenRule;

            const control =
                Object.values(this.formGroup.controls).find(c => c instanceof ArcFormControl && c.validationName === controlName)
                    ?? this.formGroup.get(controlName);

            if (!control) {
                continue;
            }

            control.setErrors({ businessError: brokenRule.message });
            mappedBrokenRuleIds.push(brokenRule.id!);
        }

        this.brokenRules = newBrokenRulesObj;
        mappedBrokenRuleIds.push(...this.onBrokenRulesLoad());

        return mappedBrokenRuleIds;
    }

    shouldReloadOnCancel(): boolean {
        return false;
    }

    hasUnsavedChanges(): boolean {
        return this.formGroup?.dirty ?? false;
    }

    onBrokenRulesLoad(): string[] {
        return [];
    }

    abstract onItemSet(): void;
    abstract prepareSaveModel(): Partial<TUpdate>;
}
