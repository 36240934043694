import { Injectable, inject } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { HashMap } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';

import { OptionalType } from '../models/types/optional.type';
import { TranslationService } from './translation.service';
import { DictionaryType } from '../models/types/dictionary.type';

@Injectable({
    providedIn: 'root'
})
export class FormControlErrorsService {
    private translationService = inject(TranslationService);

    private errorMessageMap: DictionaryType<(error: any) => { key: string; params?: HashMap }> = {
        required: () => ({ key: 'General.ValidationErrors.Required' }),
        maxlength: error => ({ key: 'General.ValidationErrors.MaxLength', params: { maxLength: error.requiredLength } }),
        minlength: error => ({ key: 'General.ValidationErrors.MinLength', params: { minLength: error.requiredLength } }),
        email: () => ({ key: 'General.ValidationErrors.Email' }),
        matDatepickerParse: () => ({ key: 'General.ValidationErrors.Date' }),
        number: () => ({ key: 'General.ValidationErrors.Number' }),
        numberRange: error => ({ key: 'General.ValidationErrors.NumberRange', params: { min: error.min, max: error.max } }),
        numberMin: error => ({ key: 'General.ValidationErrors.NumberMin', params: { min: error.min } }),
        numberMax: error => ({ key: 'General.ValidationErrors.NumberMax', params: { max: error.max } }),
        numberInteger: () => ({ key: 'General.ValidationErrors.NumberInteger' }),
        numberPositive: () => ({ key: 'General.ValidationErrors.NumberPositive' }),
        invalidHexColor: () => ({ key: 'General.ValidationErrors.InvalidHexColor' }),
        alreadyTaken: () => ({ key: 'General.ValidationErrors.AlreadyTaken' })
    };

    private readonly errorMessageMapEntries = Object.entries(this.errorMessageMap);

    getErrorMessageObs(errors?: ValidationErrors | null): Observable<OptionalType<string>> {
        if (!errors) {
            return of(undefined);
        }

        if (!!errors['businessError']) {
            return of(errors['businessError']);
        }

        if (!!errors['customError']) {
            return this.translationService.getTextObservable(errors['customError'].key, errors['customError'].params);
        }

        for (const [error, translationParams] of this.errorMessageMapEntries) {
            if (!!errors[error]) {
                const params = translationParams(errors[error]);
                return this.translationService.getTextObservable(params.key, params.params);
            }
        }

        return this.translationService.getTextObservable('General.ValidationErrors.General');
    }

    getErrorMessage(errors?: ValidationErrors | null): OptionalType<string> {
        if (!errors) {
            return undefined;
        }

        if (!!errors['businessError']) {
            return errors['businessError'];
        }

        if (!!errors['customError']) {
            return this.translationService.getText(errors['customError'].key, errors['customError'].params);
        }

        for (const [error, translationParams] of this.errorMessageMapEntries) {
            if (!!errors[error]) {
                const params = translationParams(errors[error]);
                return this.translationService.getText(params.key, params.params);
            }
        }

        return this.translationService.getText('General.ValidationErrors.General');
    }
}
