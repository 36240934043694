import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

import dayjs from './dayjs';
import { Utils } from './tools/utils.tools';

@Injectable()
export class DayjsDateAdapter extends NativeDateAdapter {
    override getYear(date: Date): number {
        return dayjs(date).year();
    }

    override getMonth(date: Date): number {
        return dayjs(date).month();
    }

    override getDate(date: Date): number {
        return dayjs(date).date();
    }

    override getDayOfWeek(date: Date): number {
        return dayjs(date).day();
    }

    override getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
        if (style === 'long') {
            return dayjs.months();
        }
        if (style === 'short') {
            return dayjs.monthsShort();
        }
        return dayjs.months().map(m => m[0]);
    }

    override getDateNames(): string[] {
        return new Array(31).fill('').map((v, i) => `${i + 1}`);
    }

    override getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
        if (style === 'long') {
            return dayjs.weekdays();
        }
        if (style === 'short') {
            return dayjs.weekdaysShort();
        }
        return dayjs.weekdaysMin();
    }

    override getYearName(date: Date): string {
        return dayjs(date).year().toString();
    }

    override getFirstDayOfWeek(): number {
        return dayjs.localeData().firstDayOfWeek();
    }

    override getNumDaysInMonth(date: Date): number {
        return dayjs(date).daysInMonth();
    }

    override clone(date: Date): Date {
        return Utils.deepCopy(date);
    }

    override today(): Date {
        return dayjs().toDate();
    }

    override parse(value: any, parseFormat: string | string[]): Date | null {
        if (!!value && typeof value == 'string') {
            return dayjs(value, parseFormat).toDate();
        }
        // eslint-disable-next-line no-null/no-null
        return !!value ? dayjs(value).toDate() : null;
    }

    override format(date: Date, displayFormat: string): string {
        if (!this.isValid(date)) {
            return '-';
        }

        return dayjs(date).format(displayFormat);
    }

    override addCalendarYears(date: Date, years: number): Date {
        return dayjs(date).add(years, 'years').toDate();
    }

    override addCalendarMonths(date: Date, months: number): Date {
        return dayjs(date).add(months, 'months').toDate();
    }

    override addCalendarDays(date: Date, days: number): Date {
        return dayjs(date).add(days, 'days').toDate();
    }

    override toIso8601(date: Date): string {
        return dayjs(date).toISOString();
    }

    override isDateInstance(obj: any): obj is Date {
        return dayjs(obj).isValid();
    }

    override isValid(date: Date): boolean {
        return dayjs(date).isValid();
    }

    override invalid(): Date {
        return dayjs(undefined).toDate();
    }
}
