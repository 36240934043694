import { Component, Input, ViewChild, computed, effect, inject, signal, untracked } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Subject, debounceTime, filter, switchMap, tap } from 'rxjs';

import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';
import { Identifyable } from '../../../core/abstractions/identifyable';
import { TreeDataSelectionDialogComponent } from '../../dialogs/tree-data-selection-dialog/tree-data-selection-dialog.component';
import { TreeDataSelectionConfig } from './models/tree-data-selection.config';
import { OptionalType } from '../../../core/models/types/optional.type';

@Component({
    selector: 'arc-tree-autocomplete',
    templateUrl: './tree-autocomplete.component.html',
    styleUrl: './tree-autocomplete.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: TreeAutocompleteComponent,
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: TreeAutocompleteComponent,
            multi: true
        }
    ]
})
export class TreeAutocompleteComponent<TModel extends Identifyable<TId>, TId = number>
    extends BaseControlValueAccessor<TId> implements Validator {
    @ViewChild(MatAutocomplete, { static: true }) autocomplete!: MatAutocomplete;

    @Input({ required: true }) treeDataSelectionConfig!: TreeDataSelectionConfig<TModel, TId>;

    currentModel = signal<OptionalType<TModel>>(undefined);
    currentParentPath = computed(() => {
        const currentModel = this.currentModel();
        if (!currentModel) {
            return undefined;
        }

        const parentTitles: string[] = [];
        let parent = this.treeDataSelectionConfig.getNodeParent(currentModel);
        while (!!parent) {
            parentTitles.unshift(this.treeDataSelectionConfig.getNodeTitle(parent));
            parent = this.treeDataSelectionConfig.getNodeParent(parent);
        }
        return parentTitles.join(' > ');
    });

    isSearching = signal(false);
    searchResults = signal<TModel[]>([]);

    private readonly internalSearchSubject = new Subject<string>();
    private readonly debounceTimeMs = 250;

    private readonly matDialog = inject(MatDialog);

    constructor() {
        super();

        const searchSub = this.internalSearchSubject
            .pipe(
                tap(() => {
                    this.searchResults.set([]);
                    this.isSearching.set(true);
                }),
                debounceTime(this.debounceTimeMs),
                switchMap(searchText => this.treeDataSelectionConfig.search(searchText))
            )
            .subscribe(options => {
                this.searchResults.set(options);
                this.isSearching.set(false);
            });

        const searchInputValueChangesSub = this.internalControl.valueChanges.subscribe(searchInputValue => {
            if (typeof searchInputValue !== 'string') {
                untracked(() => this.searchResults.set([]));
                return;
            }

            this.internalSearchSubject.next(searchInputValue);
        });

        this.addSubscriptions(searchSub, searchInputValueChangesSub);

        effect(() => {
            // when the currently selected model changes, update the internal control value (-> updates the display text)
            const currentModel = this.currentModel();
            untracked(() => this.internalControl.setValue(currentModel));
        });
    }

    override writeValue(value?: TId | undefined): void {
        if (!value) {
            super.writeValue(undefined);
            return;
        }

        this.isSearching.set(true);
        this.treeDataSelectionConfig
            .getById(value)
            .subscribe(result => {
                super.writeValue(!result.value ? undefined : result.value.id);
                this.currentModel.set(result.value);
                this._formControl?.updateValueAndValidity();
            })
            .add(() => this.isSearching.set(false));
    }

    validate(): ValidationErrors | null {
        const currentModel = this.currentModel();
        const currentValue = this.value;

        if (currentModel?.id !== currentValue) {
            return { invalid: true };
        }

        if (this.isRequired && !currentValue) {
            return { required: true };
        }

        // eslint-disable-next-line no-null/no-null
        return null;
    }

    getNodePath(node: TModel): string {
        let path = this.treeDataSelectionConfig.getNodeTitle(node);
        let parent = this.treeDataSelectionConfig.getNodeParent(node);

        while (!!parent) {
            path = `${this.treeDataSelectionConfig.getNodeTitle(parent)} > ${path}`;
            parent = this.treeDataSelectionConfig.getNodeParent(parent);
        }

        return path;
    }

    displayWithFn(item: TModel | null): string {
        return item ? this.treeDataSelectionConfig.getNodeTitle(item) : '';
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent): void {
        const option = event.option.value as TModel | null;

        // deselect all options, we don't want the autocomplete to keep the state
        event.source.options.forEach(o => o.deselect());

        if (!option || typeof option !== 'object') {
            this.setValue(undefined);
            return;
        }

        this.setValue(option);
    }

    openDialog(trigger: MatAutocompleteTrigger): void {
        setTimeout(() => trigger.closePanel());

        const dialogRef = this.matDialog.open(TreeDataSelectionDialogComponent, {
            data: this.treeDataSelectionConfig,
            width: '800px',
            maxWidth: '98vw',
            height: '800px',
            maxHeight: '98svh'
        });

        const dialogSub = dialogRef
            .afterClosed()
            .pipe(
                filter(id => !!id),
                tap(() => this.isSearching.set(true)),
                switchMap(id => this.treeDataSelectionConfig.getById(id))
            )
            .subscribe(result => {
                this.setValue(result.value);
                this.isSearching.set(false);
            });

        this.addSubscriptions(dialogSub);
    }

    checkContent(): void {
        const currentContent = this.internalControl.value;
        const currentModel = this.currentModel();

        if (!currentModel && !!currentContent) {
            this.internalControl.setValue(undefined);
            return;
        }

        // checks if the current content of the search input matches the currently selected model -> else reset to undefined
        if (typeof currentContent === 'string') {
            if (!!currentModel && this.treeDataSelectionConfig.getNodeTitle(currentModel) !== currentContent) {
                this.setValue(undefined);
            }
        } else if (typeof currentContent === 'object') {
            if (!!currentModel && currentModel.id !== currentContent.id) {
                this.setValue(undefined);
            }
        }

    }

    private setValue(value?: TModel): void {
        this.currentModel.set(value);
        this.valueChanged(value?.id);
    }
}
