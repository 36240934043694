import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { InvoiceEditModel } from '../../../../../models/requests/invoice-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { PaymentTypesEnum } from '../../../../../models/enums/payment-types.enum';
import { TranslationService } from '../../../../../../core/services/translation.service';

@Component({
    selector: 'arc-invoice-base-data',
    templateUrl: './invoice-base-data.component.html',
    styleUrl: './invoice-base-data.component.scss'
})
export class InvoiceBaseDataComponent extends BaseEditSidebarItemComponent<InvoiceEditModel> {
    override formGroup = new FormGroup({
        payableUntilDate: new ArcFormControl(new Date(), Validators.required),
        payedDate: new ArcFormControl<OptionalType<Date>>(undefined),
        sentDate: new ArcFormControl<OptionalType<Date>>(undefined),
        remindedDate: new ArcFormControl<OptionalType<Date>>(undefined),
        remindedDate2: new ArcFormControl<OptionalType<Date>>(undefined),
        remarks: new ArcFormControl<OptionalType<string>>(undefined),
        paymentType: new ArcFormControl(PaymentTypesEnum.Invoice, Validators.required)
    });

    readonly transaltionService = inject(TranslationService);

    paymentTypes = [
        {
            value: PaymentTypesEnum.Card,
            label: this.transaltionService.getText('Invoice.Edit.BaseData.PaymentType.Card')
        },
        {
            value: PaymentTypesEnum.Invoice,
            label: this.transaltionService.getText('Invoice.Edit.BaseData.PaymentType.Invoice')
        }
    ];

    onItemSet(): void {
        this.formGroup.patchValue(this.item());
    }

    override prepareSaveModel(): Partial<InvoiceEditModel> {
        const value = this.formGroup.getRawValue();
        return {
            payableUntilDate: value.payableUntilDate,
            payedDate: value.payedDate,
            sentDate: value.sentDate,
            remindedDate: value.remindedDate,
            remindedDate2: value.remindedDate2,
            remarks: value.remarks,
            paymentType: value.paymentType
        };
    }
}
