<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-datepicker [control]="formGroup.controls.valueDate" labelKey="Transactions.Edit.BaseData.ValueDate">
    </arc-datepicker>

    <arc-quick-search label="Transactions.Edit.BaseData.License"
        [config]="ComplexDataTypesEnum.License | arcQuickSearchConfig" [formControl]="formGroup.controls.licenseId">
    </arc-quick-search>
    <arc-button-toggle-group [control]="formGroup.controls.type" [items]="transactionTypes" />
    @if(formGroup.value.type === TransactionTypeEnum.License) {
        <arc-select labelKey="Transactions.Edit.BaseData.LicenseModuleBundles" [options]="licenseModuleBundles"
            [control]="formGroup.controls.licenseModuleBundleId">
        </arc-select>
    } @if(formGroup.value.type === TransactionTypeEnum.Hardware) {
        <arc-select labelKey="Transactions.Edit.BaseData.LicenseHardwareOrders" [options]="licenseHardwareOrders"
            [control]="formGroup.controls.licenseHardwareOrderId">
        </arc-select>
    }
    <arc-number-input [formControl]="formGroup.controls.poolTime" label="Transactions.Edit.BaseData.PoolTime">
    </arc-number-input>
    <div class="flex gap-2 items-center">
        <arc-currency-input label="Transactions.Edit.BaseData.Amount" [formControl]="formGroup.controls.amount"/>
        <arc-currency-input label="Transactions.Edit.BaseData.Discount" [formControl]="formGroup.controls.discountGiven"/>
        <div class="px-8">
            <div class="text-sm">{{ 'Transactions.Edit.BaseData.TotalAmount' | transloco }}</div>
            <div>{{ totalAmount() | arcCurrency }}</div>
        </div>
    </div>
    <arc-input labelKey="Transactions.Edit.BaseData.PublicRemark" [control]="this.formGroup.controls.publicRemark">
    </arc-input>
    <arc-input labelKey="Transactions.Edit.BaseData.InternalRemark" [control]="this.formGroup.controls.publicRemark">
    </arc-input>
</form>